import { bff } from './bff-hooks'
import { useState } from 'react'
import { Button, Input, Select, Space, Spin } from 'antd'
import {
    tabNameToCasesStateMapper,
    FilterParams,
    mapDueDateToSearchParams,
} from '../../pages/compliance/odd/utils'
import type { SearchCasesParams } from 'bff/moons/generated/case-manager'
import { snakeCaseToTitleCase } from 'utils/strings'
import { useCountriesList } from 'utils/countries'
import type { Alpha2Code } from 'types/countries'
import { countries } from 'countries-list'

type FilterBarProps = {
    activeTab: string
    updateUrl: (params: FilterParams) => void
    filters: FilterParams
    updateSearchParams: (params: SearchCasesParams) => void
    clearParams: () => void
}

export const FilterBar = ({
    activeTab,
    updateUrl,
    updateSearchParams,
    filters,
    clearParams,
}: FilterBarProps) => {
    const signupCountries = useCountriesList()
    const [kerberosEmailSearch, setKerberosEmailSearch] = useState<string | undefined>()
    const { data: kerberosUsers, isLoading: isLoadingKerberosUsers } =
        bff.filters.getKerberosUsers.useQuery({ admin: true, query: kerberosEmailSearch })
    const onlyOneStatus =
        tabNameToCasesStateMapper[activeTab] && tabNameToCasesStateMapper[activeTab].length === 1

    const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

    return (
        <Space style={{ marginBottom: '1rem' }}>
            <Select
                placeholder="Country"
                options={signupCountries?.map(({ countryCode }) => ({
                    value: countryCode,
                    label: `${getCountry(countryCode).emoji} ${getCountry(countryCode).name}`,
                }))}
                style={{ minWidth: 160 }}
                onChange={(value) => {
                    updateUrl({ country: value })
                    updateSearchParams({
                        metadata: value ? JSON.stringify({ companyCountryCode: value }) : undefined,
                    })
                }}
                allowClear
                value={filters?.country}
            />
            <Input
                placeholder="Company ID"
                width={320}
                onChange={(e) => {
                    updateUrl({ entityId: e.target.value })
                    updateSearchParams({ entityId: e.target.value })
                }}
                allowClear
                value={filters?.entityId}
            />
            {!onlyOneStatus && (
                <Select
                    placeholder="Status"
                    style={{ minWidth: 200 }}
                    options={tabNameToCasesStateMapper[activeTab].map((status) => ({
                        value: status,
                        label: snakeCaseToTitleCase(status),
                    }))}
                    onChange={(value) => {
                        if (value) {
                            updateUrl({ state: [value] })
                            updateSearchParams({ state: [value] })
                        } else {
                            updateUrl({ state: tabNameToCasesStateMapper[activeTab] })
                            updateSearchParams({ state: tabNameToCasesStateMapper[activeTab] })
                        }
                    }}
                    allowClear
                    value={
                        filters?.state && filters?.state.length === 1
                            ? filters?.state[0]
                            : undefined
                    }
                />
            )}
            <Select
                showSearch
                placeholder="Assignee"
                style={{ minWidth: 160 }}
                labelInValue
                filterOption={false}
                onSearch={(value) => setKerberosEmailSearch(value)}
                notFoundContent={isLoadingKerberosUsers ? <Spin size="small" /> : null}
                options={kerberosUsers?.map((user: any) => ({
                    value: user.id,
                    label: user.email,
                }))}
                onChange={(assignee) => {
                    updateUrl({ assigneeId: assignee?.value })
                    updateSearchParams({ assigneeId: assignee?.value })
                }}
                allowClear
                value={{ value: filters?.assigneeId }}
            />
            <Select
                placeholder="Due date"
                options={[
                    { value: 'this-week', label: 'This week' },
                    { value: 'overdue', label: 'Overdue' },
                ]}
                allowClear
                onChange={(value: string | undefined) => {
                    updateUrl({ dueDate: value })
                    updateSearchParams(mapDueDateToSearchParams(value))
                }}
                value={filters?.dueDate}
            />
            <Button onClick={() => clearParams()}>Clear filters</Button>
        </Space>
    )
}
