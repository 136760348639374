import React, { FC, useState, useEffect } from 'react'

import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import { ActionsContainer } from 'components/layout-containers'
import { SupplementaryUnloadStatus, WalletUnloadsStatusAPI } from './utils'
import { Approve } from './approve'
import { Reject } from './reject'
import { Investigate } from './investigate'
import { Edit, validationSchema } from './edit'

const Actions: FC<
    React.PropsWithChildren<{ walletUnload: WalletUnloadResponse; status: WalletUnloadsStatusAPI }>
> = ({ walletUnload, status }) => {
    const [isUnloadFormValid, setIsUnloadFormValid] = useState(false)

    useEffect(() => {
        const validateUnloadForm = async () => {
            const unloadFormData = {
                requesterAccountNumber: walletUnload.account?.accountNumber,
                requesterBankCode: walletUnload.account?.bankCode,
                requesterBic: walletUnload.account?.bic,
                requesterIban: walletUnload.account?.iban,
                accountType: walletUnload.account?.accountType,
                otherAccountIdentifier: walletUnload.account?.otherAccountIdentifier,
                amount: walletUnload.amount?.value,
                name: walletUnload.beneficiaryAddress?.name,
                addressLine1: walletUnload.beneficiaryAddress?.addressLine1,
                postalCode: walletUnload.beneficiaryAddress?.postalCode,
                locality: walletUnload.beneficiaryAddress?.locality,
                country: walletUnload.beneficiaryAddress?.country,
            }

            setIsUnloadFormValid(await validationSchema.isValid(unloadFormData))
        }
        validateUnloadForm()
    }, [walletUnload])

    const nothingToUnload = walletUnload.amount.value === 0
    const [disableActionButtons, setDisableActionButtons] = useState(false)
    const approvableStasuses: Array<
        WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
    > = [
        WalletUnloadResponseStatus.NEW,
        WalletUnloadResponseStatus.UNDER_INVESTIGATION,
        SupplementaryUnloadStatus.OUTSTANDING_INVOICES,
    ]
    const editableStasuses: Array<
        WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
    > = [
        WalletUnloadResponseStatus.NEW,
        WalletUnloadResponseStatus.UNDER_INVESTIGATION,
        SupplementaryUnloadStatus.OUTSTANDING_INVOICES,
        WalletUnloadResponseStatus.UNLOADED,
        WalletUnloadResponseStatus.EXPORTED,
    ]
    const rejectableStasuses: Array<
        WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
    > = [
        WalletUnloadResponseStatus.UNDER_INVESTIGATION,
        SupplementaryUnloadStatus.OUTSTANDING_INVOICES,
    ]
    const investigateableStasuses: WalletUnloadResponseStatus[] = [WalletUnloadResponseStatus.NEW]
    return (
        <ActionsContainer>
            {approvableStasuses.includes(status) && (
                <Approve
                    walletUnload={walletUnload}
                    status={status}
                    disabled={disableActionButtons || nothingToUnload || !isUnloadFormValid}
                    validationChecks={{ disableActionButtons, nothingToUnload, isUnloadFormValid }}
                    setDisableActionButtons={setDisableActionButtons}
                />
            )}
            {editableStasuses.includes(status) && (
                <Edit
                    walletUnload={walletUnload}
                    status={status}
                    disabled={disableActionButtons}
                    setDisableActionButtons={setDisableActionButtons}
                />
            )}
            {investigateableStasuses.includes(status as WalletUnloadResponseStatus) && (
                <Investigate
                    walletUnload={walletUnload}
                    status={status}
                    disabled={disableActionButtons}
                    setDisableActionButtons={setDisableActionButtons}
                />
            )}
            {rejectableStasuses.includes(status) && (
                <Reject
                    walletUnload={walletUnload}
                    status={status}
                    disabled={disableActionButtons}
                    setDisableActionButtons={setDisableActionButtons}
                />
            )}
        </ActionsContainer>
    )
}

export default Actions
