import styled from 'styled-components'

import { PageContentLayout } from 'components/layout-containers'

import { OddDetailProvider } from 'features/odd/context'
import { Header } from 'features/odd/page-header'
import { CheckList } from 'features/odd/checks'
import { Evidence } from 'features/odd/evidence'
import { CaseLockedMessage } from 'features/odd/case-locked-message'
import {
    ConclusionModal,
    DelayNotificationsModal,
    HistoryModal,
    OffboardingModal,
    SendRequestInformationModal,
} from 'features/odd/modals'
import { WalletBlockedWarning } from 'features/odd/wallet-blocked-warning'

export const OddDetailPage = () => {
    return (
        <OddDetailProvider>
            <PageContentLayout>
                <Header />
                <CaseLockedMessage />
                <WalletBlockedWarning />
                <Container>
                    <CheckList />
                    <Evidence />
                </Container>
            </PageContentLayout>
            <OffboardingModal />
            <SendRequestInformationModal />
            <DelayNotificationsModal />
            <ConclusionModal />
            <HistoryModal />
        </OddDetailProvider>
    )
}

const Container = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
`
