import { Comment } from '@ant-design/compatible'
import { Typography } from 'antd'
import dayjs from 'packages/dayjs'
import type { FC } from 'react'
import type { ComplianceNote } from 'types/styx'

const { Text } = Typography

interface NoteFieldProps {
    note: ComplianceNote
}

const ComplianceNoteField: FC<React.PropsWithChildren<NoteFieldProps>> = ({
    note,
}: NoteFieldProps) => (
    <Comment
        author={<Text>{note.author}</Text>}
        content={<Text>{note.text}</Text>}
        datetime={dayjs(note.createdAt).format('lll')}
    />
)

export default ComplianceNoteField
