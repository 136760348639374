import {
    Alert,
    Button,
    Card,
    Descriptions,
    Popconfirm,
    Space,
    Tag,
    Typography,
    notification,
} from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCompanyCountry } from 'store/modules/support/company/selectors'
import type {
    PleoReserveEligibilityProductStateEnum,
    ProductMetadataCountriesEnum,
} from '@pleo-io/narvi-ts-models'
import { PleoReserveEligibilityModal } from './pleo-reserve-eligibility'
import { PleoReserveLimitModal } from './pleo-reserve-limit'
import Spinner from 'components/content-spinner'
import type { ProductStatus } from 'bff/moons/generated/kale'
import dayjs from 'dayjs'
import { creditTabBff } from './bff'
import EmployeeLink from './overdraft-card/employee-link'
import { selectEmployees } from 'store/modules/employees/selectors'
import { usePleoReserveActiveLimit } from './pleo-reserve'

const { Text } = Typography

const getProductStateColor = (productState: PleoReserveEligibilityProductStateEnum | undefined) => {
    if (!productState) {
        return 'default'
    }
    const colors: Record<PleoReserveEligibilityProductStateEnum, string> = {
        INACTIVE: 'default',
        ACTIVE: 'green',
        CLOSED: 'cyan',
        OVERDUE: 'orange',
        COLLECTIONS: 'red',
        LOCKED: '#000',
    }
    return colors[productState]
}

export const PleoReserveCard = ({ companyId }: { companyId: string }) => {
    const { data, isInitialLoading, isError, refetch } = creditTabBff.getReserveCard.useQuery({
        companyId,
    })
    const { mutateAsync: updateAccountState } = creditTabBff.updateAccountState.useMutation()
    const activeReserveLimit = usePleoReserveActiveLimit()
    const country = useSelector(selectCompanyCountry)
    const [isEligibilityModalVisible, setIsEligibilityModalVisible] = useState(false)
    const [isLimitModalVisible, setIsLimitModalVisible] = useState(false)

    const employees = useSelector(selectEmployees)

    const handleUpdateState = async (newState: ProductStatus) => {
        if (!data?.recentAccount || newState === 'LOCKED') {
            return
        }
        try {
            await updateAccountState({
                accountId: data.recentAccount.id,
                state: newState,
            })
            refetch()
        } catch (error) {
            notification.error({
                message: `Failed to update Reserve state.`,
            })
        }
    }

    if (isInitialLoading) {
        return (
            <Card title="Pleo Reserve">
                <Spinner />
            </Card>
        )
    }

    if (isError) {
        return (
            <Card title="Pleo Reserve">
                <Alert message="Failed to fetch eligibility data for this company." type="error" />
            </Card>
        )
    }

    const isPleoReserveSupportedCountry = Boolean(
        data?.reserveMetadata?.countries?.includes(country as ProductMetadataCountriesEnum)
    )

    if (!isPleoReserveSupportedCountry) {
        return (
            <Card title="Pleo Reserve">
                <Alert
                    message="Pleo Reserve is not yet supported in company country."
                    type="info"
                />
            </Card>
        )
    }

    const activatedBy = employees.find(
        (employee) => employee.userId === data?.recentAccount?.activatedBy
    )

    return (
        <Card title="Pleo Reserve">
            <Space direction="vertical">
                <Descriptions column={4} size="small">
                    <Descriptions.Item label="Eligibility">
                        <Tag
                            color={
                                data?.reserveEligibility.eligibility === 'ELIGIBLE'
                                    ? 'green'
                                    : 'default'
                            }
                        >
                            {data?.reserveEligibility.eligibility ?? '-'}
                        </Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label="State">
                        <Tag color={getProductStateColor(data?.reserveEligibility.productState)}>
                            {data?.reserveEligibility.productState ?? '-'}
                        </Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label="Limit">
                        <Space direction="vertical">
                            <Text>
                                {'Eligible: '}
                                <BalanceItem balance={data?.eligibleLimit} />
                            </Text>
                            <Text>
                                {'Active: '}
                                <BalanceItem balance={activeReserveLimit} />
                            </Text>
                        </Space>
                    </Descriptions.Item>
                    {data?.recentAccount && (
                        <Descriptions.Item label="Activated on">
                            {dayjs(data.recentAccount.activatedAt).format('ll')}
                        </Descriptions.Item>
                    )}
                    {activatedBy && (
                        <Descriptions.Item label="Activated by">
                            <EmployeeLink employee={activatedBy} />
                        </Descriptions.Item>
                    )}
                </Descriptions>
                <Descriptions column={4} size="small">
                    <Descriptions.Item>
                        <Button onClick={() => setIsEligibilityModalVisible(true)}>
                            Change eligibility
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                handleUpdateState(
                                    data?.recentAccount?.state === 'COLLECTIONS'
                                        ? 'ACTIVE'
                                        : 'COLLECTIONS'
                                )
                            }
                            disabled={
                                !data?.recentAccount ||
                                !['ACTIVE', 'COLLECTIONS', 'OVERDUE'].includes(
                                    data?.recentAccount.state
                                )
                            }
                        >
                            <Button
                                disabled={
                                    !data?.recentAccount ||
                                    !['ACTIVE', 'COLLECTIONS', 'OVERDUE'].includes(
                                        data?.recentAccount.state
                                    )
                                }
                            >
                                {data?.recentAccount?.state === 'COLLECTIONS'
                                    ? 'Remove from collections'
                                    : 'Move to collections'}
                            </Button>
                        </Popconfirm>
                    </Descriptions.Item>

                    <Descriptions.Item>
                        <Button onClick={() => setIsLimitModalVisible(true)}>Change limit</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Space>

            <PleoReserveEligibilityModal
                isVisible={isEligibilityModalVisible}
                closeModal={() => setIsEligibilityModalVisible(false)}
            />

            <PleoReserveLimitModal
                isVisible={isLimitModalVisible}
                closeModal={() => setIsLimitModalVisible(false)}
            />
        </Card>
    )
}

export interface Balance {
    currency: string
    value: number
}

const BalanceItem = ({ balance }: { balance: Balance | null | undefined }) => {
    if (!balance) {
        return <>-</>
    }
    return (
        <Text>
            {balance.currency}{' '}
            {balance.value.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
            })}
        </Text>
    )
}
