import type { LandingPages } from 'hooks/use-user-preferences'
import { useIsLoggedIn } from 'providers/auth-context'
import { usePermissions, usePreferences } from 'providers/user-context'

const isComplianceUser = (permissions: string[]) => permissions.includes('styx')

export const getInitialRoute = (
    permissions: string[],
    preferredSection?: LandingPages,
    isLoggedIn?: boolean
) => {
    // If the user is not logged in, redirect to the login page
    if (!isLoggedIn) {
        return '/login'
    }
    // If the user has not yet been fetched, redirect to the loading page
    if (permissions.length === 0) {
        return '/'
    }
    // If the user has the B4B permission, redirect to the B4B page
    if (permissions.includes('b4b')) {
        return '/b4b'
    }
    // If the user has the compliance permission, redirect to the compliance page
    if (isComplianceUser(permissions) && preferredSection === 'compliance') {
        return '/compliance'
    }
    return '/customer-success'
}

export const useInitialRoute = () => {
    const permissions = usePermissions()
    const preferences = usePreferences()
    const isLoggedIn = useIsLoggedIn()

    const preferredSection = preferences?.userPreferences.landingPage
    const initialRoute = () => getInitialRoute(permissions, preferredSection, isLoggedIn)

    return initialRoute
}
