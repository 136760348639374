import { Button, message, Typography, Form, Input } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import Spinner from 'components/content-spinner'
import { inputWidth } from 'theme/tokens'
import styled from 'styled-components'
import { useCompanyNarrative } from 'services/styx/company'
import type { NarrativeCategory } from 'pages/compliance/shared/utils'

const { TextArea } = Input

const NARRATIVE_MAX_LENGTH = 2000

type FormValues = {
    id: string
    narrative: string
    category: NarrativeCategory
    personId?: string
}

const NarrativeContainer: FC<
    React.PropsWithChildren<{
        label: string
        companyId: string
        category: NarrativeCategory
        personId?: string
        isEmptyState?: boolean
    }>
> = ({ label, companyId, category, personId, isEmptyState = false, children }) => {
    const [form] = Form.useForm()
    const [disableWhileSubmitting, setDisableWhileSubmitting] = useState(false)
    const { narratives, mutations, isValidating: loading } = useCompanyNarrative(companyId)

    const narrative = narratives?.find((n) => n.category === category && n.person?.id === personId)

    useEffect(() => {
        form.setFieldsValue({
            id: narrative?.id ?? '',
            narrative: narrative?.narrative ?? '',
            category: narrative?.category ?? category,
            personId: personId,
        })
    }, [narrative, personId, category, form])

    const saveNarrative = async (values: FormValues) => {
        try {
            setDisableWhileSubmitting(true)
            values.id
                ? await mutations.updateNarrative(values)
                : await mutations.createNarrative(values)

            message.info('Successfully saved narrative')
        } catch (e) {
            message.error(`Failed to save narrative: ${(e as Error).message}`)
        } finally {
            setDisableWhileSubmitting(false)
        }
    }

    if (!narrative && loading) {
        return <Spinner />
    }

    return (
        <NarrativeFormContainer>
            <Title level={5}>{label}</Title>

            <Form form={form} onFinish={saveNarrative} layout="vertical">
                <Form.Item name="id" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="category" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                {children}
                <Form.Item name="narrative">
                    <TextArea
                        autoSize={{ minRows: 3 }}
                        showCount
                        maxLength={NARRATIVE_MAX_LENGTH}
                        disabled={isEmptyState}
                    />
                </Form.Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    disabled={disableWhileSubmitting || isEmptyState}
                >
                    Save
                </Button>
            </Form>
        </NarrativeFormContainer>
    )
}

const NarrativeFormContainer = styled.div`
    width: ${inputWidth.huge};
`

const Title = styled(Typography.Title)`
    margin-top: 0;
`

export default NarrativeContainer
