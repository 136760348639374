/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Oberon Client
 * OpenAPI spec version: 1.0.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('oberonV1')

export type ResendVerificationEmailVParams = {
    companyId?: string
}

export type SearchEmployeesVParams = {
    /**
     * Performs a partial search on firstName and lastName
     */
    text_search?: string
    /**
     * If organization_id and partner_id is not provided then this field will be required. Please only provide one of company_id or organization_id.
     */
    company_id?: string
    /**
     * If company_id and partner_id is not provided then this field will be required. Please only provide one of company_id or organization_id.
     */
    organization_id?: string
    /**
     * If teamIds and employeeIds are both provided then only employees on both teams and with the provided employeeIds will be returned.
     */
    team_id?: string[]
    /**
     * If company_id and organization_id is not provided then this field will be required
     */
    partner_id?: string
    employee_id?: string[]
    email?: string
    user_id?: string
    role?: Role
    type?: Type
    is_spending_entity?: boolean
    verified?: boolean
    /**
     * This query parameter needs to be used with either company_id or organization_id.
     */
    include_deactivated?: boolean
    has_card_access?: boolean
    /**
     * Including deleted employees will also include deactivated employees.
     */
    include_deleted?: boolean
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type SearchCardsParams = {
    company_id?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type PairCardParams = {
    employee_id?: string
    pan?: string
}

export type GetCardParams = {
    include_deleted?: boolean
}

export type CreateVirtualCardParams = {
    employee_id?: string
}

export type GetCardsByFiltersParams = {
    company_id?: string
    employee_id?: string
    statuses?: CardStatus[]
    type?: CardType
    features?: CardFeatureType[]
    include_deleted?: boolean
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type GetUserAddingRequestsType =
    (typeof GetUserAddingRequestsType)[keyof typeof GetUserAddingRequestsType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUserAddingRequestsType = {
    ON_BEHALF_OF_EMPLOYEE: 'ON_BEHALF_OF_EMPLOYEE',
    EMPLOYEE_SIGN_UP: 'EMPLOYEE_SIGN_UP',
} as const

export type GetUserAddingRequestsStatus =
    (typeof GetUserAddingRequestsStatus)[keyof typeof GetUserAddingRequestsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUserAddingRequestsStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
} as const

export type GetUserAddingRequestsParams = {
    companyId?: string
    organizationId?: string
    status?: GetUserAddingRequestsStatus
    type?: GetUserAddingRequestsType
    before?: string
    after?: string
    limit?: number
}

export type GetTeamByIdVParams = {
    includeDeleted?: boolean
}

export type SearchTeamsVParams = {
    company_id?: string
    organization_id?: string
    employee_id?: string[]
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type SearchOrganizationsVParams = {
    name_prefix?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type SearchLimitsParams = {
    company_id?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type GetEmployeeSpendParams = {
    company_id?: string
    employee_id?: string
    include_wallet_balance?: boolean
}

export type GetLimitsByFiltersParams = {
    company_id?: string
    employee_id?: string
    group?: LimitGroup
    type?: LimitType
    include_deleted?: boolean
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type DeleteLimitsByFilterParams = {
    company_id?: string
    employee_id?: string
}

export type GetUserAddingRequestsCountType =
    (typeof GetUserAddingRequestsCountType)[keyof typeof GetUserAddingRequestsCountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUserAddingRequestsCountType = {
    ON_BEHALF_OF_EMPLOYEE: 'ON_BEHALF_OF_EMPLOYEE',
    EMPLOYEE_SIGN_UP: 'EMPLOYEE_SIGN_UP',
} as const

export type GetUserAddingRequestsCountStatus =
    (typeof GetUserAddingRequestsCountStatus)[keyof typeof GetUserAddingRequestsCountStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUserAddingRequestsCountStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
} as const

export type GetUserAddingRequestsCountParams = {
    companyId?: string
    organizationId?: string
    status?: GetUserAddingRequestsCountStatus
    type?: GetUserAddingRequestsCountType
}

export type CreateUserAddingRequestsParams = {
    companyId?: string
}

export type GetTeamsForParams = {
    companyId?: string
    organizationId?: string
    before?: string
    after?: string
    limit?: number
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export type SetOrganizationParams = {
    name?: string
}

export type CreateTeamParams = {
    companyId?: string
    organizationId?: string
}

export type GetStoragePropertyParams = {
    companyId?: string
}

export type GetSignUpUserCompaniesParams = {
    'user-id'?: string
    'verify-token'?: string
}

export type GetProvisionedUserEventLogsParams = {
    /**
     * Only return activity events for provisioned users that belong to the given company
     */
    company_id?: string
    /**
     * Only return return activity events for provisioned users that belong to the given organization.
     */
    organization_id?: string
    /**
     * Only return activity events for provisioned users that have the given employee ID.
     */
    employee_id?: string
    /**
     * Only return activity events for the provisioned user with the given ID. In this case, companyId, organizationId, and employeeId must not be provided.
     */
    provisioned_user_id?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type SearchProvisionedUsersParams = {
    /**
     * Only return provisioned users that belong to the given company
     */
    company_id?: string
    /**
     * Only return provisioned users that belong to the given organization.
     */
    organization_id?: string
    /**
 * Only return provisioned users that have an employee associated or that have not.    
             Having an employee means that the user has been invited to Pleo.
 */
    has_employee?: boolean
    /**
 * Only return Provisioned Users that have the SCIM 'active' attribute set to the given value.
            | If not provided, no filter is applied to active.
 */
    active?: boolean
    /**
     * Only return provisioned users that have one of the given job titles.
     */
    job_title?: string[]
    /**
     * Only return provisioned users after the given date.
     */
    provisioned_after?: string
    /**
     * Only return provisioned users linked to the given employee IDs.
     */
    employee_ids?: string[]
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type RequestSelfExpansionParams = {
    newSelfInitiatedExpansionFlow?: boolean
}

export type SearchOrganizationsParams = {
    query?: string
}

export type GetActionsCountParams = {
    status?: string[]
    requesterEmail?: string
}

export type GetOrganizationGroupingRequestActionsParams = {
    status?: string[]
    requesterEmail?: string
    companyId?: string
    registryId?: string
    actionId?: string
    before?: string
    after?: string
    limit?: number
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export type GetOrganizationGroupingRequestsParams = {
    companyId?: string
    registryId?: string
    requesterEmail?: string
    before?: string
    after?: string
    limit?: number
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export type ImportAccountCategoriesBodyFile = { [key: string]: any }

export type ImportAccountCategoriesBody = {
    file?: ImportAccountCategoriesBodyFile
}

export type ImportAccountCategoriesParams = {
    dryRun?: boolean
}

export type ExportAccountCategoriesParams = {
    categoryIds?: string[]
}

export type GetCompaniesParams = {
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type RemoveTeamAsAdminParams = {
    forceEvent?: boolean
}

export type StartImportType = (typeof StartImportType)[keyof typeof StartImportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StartImportType = {
    EMPLOYEE: 'EMPLOYEE',
    TAG_GROUP: 'TAG_GROUP',
    TAG_GROUP_ROW: 'TAG_GROUP_ROW',
    TAG_GROUP_COLUMN: 'TAG_GROUP_COLUMN',
    TAG_GROUP_VALUE: 'TAG_GROUP_VALUE',
} as const

export type StartImportParams = {
    type?: StartImportType
    from_id?: string
    count?: number
    updatedAfter?: string
    updatedBefore?: string
}

export type StopImportType = (typeof StopImportType)[keyof typeof StopImportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StopImportType = {
    EMPLOYEE: 'EMPLOYEE',
    TAG_GROUP: 'TAG_GROUP',
    TAG_GROUP_ROW: 'TAG_GROUP_ROW',
    TAG_GROUP_COLUMN: 'TAG_GROUP_COLUMN',
    TAG_GROUP_VALUE: 'TAG_GROUP_VALUE',
} as const

export type StopImportParams = {
    type?: StopImportType
}

export interface WriteListener {
    [key: string]: any
}

export interface UserAddingRequestsCountResponse {
    count: number
}

export type UserAddingRequestTypeResponseModel =
    (typeof UserAddingRequestTypeResponseModel)[keyof typeof UserAddingRequestTypeResponseModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestTypeResponseModel = {
    ON_BEHALF_OF_EMPLOYEE: 'ON_BEHALF_OF_EMPLOYEE',
    EMPLOYEE_SIGN_UP: 'EMPLOYEE_SIGN_UP',
} as const

export type UserAddingRequestStatusResponseModel =
    (typeof UserAddingRequestStatusResponseModel)[keyof typeof UserAddingRequestStatusResponseModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestStatusResponseModel = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
} as const

export type UserAddingRequestResponseModelDeclineReason =
    (typeof UserAddingRequestResponseModelDeclineReason)[keyof typeof UserAddingRequestResponseModelDeclineReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestResponseModelDeclineReason = {
    MORE_INFORMATION_NEEDED: 'MORE_INFORMATION_NEEDED',
    NOT_WORK_EMAIL: 'NOT_WORK_EMAIL',
    TOO_MANY_USERS: 'TOO_MANY_USERS',
    OTHER: 'OTHER',
} as const

export interface UserAddingRequestResponseModel {
    company?: GetCompanyResponse
    companyId: string
    createdAt: string
    declineReason?: UserAddingRequestResponseModelDeclineReason
    deletedAt?: string
    email: string
    id: string
    requesterEmployeeId?: string
    requesterFirstName?: string
    requesterLastName?: string
    requesterNotes: string
    requesterTitle?: string
    requesterUserId?: string
    reviewedByEmployeeId?: string
    reviewerNotes?: string
    status: UserAddingRequestStatusResponseModel
    type: UserAddingRequestTypeResponseModel
    updatedAt?: string
}

export type UserAddingRequestResponseError =
    (typeof UserAddingRequestResponseError)[keyof typeof UserAddingRequestResponseError]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestResponseError = {
    invalid_email: 'invalid_email',
    team_not_found: 'team_not_found',
    notes_exceed_maximum_length: 'notes_exceed_maximum_length',
    employee_already_exists: 'employee_already_exists',
    internal_server_error: 'internal_server_error',
} as const

export interface UserAddingRequestResponse {
    email: string
    error?: UserAddingRequestResponseError
    id?: string
    success: boolean
}

export type UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseType =
    (typeof UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseType)[keyof typeof UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseType = {
    ON_BEHALF_OF_EMPLOYEE: 'ON_BEHALF_OF_EMPLOYEE',
} as const

export interface UserAddingRequestOnBehalfOfEmployeeRequesterView {
    avatar?: string
    email?: string
    employeeId: string
    firstName?: string
    lastName?: string
    userId?: string
}

export type UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseAllOf = {
    requester?: UserAddingRequestOnBehalfOfEmployeeRequesterView
}

export type UserAddingRequestOnBehalfOfEmployeeRequesterViewResponse =
    UserAddingRequestAdminViewResponseV2 &
        UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseAllOf & {
            type: UserAddingRequestOnBehalfOfEmployeeRequesterViewResponseType
        }

export type UserAddingRequestEmployeeSignUpViewResponseType =
    (typeof UserAddingRequestEmployeeSignUpViewResponseType)[keyof typeof UserAddingRequestEmployeeSignUpViewResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddingRequestEmployeeSignUpViewResponseType = {
    EMPLOYEE_SIGN_UP: 'EMPLOYEE_SIGN_UP',
} as const

export interface UserAddingRequestEmployeeSignUpRequesterView {
    email?: string
    firstName?: string
    lastName?: string
    title?: string
    userId?: string
}

export type UserAddingRequestEmployeeSignUpViewResponseAllOf = {
    requester?: UserAddingRequestEmployeeSignUpRequesterView
}

export interface UserAddingRequestAdminViewResponseV2 {
    companyId: string
    createdAt: string
    email: string
    id: string
    notes: string
    status: UserAddingRequestStatusResponseModel
    type: UserAddingRequestTypeResponseModel
}

export type UserAddingRequestEmployeeSignUpViewResponse = UserAddingRequestAdminViewResponseV2 &
    UserAddingRequestEmployeeSignUpViewResponseAllOf & {
        type: UserAddingRequestEmployeeSignUpViewResponseType
    }

export interface UserAddingRequest {
    email: string
    notes: string
}

export interface UpdateOrganizationGroupingRequestActionModel {
    reviewerEmail?: string
    status: string
}

export interface UpdateCardStatusRequest {
    status: CardStatus
}

export type Type = (typeof Type)[keyof typeof Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Type = {
    COMPANY: 'COMPANY',
    BOOKKEEPER: 'BOOKKEEPER',
    PARTNER: 'PARTNER',
} as const

export interface TeamViewResponseModel {
    code?: string
    companyId?: string
    deletedAt?: string
    id: string
    members?: string[]
    name: string
    organizationId?: string
}

export interface TeamUpdateRequest {
    code?: string
    name?: string
}

export interface TeamMember {
    avatar?: string
    companyName?: string
    firstName?: string
    id: string
    lastName?: string
}

export interface TeamCreateRequest {
    code?: string
    companyId?: string
    name: string
    organizationId?: string
}

export interface SyncerResult {
    checked: number
    deSynced: number
    fixed: number
}

export interface SyncTeamMemberRequest {
    employeeId: string
    teamId: string
}

export interface StorageKeyResponse {
    key: string
    value?: string
}

/**
 * 
        INVITED: An employee is in the invited state if they have been invited to pleo but have not yet accepted the invitation
        ACTIVE: An employee is in the active state if they have accepted the invitation
        DEACTIVATED: An employee is in the deactivated state if they have been de-provisioned but not yet deleted
        DELETED: An employee is in the deleted state if they have been deleted, this could have been done by a user or by being de-provisioned and then deleted
        INVALID: An employee is in the invalid state if there is an issue calculating the state
 */
export type State = (typeof State)[keyof typeof State]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const State = {
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    DELETED: 'DELETED',
    INVALID: 'INVALID',
} as const

export interface SignUpUserCompany {
    address?: CompanyAddressResponseModel
    id: string
    isMultiEntity: boolean
    name: string
}

export interface ServletOutputStream {
    isReady?: boolean
    writeListener?: WriteListener
}

export interface SendOnboardingSmsRequest {
    phone: string
    verifyToken: string
}

export interface SelfExpansionRequestModel {
    addNewEntities: boolean
    countries?: string[]
    groupExistingEntities: boolean
}

export interface SearchLimitsRequest {
    /** Only return limits for the given employee IDs.  */
    employeeIds?: string[]
    group?: LimitGroup
    /** Only return limits for the given IDs.  */
    ids?: string[]
    /** Include deleted limits */
    includeDeleted?: boolean
    type?: LimitType
}

export interface SearchCardsRequest {
    /** Only return cards for the employee IDs. */
    employeeIds?: string[]
    /** Only return cards for the given feature types. */
    featureTypes?: CardFeatureType[]
    /** Only return cards for the given IDs. */
    ids?: string[]
    /** Also return cards that are deleted */
    includeDeleted?: boolean
    /** Only return cards for the given statuses. */
    statuses?: CardStatus[]
    /** Only return cards for the given types. */
    types?: CardType[]
}

export type Role = (typeof Role)[keyof typeof Role]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
    MEMBER: 'MEMBER',
    OWNER: 'OWNER',
    BOOKKEEPER_BASIC: 'BOOKKEEPER_BASIC',
    BOOKKEEPER_EXTENDED: 'BOOKKEEPER_EXTENDED',
    BOOKKEEPER_MANAGED: 'BOOKKEEPER_MANAGED',
} as const

export type ResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultError = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorStackTraceItem[]
}

export type ResultDetails = { [key: string]: { [key: string]: any } }

export interface Result {
    details?: ResultDetails
    duration?: number
    error?: ResultError
    isHealthy?: boolean
    message?: string
    time?: number
    timestamp?: string
}

export interface RenameOrganizationRequestModel {
    name: string
}

export interface RemoveCompanyFromOrganizationRequestModel {
    companyId: string
}

export interface ProvisionedUserResponseModel {
    active: boolean
    companyId?: string
    deleteAfter?: string
    department?: string
    displayName?: string
    employeeId?: string
    id: string
    organizationId?: string
    provisionedDate: string
    title?: string
    userName: string
}

export interface ProvisionedUserAdminUpdateEmployeeModel {
    employeeId?: string
}

export interface ProvisionedUserAdminDeleteRequest {
    companyId?: string
    id?: string
    organizationId?: string
}

export interface ProvisionedUserActivationModel {
    cardAccess: boolean
    companyId?: string
}

export interface PeopleExportRequest {
    companyId?: string
    fileName?: string
    organizationId?: string
}

export interface PeopleExportLambdaUserData {
    companyId: string
    employeeId: string
    userId: string
}

export type PeopleExportAdminRequestActor =
    (typeof PeopleExportAdminRequestActor)[keyof typeof PeopleExportAdminRequestActor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleExportAdminRequestActor = {
    ADMIN: 'ADMIN',
    EXTENDED_BOOKKEEPER: 'EXTENDED_BOOKKEEPER',
} as const

export interface PeopleExportAdminRequest {
    actor: PeopleExportAdminRequestActor
    peopleExportRequest: PeopleExportRequest
    userData: PeopleExportLambdaUserData
}

export type PageOrder = (typeof PageOrder)[keyof typeof PageOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageOrder = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST',
} as const

export interface OrganizationViewResponseModel {
    companies: string[]
    id: string
    name: string
}

export interface OrganizationUpdateRequest {
    name: string
}

export interface OrganizationGroupingRequestInfo {
    mainContact: string
    type: string
}

export interface OrganizationGroupingRequestActionsCountResponseModel {
    total: number
}

export interface OrganizationCreateRequest {
    name: string
}

export interface LimitValue {
    date?: string
    value: number
}

export type LimitType = (typeof LimitType)[keyof typeof LimitType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitType = {
    MONTHLY: 'MONTHLY',
    WEEKLY: 'WEEKLY',
    TOTAL: 'TOTAL',
    PER: 'PER',
} as const

export type LimitGroup = (typeof LimitGroup)[keyof typeof LimitGroup]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitGroup = {
    SPEND: 'SPEND',
    TRANSACTION: 'TRANSACTION',
} as const

export interface LimitSpend {
    /** Remaining amount until the limit is reached. */
    balance: number
    /** Unique identifier of the Company the Limit belongs to. */
    companyId: string
    /** Currency of the company the Limit belongs to */
    currency: string
    /** Unique identifier of the Employee the Limit belongs to. */
    employeeId: string
    group: LimitGroup
    /** Unique identifier of Limit (generated on creation). */
    id: string
    /** Current employee spending regarding their limits */
    spend: number
    type: LimitType
    value: LimitValue
    /** Available balance in the wallet of the company the employee belongs to */
    walletBalance?: number
}

export interface LimitRequest {
    employeeId: string
    group: LimitGroup
    type: LimitType
    value: LimitValue
}

export interface Limit {
    /** Unique identifier of the Company the Limit belongs to. */
    companyId: string
    /** Currency of the company the Limit belongs to */
    currency: string
    /** Unique identifier of the Employee the Limit belongs to. */
    employeeId: string
    group: LimitGroup
    /** Unique identifier of Limit (generated on creation). */
    id: string
    type: LimitType
    value: LimitValue
}

export interface KycLevelResponseModel {
    edd?: boolean
    fdd?: boolean
    hedd?: boolean
    hedd1?: boolean
    sdd?: boolean
}

export interface KycLevelDateResponseModel {
    edd?: string
    fdd?: string
    hedd?: string
    hedd1?: string
    sdd?: string
}

export interface ImportCategoryItemSummary {
    addCount: number
    categoryName: string
    categoryType: string
    createNew: boolean
}

export interface ImportCategoriesSummaryResponse {
    items: ImportCategoryItemSummary[]
}

export type HttpServletResponseWriter = { [key: string]: any }

export type HttpServletResponseTrailerFields = { [key: string]: any }

export type HttpServletResponseLocale = {
    country?: string
    displayCountry?: string
    displayLanguage?: string
    displayName?: string
    displayScript?: string
    displayVariant?: string
    extensionKeys?: string[]
    iso3Country?: string
    iso3Language?: string
    language?: string
    script?: string
    unicodeLocaleAttributes?: string[]
    unicodeLocaleKeys?: string[]
    variant?: string
}

export interface HttpServletResponse {
    bufferSize?: number
    characterEncoding?: string
    contentLength?: number
    contentLengthLong?: number
    contentType?: string
    headerNames?: string[]
    isCommitted?: boolean
    locale?: HttpServletResponseLocale
    outputStream?: ServletOutputStream
    status?: number
    trailerFields?: HttpServletResponseTrailerFields
    writer?: HttpServletResponseWriter
}

export interface GetUserResponse {
    avatar?: string
    email?: string
    firstName?: string
    id: string
    lastName?: string
    phone?: string
}

export interface GetTeamResponse {
    code?: string
    companyId?: string
    id: string
    members?: TeamMember[]
    name: string
    organizationId?: string
}

export interface GetTeamAdminResponse {
    code?: string
    companyId?: string
    id: string
    name: string
    organizationId?: string
}

export interface GetOrganizationSimplifiedResponseModel {
    id: string
    name: string
}

export interface GetOrganizationResponseModel {
    companies?: GetCompanyOrganizationModel[]
    id: string
    name: string
}

export interface GetOrganizationGroupingRequestResponse {
    createdAt: string
    id: string
    organizationId?: string
    organizationName?: string
    status: string
    type: string
    updatedAt: string
}

export interface GetOrganizationGroupingRequestActionResponse {
    companyId?: string
    createdAt: string
    id: string
    organizationId?: string
    organizationName?: string
    registryId?: string
    requesterEmail: string
    reviewerEmail?: string
    status: string
    type: string
    updatedAt: string
}

export type GetEmployeeResponseType =
    (typeof GetEmployeeResponseType)[keyof typeof GetEmployeeResponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEmployeeResponseType = {
    BOOKKEEPER: 'BOOKKEEPER',
    COMPANY: 'COMPANY',
    PARTNER: 'PARTNER',
} as const

export type GetEmployeeResponseRole =
    (typeof GetEmployeeResponseRole)[keyof typeof GetEmployeeResponseRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEmployeeResponseRole = {
    member: 'member',
    owner: 'owner',
    'bookkeeper-extended': 'bookkeeper-extended',
    'bookkeeper-basic': 'bookkeeper-basic',
} as const

export interface GetEmployeeResponse {
    avatar?: string
    companyId: string
    email?: string
    firstName?: string
    id: string
    jobTitle?: string
    lastName?: string
    phone?: string
    role: GetEmployeeResponseRole
    type: GetEmployeeResponseType
    userId: string
    verified: boolean
}

export interface GetCompanyResponse {
    address?: CompanyAddressResponseModel
    createdAt: string
    id: string
    name: string
    organizationId?: string
}

export interface GetCompanyOrganizationModel {
    address?: CompanyAddressResponseModel
    companyId: string
    id: string
    name: string
}

export interface GetCompanyAccountCategoriesExportResponse {
    filename: string
    url: string
}

export interface ExpandRequest {
    addNewEntities: boolean
    countries?: string[]
    groupExistingEntities: boolean
}

export interface EventType {
    eventSubType: string
    eventType: string
    eventTypeVersion?: number
    feature: string
}

export type EventLogDimensions = { [key: string]: string[] }

export type EventLogData = { [key: string]: any }

export interface EventLog {
    companyId?: string
    data?: EventLogData
    dimensions?: EventLogDimensions
    emittedAt?: string
    id: string
    insertedAt?: string
    occurredAt: string
    resourceId?: string
    source?: string
    sourceUserId?: string
    sourceUserSystem?: string
    type: EventType
}

export type EntityResponseModelRole =
    (typeof EntityResponseModelRole)[keyof typeof EntityResponseModelRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityResponseModelRole = {
    member: 'member',
    owner: 'owner',
    bookkeeper: 'bookkeeper',
} as const

export interface EntityResponseModel {
    companyId: string
    companyName?: string
    companyUserId: string
    isSpendingEntity: boolean
    organizationId?: string
    organizationName?: string
    role: EntityResponseModelRole
}

export type EmployeeUpdateRequestMetadata = { [key: string]: { [key: string]: any } }

export interface EmployeeUpdateRequest {
    avatar?: Avatar
    code?: string
    companyId?: string
    email: string
    firstName: string
    hasCardAccess: boolean
    isSpendingEntity: boolean
    jobTitle?: string
    lastName: string
    metadata?: EmployeeUpdateRequestMetadata
    partnerId?: string
    phone?: string
    role?: Role
    type: Type
    userId?: string
    verified: boolean
}

export type EmployeeResponseModelMetadata = { [key: string]: { [key: string]: any } }

export interface EmployeeResponseModel {
    avatar?: Avatar
    code?: string
    companyId?: string
    createdAt: string
    deletedAt?: string
    email: string
    firstName: string
    hasCardAccess: boolean
    id: string
    isSpendingEntity: boolean
    jobTitle?: string
    lastName: string
    metadata?: EmployeeResponseModelMetadata
    partnerId?: string
    phone?: string
    role?: string
    state: State
    type: string
    updatedAt: string
    userId?: string
    verified: boolean
}

export interface EmployeeDeleteRequest {
    deactivatedBy: string
}

export type EmployeeCreateRequestMetadata = { [key: string]: { [key: string]: any } }

export interface EmployeeCreateRequest {
    code?: string
    companyId?: string
    email: string
    firstName: string
    hasCardAccess: boolean
    id?: string
    isSpendingEntity: boolean
    jobTitle?: string
    lastName: string
    metadata?: EmployeeCreateRequestMetadata
    partnerId?: string
    phone?: string
    role?: Role
    type: Type
    userId?: string
    verified: boolean
}

export interface DataUserAddingRequestsCountResponse {
    data: UserAddingRequestsCountResponse
}

export interface DataResponseUserAddingRequestResponseModel {
    data: UserAddingRequestResponseModel
}

export interface DataResponseTeamViewResponseModel {
    data: TeamViewResponseModel
}

export interface DataResponseProvisionedUserResponseModel {
    data: ProvisionedUserResponseModel
}

export interface DataResponseOrganizationViewResponseModel {
    data: OrganizationViewResponseModel
}

export interface DataResponseListAddEmployeeToTeamResponse {
    data: AddEmployeeToTeamResponse[]
}

export interface DataResponseLimitSpend {
    data: LimitSpend
}

export interface DataResponseLimit {
    data: Limit
}

export interface DataResponseEmployeeResponseModel {
    data: EmployeeResponseModel
}

export interface DataResponseCompanyResponseModel {
    data: CompanyResponseModel
}

export interface DataResponseCard {
    data: Card
}

export interface DataListSignUpUserCompany {
    data: SignUpUserCompany[]
}

export interface DataListApproveOrDeclineUserAddingRequestResponse {
    data: ApproveOrDeclineUserAddingRequestResponse[]
}

export interface CursorPaginatedResponseUserAddingRequestAdminViewResponseV2 {
    data: UserAddingRequestAdminViewResponseV2[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseTeamViewResponseModel {
    data: TeamViewResponseModel[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseProvisionedUserResponseModel {
    data: ProvisionedUserResponseModel[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseOrganizationViewResponseModel {
    data: OrganizationViewResponseModel[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseLimit {
    data: Limit[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseGetTeamResponse {
    data: GetTeamResponse[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseGetOrganizationGroupingRequestResponse {
    data: GetOrganizationGroupingRequestResponse[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseGetOrganizationGroupingRequestActionResponse {
    data: GetOrganizationGroupingRequestActionResponse[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseGetCompanyResponse {
    data: GetCompanyResponse[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseEventLog {
    data: EventLog[]
    pagination: CursorPageInfo
}

export interface CursorPaginatedResponseCard {
    data: Card[]
    pagination: CursorPageInfo
}

export type CursorPageCurrentRequestInfoParameters = { [key: string]: string[] }

export interface CursorPageCurrentRequestInfo {
    after?: string
    before?: string
    limit?: number
    offset?: number
    parameters: CursorPageCurrentRequestInfoParameters
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export interface CursorPageInfo {
    currentRequestPagination: CursorPageCurrentRequestInfo
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
    total?: number
}

export interface CursorPaginatedResponseEmployeeResponseModel {
    data: EmployeeResponseModel[]
    pagination: CursorPageInfo
}

export interface CreateUsersAddingRequestResponse {
    data: UserAddingRequestResponse[]
}

export interface CreateUsersAddingRequest {
    userAddingRequests: UserAddingRequest[]
}

export interface CreateUserRequest {
    avatar?: string
    email: string
    firstName?: string
    id: string
    lastName?: string
    phone?: string
}

export interface CreateTeamRequest {
    code?: string
    name: string
}

export interface CreateOrganizationRequestModel {
    name: string
}

export interface CreateOrganizationGroupingRequestResponseModel {
    id: string
}

export interface CreateOrganizationGroupingRequestModel {
    companyIdsToAdd?: string[]
    companyIdsToRemove?: string[]
    mainContact: string
    organizationId?: string
    organizationName?: string
    registryIdsToCreate?: string[]
    type: string
}

export interface CreateOrganizationAndCompanyRequest {
    address?: CompanyAddress
    existingCompanyId: string
    incorporationDate?: string
    isManualSignup: boolean
    name?: string
    numberEmployees?: string
    organizationName: string
    registrationNumber?: string
    registryId?: string
}

export interface CreateEmployeeSignUpUserAddingRequestInput {
    companyId?: string
    firstName?: string
    lastName?: string
    notes: string
    title?: string
}

export type CreateEmployeeRequestType =
    (typeof CreateEmployeeRequestType)[keyof typeof CreateEmployeeRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateEmployeeRequestType = {
    BOOKKEEPER: 'BOOKKEEPER',
    COMPANY: 'COMPANY',
    PARTNER: 'PARTNER',
} as const

export type CreateEmployeeRequestRole =
    (typeof CreateEmployeeRequestRole)[keyof typeof CreateEmployeeRequestRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateEmployeeRequestRole = {
    member: 'member',
    owner: 'owner',
    'bookkeeper-extended': 'bookkeeper-extended',
    'bookkeeper-basic': 'bookkeeper-basic',
} as const

export interface CreateEmployeeRequest {
    avatar?: string
    companyId: string
    firstName?: string
    id: string
    jobTitle?: string
    lastName?: string
    role: CreateEmployeeRequestRole
    type: CreateEmployeeRequestType
    userId: string
    verified: boolean
}

export interface CreateCompanyTeamRequest {
    code?: string
    id: string
    memberEmployeeIds?: string
    name: string
}

export interface CreateCompanyRequest {
    address?: CompanyAddressRequestModel
    id: string
    name: string
}

export interface CreateCompanyInOrganizationResponse {
    id: string
    name: string
    organizationId: string
}

export interface CreateCompanyInOrganizationRequest {
    address?: CompanyAddress
    incorporationDate?: string
    isManualSignup: boolean
    name: string
    numberEmployees?: string
    organizationId: string
    registrationNumber: string
    registryId?: string
}

export interface CreateCompanyInOrgRequest {
    registryId: string
}

export interface ConvertToOrganizationTeamRequest {
    organizationId: string
}

export interface CompanyResponseModel {
    additionalProperties: AdditionalPropertiesResponseModel
    address: AddressResponseModel
    billingAdminId?: string
    createdAt: string
    currency?: string
    deletedAt?: string
    domainName?: string
    id: string
    name: string
    onboardedVia: string
    organizationId?: string
    ownPartnerId?: string
    ownerEmail?: string
    ownerId?: string
    plan?: string
    registrationNumber: string
    styxId?: string
    updatedAt: string
}

export interface CompanyAddressResponseModel {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export interface CompanyAddressRequestModel {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export interface CompanyAddress {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export interface CheckTeamEmployeeResponse {
    checked: number
    deSynced: number
    fixed: number
}

export interface CheckSyncRequest {
    endDate?: string
    ids?: string[]
    shouldSync: boolean
    startDate?: string
}

/**
 * The card type defines if the card is virtual or physical.
 */
export type CardType = (typeof CardType)[keyof typeof CardType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardType = {
    VIRTUAL: 'VIRTUAL',
    PHYSICAL: 'PHYSICAL',
} as const

export type CardStatus = (typeof CardStatus)[keyof typeof CardStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardStatus = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export type CardFeatureType = (typeof CardFeatureType)[keyof typeof CardFeatureType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardFeatureType = {
    TEMPORARY: 'TEMPORARY',
    DEDICATED: 'DEDICATED',
    NO_FEATURES: 'NO_FEATURES',
} as const

export type CardFeatureDataData = { [key: string]: { [key: string]: any } }

/**
 * Specific data for the implemented feature.
 */
export interface CardFeatureData {
    data: CardFeatureDataData
    type: CardFeatureType
}

export interface Card {
    /** If card will expire in less than 60 days */
    aboutToExpire: boolean
    /** Unique identifier of the Card order the Card was create from. */
    cardOrderId?: string
    /** Unique identifier of the company the employee of the card belongs to. */
    companyId: string
    /** Days until the card expires */
    daysToExpiration: number
    /** If the card was disabled by an admin of the employee. */
    disabledByAdmin: boolean
    /** If the card was disabled by a limit. */
    disabledByLimit: boolean
    /** Embossline4 of the card. */
    embossedLine4?: string
    /** Embossline of the card. */
    embossedName?: string
    /** Unique identifier of the Employee the Card belongs to. */
    employeeId?: string
    /** The date when the card expires. */
    expiration: string
    /** The feature type the card implements. */
    featureTypes: CardFeatureType[]
    /** Specific data for the implemented feature. */
    features: CardFeatureData[]
    /** Unique identifier of cards (generated on creation). */
    id: string
    /** If the card is Apple Wallet capable. */
    isAppleWalletCapable: boolean
    /** If the card is Google Wallet capable. */
    isGoogleWalletCapable: boolean
    /** The issuer of the card. */
    issuer: string
    /** Last 4 digits of the PAN of the card. */
    panLast4: string
    /** If the employee exceeded card PIN retries. */
    pinTriesExceeded: boolean
    status: CardStatus
    /** If the card implements storebox. */
    storebox: boolean
    /** When the card should be reactivated if it was disabled by a limit. */
    toBeReReactivatedAt?: string
    /** If transaction limit is restricted to avoid overspending */
    transactionLimitRestricted: boolean
    type: CardType
    /** Unique identifier of the wallet the card belongs to. */
    walletId: string
}

export interface BackfillRequestDto {
    endDate: string
    startDate: string
}

export interface BackfillRequest {
    from?: string
    ids?: string[]
    to?: string
}

export interface Avatar {
    url: string
}

export type ApproveOrDeclineUserAddingRequestResponseError =
    (typeof ApproveOrDeclineUserAddingRequestResponseError)[keyof typeof ApproveOrDeclineUserAddingRequestResponseError]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApproveOrDeclineUserAddingRequestResponseError = {
    user_adding_request_not_found: 'user_adding_request_not_found',
    not_work_email: 'not_work_email',
} as const

export interface ApproveOrDeclineUserAddingRequestResponse {
    error?: ApproveOrDeclineUserAddingRequestResponseError
    id: string
    success: boolean
}

export type ApproveOrDeclineUserAddingRequestStatus =
    (typeof ApproveOrDeclineUserAddingRequestStatus)[keyof typeof ApproveOrDeclineUserAddingRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApproveOrDeclineUserAddingRequestStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
} as const

export type ApproveOrDeclineUserAddingRequestDeclineReason =
    (typeof ApproveOrDeclineUserAddingRequestDeclineReason)[keyof typeof ApproveOrDeclineUserAddingRequestDeclineReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApproveOrDeclineUserAddingRequestDeclineReason = {
    MORE_INFORMATION_NEEDED: 'MORE_INFORMATION_NEEDED',
    NOT_WORK_EMAIL: 'NOT_WORK_EMAIL',
    TOO_MANY_USERS: 'TOO_MANY_USERS',
    OTHER: 'OTHER',
} as const

export interface ApproveOrDeclineUserAddingRequest {
    declineReason?: ApproveOrDeclineUserAddingRequestDeclineReason
    id: string
    notes?: string
    status: ApproveOrDeclineUserAddingRequestStatus
}

export interface ApproveOrDeclineUserAddingRequests {
    data: ApproveOrDeclineUserAddingRequest[]
}

export interface AddressResponseModel {
    addressLine1?: string
    addressLine2?: string
    country?: string
    locality?: string
    postalCode?: string
    region?: string
}

export type AdditionalPropertiesResponseModelTerms = { [key: string]: any }

export type AdditionalPropertiesResponseModelPartnerMetadata = { [key: string]: any }

export type AdditionalPropertiesResponseModelInfo = { [key: string]: any }

export type AdditionalPropertiesResponseModelFeatures = { [key: string]: { [key: string]: any } }

export interface AdditionalPropertiesResponseModel {
    bookkeeperEmployeeId?: string
    complianceInfoRequestedLast?: string
    deactivated?: string
    deactivatedBy?: string
    features?: AdditionalPropertiesResponseModelFeatures
    fees?: string
    info?: AdditionalPropertiesResponseModelInfo
    kyc?: KycLevelResponseModel
    kycDate?: KycLevelDateResponseModel
    manualSignup?: boolean
    numemployees?: string
    partnerMetadata?: AdditionalPropertiesResponseModelPartnerMetadata
    partnerOnboarding?: boolean
    partnerOwnCompany?: boolean
    readyForExportSwitchDate?: string
    recardingDate?: string
    recardingNotificationSent?: boolean
    referredAt?: string
    selfOnboarding?: boolean
    terms?: AdditionalPropertiesResponseModelTerms
    trialDate?: string
    trialLength?: string
}

export interface AddMembersToTeamRequest {
    employeeIds: string[]
}

export interface AddEmployeesToTeamRequest {
    employeeIds: string[]
}

export interface AddEmployeeToTeamResponse {
    employeeId: string
    error?: string
    success: boolean
}

export interface AddCompanyToOrganizationRequestModel {
    companyId: string
}

export interface AddCompanyToOrganizationRequest {
    companyId: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Returns the health of the service
 */
export const check = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<Result>({ url: `/health`, method: 'get' }, options)
}

/**
 * @summary Admin endpoint used to backfill organization data using kafka. This endpoints allows to backfill all organization data, or a subset of it by filtering it by Ids or a date range.
 */
export const backfillOrganization = (
    backfillRequest: BackfillRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/backfill/organization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: backfillRequest,
        },
        options
    )
}

export const backfillProvisionedUsers = (
    backfillRequestDto: BackfillRequestDto,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/backfill/provisioned-users`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: backfillRequestDto,
        },
        options
    )
}

/**
 * @summary Create companies as an admin
 */
export const createCompanies = (
    createCompanyRequest: CreateCompanyRequest[],
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/companies`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyRequest,
        },
        options
    )
}

/**
 * @summary Create company as an admin
 */
export const createCompany = (
    createCompanyRequest: CreateCompanyRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetCompanyResponse>(
        {
            url: `/v1/admin/company`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyRequest,
        },
        options
    )
}

/**
 * @summary GET company as an admin
 */
export const getCompany = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetCompanyResponse>(
        { url: `/v1/admin/company/${companyId}`, method: 'get' },
        options
    )
}

/**
 * @summary Updates if it already exists or creates a new team as an admin
 */
export const createTeam = (
    companyId: string,
    createCompanyTeamRequest: CreateCompanyTeamRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamAdminResponse>(
        {
            url: `/v1/admin/company/${companyId}/team`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyTeamRequest,
        },
        options
    )
}

/**
 * @summary Create employee as a system admin
 */
export const createSingleEmployee = (
    createEmployeeRequest: CreateEmployeeRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetEmployeeResponse>(
        {
            url: `/v1/admin/employee`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createEmployeeRequest,
        },
        options
    )
}

/**
 * @summary Fetch employee as an admin. Data is synced asynchronously so it not guaranteed to be identical to the source of truth.
 */
export const getEmployee = (employeeId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetEmployeeResponse>(
        { url: `/v1/admin/employee/${employeeId}`, method: 'get' },
        options
    )
}

/**
 * @summary Create employees as a system admin
 */
export const createEmployees = (
    createEmployeeRequest: CreateEmployeeRequest[],
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/employees`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createEmployeeRequest,
        },
        options
    )
}

/**
 * @summary Stop a data import
 */
export const stopImport = (
    params: StopImportParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v1/admin/import`, method: 'delete', params }, options)
}

/**
 * @summary Start a data import
 */
export const startImport = (
    params: StartImportParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v1/admin/import`, method: 'post', params }, options)
}

/**
 * @summary Create an organization
 */
export const createOrganization = (
    createOrganizationRequestModel: CreateOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/admin/organization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createOrganizationRequestModel,
        },
        options
    )
}

/**
 * @summary Delete an organization
 */
export const deleteOrganization = (orgId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<boolean>({ url: `/v1/admin/organization/${orgId}`, method: 'post' }, options)
}

/**
 * @summary Add a company to the given organization
 */
export const addCompanyToOrganization = (
    orgId: string,
    addCompanyToOrganizationRequestModel: AddCompanyToOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/admin/organization/${orgId}/companies`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addCompanyToOrganizationRequestModel,
        },
        options
    )
}

/**
 * @summary Create company in org as an admin
 */
export const createCompanyForOrganization = (
    orgId: string,
    createCompanyInOrgRequest: CreateCompanyInOrgRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/admin/organization/${orgId}/company`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyInOrgRequest,
        },
        options
    )
}

/**
 * @summary Removes company from organization
 */
export const removeCompanyFromOrganization = (
    orgId: string,
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        { url: `/v1/admin/organization/${orgId}/company/${companyId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Change name of organization
 */
export const renameOrganization = (
    orgId: string,
    renameOrganizationRequestModel: RenameOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/admin/organization/${orgId}/rename`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: renameOrganizationRequestModel,
        },
        options
    )
}

export const triggerPeopleExportAdmin = (
    peopleExportAdminRequest: PeopleExportAdminRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/people/export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: peopleExportAdminRequest,
        },
        options
    )
}

/**
 * @summary Deletes the provisioned user records by id; or by companyId and/or organizationId. In case id is provided, it deletes the provisioned user by id, ignoring the companyId and organizationId from the request.
 */
export const deleteProvisionedUserRecords = (
    provisionedUserAdminDeleteRequest: ProvisionedUserAdminDeleteRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v1/admin/provisioned-user`,
            method: 'delete',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: provisionedUserAdminDeleteRequest,
        },
        options
    )
}

/**
 * @summary TEMP endpoint - Fixes provisioned users with missing company id
 */
export const fixProvisionedUserWithMissingCompanyId = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/provisioned-user/fix-missing-company-id`, method: 'post' },
        options
    )
}

/**
 * @summary Updates the provisioned user employee id
 */
export const updateProvisionedUser = (
    provisionedUserId: string,
    provisionedUserAdminUpdateEmployeeModel: ProvisionedUserAdminUpdateEmployeeModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/provisioned-user/${provisionedUserId}/employee`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: provisionedUserAdminUpdateEmployeeModel,
        },
        options
    )
}

/**
 * @summary Checks and syncs the company user records on Deimos
 */
export const checkCompanyUsers = (
    checkSyncRequest: CheckSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SyncerResult>(
        {
            url: `/v1/admin/sync/company-user`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: checkSyncRequest,
        },
        options
    )
}

/**
 * @summary Checks and syncs the Employee records on Deimos
 */
export const checkEmployees = (
    checkSyncRequest: CheckSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SyncerResult>(
        {
            url: `/v1/admin/sync/employee`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: checkSyncRequest,
        },
        options
    )
}

/**
 * @summary Checks and syncs the team employee records on Deimos
 */
export const checkTeamEmployees = (
    checkSyncRequest: CheckSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CheckTeamEmployeeResponse>(
        {
            url: `/v1/admin/sync/team-employee`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: checkSyncRequest,
        },
        options
    )
}

/**
 * @summary Sync the member of a team as an admin
 */
export const syncTeamMember = (
    syncTeamMemberRequest: SyncTeamMemberRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/team/member/sync`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: syncTeamMemberRequest,
        },
        options
    )
}

export const publishTeamSnapshots = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/team/publish-snapshots`, method: 'post' },
        options
    )
}

/**
 * @summary Rename archived teams
 */
export const renameArchivedTeams = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetTeamResponse[]>(
        { url: `/v1/admin/team/rename-archived`, method: 'post' },
        options
    )
}

/**
 * @summary Delete team as an admin
 */
export const removeTeamAsAdmin = (
    teamId: string,
    params: RemoveTeamAsAdminParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/team/${teamId}`, method: 'delete', params },
        options
    )
}

/**
 * @summary Remove employee from team
 */
export const removeEmployeeFromTeam = (
    teamId: string,
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/team/${teamId}/employee/${employeeId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Add employees to team as an admin
 */
export const addMembersToTeam = (
    teamId: string,
    addMembersToTeamRequest: AddMembersToTeamRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamAdminResponse>(
        {
            url: `/v1/admin/team/${teamId}/members`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addMembersToTeamRequest,
        },
        options
    )
}

/**
 * @summary Create user as an admin
 */
export const createUser = (
    createUserRequest: CreateUserRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/user`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createUserRequest,
        },
        options
    )
}

/**
 * @summary Fetch user as an admin. Data is synced asynchronously so it not guaranteed to be identical to the source of truth.
 */
export const getUser = (userId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetUserResponse>({ url: `/v1/admin/user/${userId}`, method: 'get' }, options)
}

/**
 * @summary Syncs a single Oberon's User based on the information in Kerberos. A user is a combination of fields from Kerberos User and its latest Deimos Employee updated that are synced asynchronously. This operation gets the latest data from Kerberos and updates the Oberon's User so it's consistent. The employee data is not synced because there is currently no way of getting the latest employee updated based on a userId.
 */
export const syncUser = (userId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v1/admin/user/${userId}:sync`, method: 'post' }, options)
}

/**
 * @summary Create users as an admin
 */
export const createUsers = (
    createUserRequest: CreateUserRequest[],
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/admin/users`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createUserRequest,
        },
        options
    )
}

/**
 * Sorting keys supported are 'id'
 * @deprecated
 * @summary Get all companies
 */
export const getCompanies = (
    params: GetCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseGetCompanyResponse>(
        { url: `/v1/companies`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Get a company
 */
export const getCompany1 = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetCompanyResponse>(
        { url: `/v1/companies/${companyId}`, method: 'get' },
        options
    )
}

/**
 * @summary export account categories
 */
export const exportAccountCategories = (
    companyId: string,
    params: ExportAccountCategoriesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetCompanyAccountCategoriesExportResponse>(
        {
            url: `/v1/company-settings/${companyId}/account-categories/export`,
            method: 'get',
            params,
        },
        options
    )
}

/**
 * @summary import account categories
 */
export const importAccountCategories = (
    companyId: string,
    importAccountCategoriesBody: ImportAccountCategoriesBody,
    params: ImportAccountCategoriesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    const formData = new FormData()
    if (importAccountCategoriesBody.file !== undefined) {
        formData.append('file', JSON.stringify(importAccountCategoriesBody.file))
    }

    return moonRequest<ImportCategoriesSummaryResponse>(
        {
            url: `/v1/company-settings/${companyId}/account-categories/import`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
        },
        options
    )
}

/**
 * @summary Returns all connected entities for the requester
 */
export const getEntities = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<EntityResponseModel[]>({ url: `/v1/entities`, method: 'get' }, options)
}

/**
 * @summary Create a company for an existing organization
 */
export const createCompanyInExistingOrganization = (
    createCompanyInOrganizationRequest: CreateCompanyInOrganizationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateCompanyInOrganizationResponse>(
        {
            url: `/v1/orchestration/create-company-in-organization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyInOrganizationRequest,
        },
        options
    )
}

/**
 * @summary Create an organization and a company
 */
export const createOrganizationAndCompany = (
    createOrganizationAndCompanyRequest: CreateOrganizationAndCompanyRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateCompanyInOrganizationResponse>(
        {
            url: `/v1/orchestration/create-organization-and-company`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createOrganizationAndCompanyRequest,
        },
        options
    )
}

/**
 * @summary Request multi-entity self expansion. It allows you to request different types of expansion: add new entities, group existing entities, and/or select countries.
 */
export const requestExpansionV = (
    expandRequest: ExpandRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v1/orchestration/organization/expand`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: expandRequest,
        },
        options
    )
}

/**
 * Create an organization
 * @deprecated
 */
export const createOrganization1 = (
    createOrganizationRequestModel: CreateOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/organization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createOrganizationRequestModel,
        },
        options
    )
}

/**
 * @summary Get organization grouping requests
 */
export const getOrganizationGroupingRequests = (
    params: GetOrganizationGroupingRequestsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseGetOrganizationGroupingRequestResponse>(
        { url: `/v1/organization-grouping-request`, method: 'get', params },
        options
    )
}

/**
 * @summary Create an organization grouping request
 */
export const createOrganizationGroupingRequest = (
    createOrganizationGroupingRequestModel: CreateOrganizationGroupingRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateOrganizationGroupingRequestResponseModel>(
        {
            url: `/v1/organization-grouping-request`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createOrganizationGroupingRequestModel,
        },
        options
    )
}

/**
 * @summary Get organization grouping request actions
 */
export const getOrganizationGroupingRequestActions = (
    params: GetOrganizationGroupingRequestActionsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseGetOrganizationGroupingRequestActionResponse>(
        { url: `/v1/organization-grouping-request/actions`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the number of organization grouping request actions for the given statuses
 */
export const getActionsCount = (
    params: GetActionsCountParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<OrganizationGroupingRequestActionsCountResponseModel>(
        { url: `/v1/organization-grouping-request/actions/count`, method: 'get', params },
        options
    )
}

/**
 * @summary Update an organization grouping requests action
 */
export const updateOrganizationGroupingRequestAction = (
    actionId: string,
    updateOrganizationGroupingRequestActionModel: UpdateOrganizationGroupingRequestActionModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/organization-grouping-request/actions/${actionId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateOrganizationGroupingRequestActionModel,
        },
        options
    )
}

/**
 * @summary Get an organization grouping requests
 */
export const getOrganizationGroupingRequestsForOrganization = (
    orgId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<OrganizationGroupingRequestInfo[]>(
        { url: `/v1/organization-grouping-request/${orgId}`, method: 'get' },
        options
    )
}

/**
 * Get organization
 * @deprecated
 */
export const getOrganization = (orgId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetOrganizationResponseModel>(
        { url: `/v1/organization/${orgId}`, method: 'get' },
        options
    )
}

/**
 * Remove a company from  given organization
 * @deprecated
 */
export const removeCompanyFromOrganization1 = (
    orgId: string,
    removeCompanyFromOrganizationRequestModel: RemoveCompanyFromOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/organization/${orgId}/companies`,
            method: 'delete',
            headers: { 'Content-Type': '*/*' },
            data: removeCompanyFromOrganizationRequestModel,
        },
        options
    )
}

/**
 * Add a company to the given organization
 * @deprecated
 */
export const addCompanyToOrganization1 = (
    orgId: string,
    addCompanyToOrganizationRequestModel: AddCompanyToOrganizationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationResponseModel>(
        {
            url: `/v1/organization/${orgId}/companies`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addCompanyToOrganizationRequestModel,
        },
        options
    )
}

/**
 * Get the organizations for given user
 * @deprecated
 */
export const getOrganizations = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetOrganizationResponseModel[]>(
        { url: `/v1/organizations`, method: 'get' },
        options
    )
}

/**
 * Fetches organizations
 * @deprecated
 */
export const searchOrganizations = (
    params: SearchOrganizationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetOrganizationSimplifiedResponseModel[]>(
        { url: `/v1/organizations/search`, method: 'get', params },
        options
    )
}

/**
 * Request multi-entity self expansion
 * @deprecated
 */
export const requestSelfExpansion = (
    selfExpansionRequestModel: SelfExpansionRequestModel,
    params: RequestSelfExpansionParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v1/organizations/self-expansion`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: selfExpansionRequestModel,
            params,
        },
        options
    )
}

/**
 * Trigger a CSV export that sends an email with a link to download theresulting CSV file. An export can be created on Company or Organization level.
 * @summary Trigger a people export
 */
export const triggerPeopleExport = (
    peopleExportRequest: PeopleExportRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/people/export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: peopleExportRequest,
        },
        options
    )
}

/**
 * @summary Get Provisioned Users for the given organization or company
 */
export const searchProvisionedUsers = (
    params: SearchProvisionedUsersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseProvisionedUserResponseModel>(
        { url: `/v1/provisioned-user`, method: 'get', params },
        options
    )
}

/**
 * @summary Get event logs for provisioned users
 */
export const getProvisionedUserEventLogs = (
    params: GetProvisionedUserEventLogsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseEventLog>(
        { url: `/v1/provisioned-user/event-log`, method: 'get', params },
        options
    )
}

/**
 * @summary Immediately deletes a Provisioned User. If provisioned user was still active then this endpoint first deactivates the provisioned user and then delete it.
 */
export const deleteProvisionedUser = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v1/provisioned-user/${id}`, method: 'delete' }, options)
}

/**
 * @summary Get Provisioned User by ID
 */
export const getProvisionedUser = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseProvisionedUserResponseModel>(
        { url: `/v1/provisioned-user/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Create Employee record and send invite for the Provisioned User
 */
export const activateProvisionedUser = (
    id: string,
    provisionedUserActivationModel: ProvisionedUserActivationModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseProvisionedUserResponseModel>(
        {
            url: `/v1/provisioned-user/${id}:activate`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: provisionedUserActivationModel,
        },
        options
    )
}

/**
 * 
            - returns empty list if company is multi entity or more than one company matches
            - Used for employees signing up (not invited by admin)
            - Try to match them to a company given their email domain
            
 * @summary Get Companies that match the given user email domain
 */
export const getSignUpUserCompanies = (
    params: GetSignUpUserCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataListSignUpUserCompany>(
        { url: `/v1/sign-up-users/companies`, method: 'get', params },
        options
    )
}

/**
 * Returns the latest user adding request for the given user
 * @summary For sign up users get their latest user adding request
 */
export const getSignUpUserUserAddingRequest = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseUserAddingRequestResponseModel>(
        { url: `/v1/sign-up-users/user-adding-request`, method: 'get' },
        options
    )
}

/**
 * For sign up users to upsert user adding request
            | - user email must match one non multi entity company
            | - cannot have more than one pending request, to avoid spamming the company admins
            | - if domain of user matches multi entity companies, user **must** provide a company id
            | - if company id is provided, and company wasn't not found, will throw an error
            | - used to upsert current pending request if it exists
            | - status code is 201 if request was created, else 200 if it was updated
        
 * @summary For sign up users to upsert user adding request
 */
export const createSignUpUserUserAddingRequest = (
    createEmployeeSignUpUserAddingRequestInput: CreateEmployeeSignUpUserAddingRequestInput,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUserAddingRequestResponseModel>(
        {
            url: `/v1/sign-up-users/user-adding-requests`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createEmployeeSignUpUserAddingRequestInput,
        },
        options
    )
}

/**
 * @summary Get the property with the given key for the company id
 */
export const getStorageProperty = (
    key: string,
    params: GetStoragePropertyParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<StorageKeyResponse>(
        { url: `/v1/storage-property/${key}`, method: 'get', params },
        options
    )
}

/**
 * Create team
 * @deprecated
 */
export const createTeam1 = (
    createTeamRequest: CreateTeamRequest,
    params: CreateTeamParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamResponse>(
        {
            url: `/v1/team`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createTeamRequest,
            params,
        },
        options
    )
}

/**
 * Delete a team
 * @deprecated
 */
export const deleteTeam = (teamId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v1/team/${teamId}`, method: 'delete' }, options)
}

/**
 * Fetch team by id
 * @deprecated
 */
export const getTeamById = (teamId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetTeamResponse>({ url: `/v1/team/${teamId}`, method: 'get' }, options)
}

/**
 * Delete team code by teamId
 * @deprecated
 */
export const deleteTeamCode = (teamId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<GetTeamResponse>(
        { url: `/v1/team/${teamId}/code`, method: 'delete' },
        options
    )
}

/**
 * Update team code by id
 * @deprecated
 */
export const updateTeamCode = (
    teamId: string,
    code: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamResponse>(
        { url: `/v1/team/${teamId}/code/${code}`, method: 'put' },
        options
    )
}

/**
 * Remove employee from team
 * @deprecated
 */
export const removeEmployeeFromTeam1 = (
    teamId: string,
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/team/${teamId}/employee/${employeeId}`, method: 'delete' },
        options
    )
}

/**
 * Add employees to team
 * @deprecated
 */
export const addEmployeesToTeam = (
    teamId: string,
    addEmployeesToTeamRequest: AddEmployeesToTeamRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AddEmployeeToTeamResponse[]>(
        {
            url: `/v1/team/${teamId}/employees`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addEmployeesToTeamRequest,
        },
        options
    )
}

/**
 * Update team
 * @deprecated
 */
export const updateTeamName = (
    teamId: string,
    name: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamResponse>(
        { url: `/v1/team/${teamId}/name/${name}`, method: 'put' },
        options
    )
}

/**
 * Set organization for team
 * @deprecated
 */
export const setOrganization = (
    teamId: string,
    organizationId: string,
    params: SetOrganizationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetTeamResponse>(
        { url: `/v1/team/${teamId}/organization/${organizationId}`, method: 'put', params },
        options
    )
}

/**
 * Get all teams for given input
 * @deprecated
 */
export const getTeamsFor = (
    params: GetTeamsForParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseGetTeamResponse>(
        { url: `/v1/teams`, method: 'get', params },
        options
    )
}

/**
 * @summary Create user adding requests for the given company
 */
export const createUserAddingRequests = (
    createUsersAddingRequest: CreateUsersAddingRequest,
    params: CreateUserAddingRequestsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateUsersAddingRequestResponse>(
        {
            url: `/v1/user-adding-requests`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createUsersAddingRequest,
            params,
        },
        options
    )
}

/**
 * @summary Get user adding requests count by company id or organization id
 */
export const getUserAddingRequestsCount = (
    params: GetUserAddingRequestsCountParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataUserAddingRequestsCountResponse>(
        { url: `/v1/user-adding-requests/count`, method: 'get', params },
        options
    )
}

/**
 * @summary Approve or decline incoming user adding requests
 */
export const approveOrDeclineUserAddingRequests = (
    approveOrDeclineUserAddingRequests: ApproveOrDeclineUserAddingRequests,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataListApproveOrDeclineUserAddingRequestResponse>(
        {
            url: `/v1/user-adding-requests/status`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: approveOrDeclineUserAddingRequests,
        },
        options
    )
}

/**
 * @summary Deletes limits by filters
 */
export const deleteLimitsByFilter = (
    params: DeleteLimitsByFilterParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v2/limits`, method: 'delete', params }, options)
}

/**
 * @deprecated
 * @summary Get limits by filters
 */
export const getLimitsByFilters = (
    params: GetLimitsByFiltersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseLimit>(
        { url: `/v2/limits`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates a limit
 */
export const createLimit = (
    limitRequest: LimitRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseLimit>(
        {
            url: `/v2/limits`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: limitRequest,
        },
        options
    )
}

/**
 * @summary Get employee limit spend
 */
export const getEmployeeSpend = (
    params: GetEmployeeSpendParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseLimitSpend>(
        { url: `/v2/limits/spend`, method: 'get', params },
        options
    )
}

/**
 * @summary Deletes a limit
 */
export const deleteLimit = (limitId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v2/limits/${limitId}`, method: 'delete' }, options)
}

/**
 * @summary Get limit by id
 */
export const get = (limitId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseLimit>({ url: `/v2/limits/${limitId}`, method: 'get' }, options)
}

/**
 * @summary Updates a limit
 */
export const updateLimit = (
    limitId: string,
    limitRequest: LimitRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseLimit>(
        {
            url: `/v2/limits/${limitId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: limitRequest,
        },
        options
    )
}

/**
 * @summary Search limits by filters
 */
export const searchLimits = (
    searchLimitsRequest: SearchLimitsRequest,
    params: SearchLimitsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseLimit>(
        {
            url: `/v2/limits:search`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: searchLimitsRequest,
            params,
        },
        options
    )
}

/**
 * Returns a list of organizations matching the given search criteria. Currently it's only possible to search by name if name_prefix is not provided then all organizations will be returned.
 * @summary Searches for organizations.
 */
export const searchOrganizationsV = (
    params: SearchOrganizationsVParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseOrganizationViewResponseModel>(
        { url: `/v2/organizations`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates an organization
 */
export const createOrganizationV = (
    organizationCreateRequest: OrganizationCreateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseOrganizationViewResponseModel>(
        {
            url: `/v2/organizations`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: organizationCreateRequest,
        },
        options
    )
}

/**
 * Can only delete empty organizations
 * @summary Deletes an Organization by ID.
 */
export const deleteOrganizationV = (
    orgId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>({ url: `/v2/organizations/${orgId}`, method: 'delete' }, options)
}

/**
 * @summary Fetches an Organization by ID.
 */
export const getOrganizationByIdV = (
    orgId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseOrganizationViewResponseModel>(
        { url: `/v2/organizations/${orgId}`, method: 'get' },
        options
    )
}

/**
 * @summary Updates an Organization.
 */
export const updateOrganizationV = (
    orgId: string,
    organizationUpdateRequest: OrganizationUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseOrganizationViewResponseModel>(
        {
            url: `/v2/organizations/${orgId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: organizationUpdateRequest,
        },
        options
    )
}

/**
 * @summary Add a company to the given organization.
 */
export const addCompanyToOrganizationV = (
    orgId: string,
    addCompanyToOrganizationRequest: AddCompanyToOrganizationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseOrganizationViewResponseModel>(
        {
            url: `/v2/organizations/${orgId}/companies`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addCompanyToOrganizationRequest,
        },
        options
    )
}

/**
 * @summary Removes a company from the given organization.
 */
export const removeCompanyFromOrganizationV = (
    orgId: string,
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseOrganizationViewResponseModel>(
        { url: `/v2/organizations/${orgId}/companies/${companyId}`, method: 'delete' },
        options
    )
}

/**
 * If both organizationId and companyId are provided and if the company belong to the org,it searches for Teams with those orgId or companyId. If employeeIds are provided it searches for teams that have those employees, only other services can search for teams by employeeId.If none are provided, it searches for Teams where the user is a reviewer.
 * @summary Search for Teams
 */
export const searchTeamsV = (
    params: SearchTeamsVParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseTeamViewResponseModel>(
        { url: `/v2/teams`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates a new Team
 */
export const createTeamV = (
    teamCreateRequest: TeamCreateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseTeamViewResponseModel>(
        {
            url: `/v2/teams`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: teamCreateRequest,
        },
        options
    )
}

/**
 * @summary Deletes a Team.
 */
export const deleteTeamV = (teamId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v2/teams/${teamId}`, method: 'delete' }, options)
}

/**
 * @summary Fetches a Team by ID.
 */
export const getTeamByIdV = (
    teamId: string,
    params: GetTeamByIdVParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseTeamViewResponseModel>(
        { url: `/v2/teams/${teamId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Partially updates a Team. Set the code to empty string if you wish to delete it.
 */
export const partialUpdateTeamV = (
    teamId: string,
    teamUpdateRequest: TeamUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseTeamViewResponseModel>(
        {
            url: `/v2/teams/${teamId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: teamUpdateRequest,
        },
        options
    )
}

/**
 * @summary Adds members to a Team.
 */
export const addMembersToTeamV = (
    teamId: string,
    addEmployeesToTeamRequest: AddEmployeesToTeamRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListAddEmployeeToTeamResponse>(
        {
            url: `/v2/teams/${teamId}/members`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: addEmployeesToTeamRequest,
        },
        options
    )
}

/**
 * @summary Removes a member from a Team.
 */
export const removeMemberFromTeamV = (
    teamId: string,
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        { url: `/v2/teams/${teamId}/members/${employeeId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Converts a company team to an organization team.
 */
export const convertToOrganizationTeamV = (
    teamId: string,
    convertToOrganizationTeamRequest: ConvertToOrganizationTeamRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseTeamViewResponseModel>(
        {
            url: `/v2/teams/${teamId}:convert-to-organization-team`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: convertToOrganizationTeamRequest,
        },
        options
    )
}

/**
 * @summary View user adding requests by company id or organization id
 */
export const getUserAddingRequests = (
    params: GetUserAddingRequestsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseUserAddingRequestAdminViewResponseV2>(
        { url: `/v2/user-adding-requests`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Get cards by filter
 */
export const getCardsByFilters = (
    params: GetCardsByFiltersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseCard>(
        { url: `/v3/cards`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates a virtual card
 */
export const createVirtualCard = (
    params: CreateVirtualCardParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCard>({ url: `/v3/cards`, method: 'post', params }, options)
}

/**
 * @summary Get card by id
 */
export const getCard = (
    cardId: string,
    params: GetCardParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCard>(
        { url: `/v3/cards/${cardId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates card status
 */
export const updateStatus = (
    cardId: string,
    updateCardStatusRequest: UpdateCardStatusRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCard>(
        {
            url: `/v3/cards/${cardId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCardStatusRequest,
        },
        options
    )
}

/**
 * @summary Resets card pin
 */
export const resetPin = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseCard>(
        { url: `/v3/cards/${cardId}:reset-pin`, method: 'post' },
        options
    )
}

/**
 * @summary Pairs a card to an employee
 */
export const pairCard = (params: PairCardParams, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseCard>({ url: `/v3/cards:pair`, method: 'post', params }, options)
}

/**
 * @summary Get cards by filter
 */
export const searchCards = (
    searchCardsRequest: SearchCardsRequest,
    params: SearchCardsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseCard>(
        {
            url: `/v3/cards:search`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: searchCardsRequest,
            params,
        },
        options
    )
}

/**
 * @summary Retrieves a company resource
 */
export const getCompanyV = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseCompanyResponseModel>(
        { url: `/v5/companies/${companyId}`, method: 'get' },
        options
    )
}

/**
 * By default the endpoint will only return 20 items and won't return deactivated employees.
 * @summary Search for employees
 */
export const searchEmployeesV = (
    params: SearchEmployeesVParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseEmployeeResponseModel>(
        { url: `/v5/employees`, method: 'get', params },
        options
    )
}

/**
 * @deprecated
 * @summary Create a new employee
 */
export const createEmployeeV = (
    employeeCreateRequest: EmployeeCreateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseEmployeeResponseModel>(
        {
            url: `/v5/employees`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeCreateRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Deactivates an employee.
 */
export const softDeleteEmployeeV = (
    employeeId: string,
    employeeDeleteRequest: EmployeeDeleteRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v5/employees/${employeeId}`,
            method: 'delete',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeDeleteRequest,
        },
        options
    )
}

/**
 * @summary Retrieves an employee resource
 */
export const getEmployeeV = (employeeId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseEmployeeResponseModel>(
        { url: `/v5/employees/${employeeId}`, method: 'get' },
        options
    )
}

/**
 * Orchestrates the necessary actions related with changing certain fields. Changing read-only fields will result in a bad request error
 * @deprecated
 * @summary Update an employee
 */
export const updateEmployeeV = (
    employeeId: string,
    employeeUpdateRequest: EmployeeUpdateRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseEmployeeResponseModel>(
        {
            url: `/v5/employees/${employeeId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeUpdateRequest,
        },
        options
    )
}

/**
 * @deprecated
 * @summary Action: Resends the verification email to the employee
 */
export const resendVerificationEmailV = (
    employeeId: string,
    params: ResendVerificationEmailVParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        { url: `/v5/employees/${employeeId}:resend-verification-email`, method: 'post', params },
        options
    )
}

/**
 * Only restores the employee record, all other data the employee had when it was deactivated will remain deleted.
 * @deprecated
 * @summary Action: Restore an employee
 */
export const restoreEmployeeV = (
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseEmployeeResponseModel>(
        { url: `/v5/employees/${employeeId}:restore`, method: 'post' },
        options
    )
}

/**
 * @deprecated
 * @summary Action: Sends an onboarding sms to the employee
 */
export const sendOnboardingSmsEmployeeV = (
    employeeId: string,
    sendOnboardingSmsRequest: SendOnboardingSmsRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        {
            url: `/v5/employees/${employeeId}:send-onboarding-sms`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: sendOnboardingSmsRequest,
        },
        options
    )
}

export type CheckResult = NonNullable<Awaited<ReturnType<typeof check>>>
export type BackfillOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof backfillOrganization>>
>
export type BackfillProvisionedUsersResult = NonNullable<
    Awaited<ReturnType<typeof backfillProvisionedUsers>>
>
export type CreateCompaniesResult = NonNullable<Awaited<ReturnType<typeof createCompanies>>>
export type CreateCompanyResult = NonNullable<Awaited<ReturnType<typeof createCompany>>>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type CreateTeamResult = NonNullable<Awaited<ReturnType<typeof createTeam>>>
export type CreateSingleEmployeeResult = NonNullable<
    Awaited<ReturnType<typeof createSingleEmployee>>
>
export type GetEmployeeResult = NonNullable<Awaited<ReturnType<typeof getEmployee>>>
export type CreateEmployeesResult = NonNullable<Awaited<ReturnType<typeof createEmployees>>>
export type StopImportResult = NonNullable<Awaited<ReturnType<typeof stopImport>>>
export type StartImportResult = NonNullable<Awaited<ReturnType<typeof startImport>>>
export type CreateOrganizationResult = NonNullable<Awaited<ReturnType<typeof createOrganization>>>
export type DeleteOrganizationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganization>>>
export type AddCompanyToOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof addCompanyToOrganization>>
>
export type CreateCompanyForOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof createCompanyForOrganization>>
>
export type RemoveCompanyFromOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof removeCompanyFromOrganization>>
>
export type RenameOrganizationResult = NonNullable<Awaited<ReturnType<typeof renameOrganization>>>
export type TriggerPeopleExportAdminResult = NonNullable<
    Awaited<ReturnType<typeof triggerPeopleExportAdmin>>
>
export type DeleteProvisionedUserRecordsResult = NonNullable<
    Awaited<ReturnType<typeof deleteProvisionedUserRecords>>
>
export type FixProvisionedUserWithMissingCompanyIdResult = NonNullable<
    Awaited<ReturnType<typeof fixProvisionedUserWithMissingCompanyId>>
>
export type UpdateProvisionedUserResult = NonNullable<
    Awaited<ReturnType<typeof updateProvisionedUser>>
>
export type CheckCompanyUsersResult = NonNullable<Awaited<ReturnType<typeof checkCompanyUsers>>>
export type CheckEmployeesResult = NonNullable<Awaited<ReturnType<typeof checkEmployees>>>
export type CheckTeamEmployeesResult = NonNullable<Awaited<ReturnType<typeof checkTeamEmployees>>>
export type SyncTeamMemberResult = NonNullable<Awaited<ReturnType<typeof syncTeamMember>>>
export type PublishTeamSnapshotsResult = NonNullable<
    Awaited<ReturnType<typeof publishTeamSnapshots>>
>
export type RenameArchivedTeamsResult = NonNullable<Awaited<ReturnType<typeof renameArchivedTeams>>>
export type RemoveTeamAsAdminResult = NonNullable<Awaited<ReturnType<typeof removeTeamAsAdmin>>>
export type RemoveEmployeeFromTeamResult = NonNullable<
    Awaited<ReturnType<typeof removeEmployeeFromTeam>>
>
export type AddMembersToTeamResult = NonNullable<Awaited<ReturnType<typeof addMembersToTeam>>>
export type CreateUserResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
export type GetUserResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type SyncUserResult = NonNullable<Awaited<ReturnType<typeof syncUser>>>
export type CreateUsersResult = NonNullable<Awaited<ReturnType<typeof createUsers>>>
export type GetCompaniesResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>
export type GetCompany1Result = NonNullable<Awaited<ReturnType<typeof getCompany1>>>
export type ExportAccountCategoriesResult = NonNullable<
    Awaited<ReturnType<typeof exportAccountCategories>>
>
export type ImportAccountCategoriesResult = NonNullable<
    Awaited<ReturnType<typeof importAccountCategories>>
>
export type GetEntitiesResult = NonNullable<Awaited<ReturnType<typeof getEntities>>>
export type CreateCompanyInExistingOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof createCompanyInExistingOrganization>>
>
export type CreateOrganizationAndCompanyResult = NonNullable<
    Awaited<ReturnType<typeof createOrganizationAndCompany>>
>
export type RequestExpansionVResult = NonNullable<Awaited<ReturnType<typeof requestExpansionV>>>
export type CreateOrganization1Result = NonNullable<Awaited<ReturnType<typeof createOrganization1>>>
export type GetOrganizationGroupingRequestsResult = NonNullable<
    Awaited<ReturnType<typeof getOrganizationGroupingRequests>>
>
export type CreateOrganizationGroupingRequestResult = NonNullable<
    Awaited<ReturnType<typeof createOrganizationGroupingRequest>>
>
export type GetOrganizationGroupingRequestActionsResult = NonNullable<
    Awaited<ReturnType<typeof getOrganizationGroupingRequestActions>>
>
export type GetActionsCountResult = NonNullable<Awaited<ReturnType<typeof getActionsCount>>>
export type UpdateOrganizationGroupingRequestActionResult = NonNullable<
    Awaited<ReturnType<typeof updateOrganizationGroupingRequestAction>>
>
export type GetOrganizationGroupingRequestsForOrganizationResult = NonNullable<
    Awaited<ReturnType<typeof getOrganizationGroupingRequestsForOrganization>>
>
export type GetOrganizationResult = NonNullable<Awaited<ReturnType<typeof getOrganization>>>
export type RemoveCompanyFromOrganization1Result = NonNullable<
    Awaited<ReturnType<typeof removeCompanyFromOrganization1>>
>
export type AddCompanyToOrganization1Result = NonNullable<
    Awaited<ReturnType<typeof addCompanyToOrganization1>>
>
export type GetOrganizationsResult = NonNullable<Awaited<ReturnType<typeof getOrganizations>>>
export type SearchOrganizationsResult = NonNullable<Awaited<ReturnType<typeof searchOrganizations>>>
export type RequestSelfExpansionResult = NonNullable<
    Awaited<ReturnType<typeof requestSelfExpansion>>
>
export type TriggerPeopleExportResult = NonNullable<Awaited<ReturnType<typeof triggerPeopleExport>>>
export type SearchProvisionedUsersResult = NonNullable<
    Awaited<ReturnType<typeof searchProvisionedUsers>>
>
export type GetProvisionedUserEventLogsResult = NonNullable<
    Awaited<ReturnType<typeof getProvisionedUserEventLogs>>
>
export type DeleteProvisionedUserResult = NonNullable<
    Awaited<ReturnType<typeof deleteProvisionedUser>>
>
export type GetProvisionedUserResult = NonNullable<Awaited<ReturnType<typeof getProvisionedUser>>>
export type ActivateProvisionedUserResult = NonNullable<
    Awaited<ReturnType<typeof activateProvisionedUser>>
>
export type GetSignUpUserCompaniesResult = NonNullable<
    Awaited<ReturnType<typeof getSignUpUserCompanies>>
>
export type GetSignUpUserUserAddingRequestResult = NonNullable<
    Awaited<ReturnType<typeof getSignUpUserUserAddingRequest>>
>
export type CreateSignUpUserUserAddingRequestResult = NonNullable<
    Awaited<ReturnType<typeof createSignUpUserUserAddingRequest>>
>
export type GetStoragePropertyResult = NonNullable<Awaited<ReturnType<typeof getStorageProperty>>>
export type CreateTeam1Result = NonNullable<Awaited<ReturnType<typeof createTeam1>>>
export type DeleteTeamResult = NonNullable<Awaited<ReturnType<typeof deleteTeam>>>
export type GetTeamByIdResult = NonNullable<Awaited<ReturnType<typeof getTeamById>>>
export type DeleteTeamCodeResult = NonNullable<Awaited<ReturnType<typeof deleteTeamCode>>>
export type UpdateTeamCodeResult = NonNullable<Awaited<ReturnType<typeof updateTeamCode>>>
export type RemoveEmployeeFromTeam1Result = NonNullable<
    Awaited<ReturnType<typeof removeEmployeeFromTeam1>>
>
export type AddEmployeesToTeamResult = NonNullable<Awaited<ReturnType<typeof addEmployeesToTeam>>>
export type UpdateTeamNameResult = NonNullable<Awaited<ReturnType<typeof updateTeamName>>>
export type SetOrganizationResult = NonNullable<Awaited<ReturnType<typeof setOrganization>>>
export type GetTeamsForResult = NonNullable<Awaited<ReturnType<typeof getTeamsFor>>>
export type CreateUserAddingRequestsResult = NonNullable<
    Awaited<ReturnType<typeof createUserAddingRequests>>
>
export type GetUserAddingRequestsCountResult = NonNullable<
    Awaited<ReturnType<typeof getUserAddingRequestsCount>>
>
export type ApproveOrDeclineUserAddingRequestsResult = NonNullable<
    Awaited<ReturnType<typeof approveOrDeclineUserAddingRequests>>
>
export type DeleteLimitsByFilterResult = NonNullable<
    Awaited<ReturnType<typeof deleteLimitsByFilter>>
>
export type GetLimitsByFiltersResult = NonNullable<Awaited<ReturnType<typeof getLimitsByFilters>>>
export type CreateLimitResult = NonNullable<Awaited<ReturnType<typeof createLimit>>>
export type GetEmployeeSpendResult = NonNullable<Awaited<ReturnType<typeof getEmployeeSpend>>>
export type DeleteLimitResult = NonNullable<Awaited<ReturnType<typeof deleteLimit>>>
export type GetResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type UpdateLimitResult = NonNullable<Awaited<ReturnType<typeof updateLimit>>>
export type SearchLimitsResult = NonNullable<Awaited<ReturnType<typeof searchLimits>>>
export type SearchOrganizationsVResult = NonNullable<
    Awaited<ReturnType<typeof searchOrganizationsV>>
>
export type CreateOrganizationVResult = NonNullable<Awaited<ReturnType<typeof createOrganizationV>>>
export type DeleteOrganizationVResult = NonNullable<Awaited<ReturnType<typeof deleteOrganizationV>>>
export type GetOrganizationByIdVResult = NonNullable<
    Awaited<ReturnType<typeof getOrganizationByIdV>>
>
export type UpdateOrganizationVResult = NonNullable<Awaited<ReturnType<typeof updateOrganizationV>>>
export type AddCompanyToOrganizationVResult = NonNullable<
    Awaited<ReturnType<typeof addCompanyToOrganizationV>>
>
export type RemoveCompanyFromOrganizationVResult = NonNullable<
    Awaited<ReturnType<typeof removeCompanyFromOrganizationV>>
>
export type SearchTeamsVResult = NonNullable<Awaited<ReturnType<typeof searchTeamsV>>>
export type CreateTeamVResult = NonNullable<Awaited<ReturnType<typeof createTeamV>>>
export type DeleteTeamVResult = NonNullable<Awaited<ReturnType<typeof deleteTeamV>>>
export type GetTeamByIdVResult = NonNullable<Awaited<ReturnType<typeof getTeamByIdV>>>
export type PartialUpdateTeamVResult = NonNullable<Awaited<ReturnType<typeof partialUpdateTeamV>>>
export type AddMembersToTeamVResult = NonNullable<Awaited<ReturnType<typeof addMembersToTeamV>>>
export type RemoveMemberFromTeamVResult = NonNullable<
    Awaited<ReturnType<typeof removeMemberFromTeamV>>
>
export type ConvertToOrganizationTeamVResult = NonNullable<
    Awaited<ReturnType<typeof convertToOrganizationTeamV>>
>
export type GetUserAddingRequestsResult = NonNullable<
    Awaited<ReturnType<typeof getUserAddingRequests>>
>
export type GetCardsByFiltersResult = NonNullable<Awaited<ReturnType<typeof getCardsByFilters>>>
export type CreateVirtualCardResult = NonNullable<Awaited<ReturnType<typeof createVirtualCard>>>
export type GetCardResult = NonNullable<Awaited<ReturnType<typeof getCard>>>
export type UpdateStatusResult = NonNullable<Awaited<ReturnType<typeof updateStatus>>>
export type ResetPinResult = NonNullable<Awaited<ReturnType<typeof resetPin>>>
export type PairCardResult = NonNullable<Awaited<ReturnType<typeof pairCard>>>
export type SearchCardsResult = NonNullable<Awaited<ReturnType<typeof searchCards>>>
export type GetCompanyVResult = NonNullable<Awaited<ReturnType<typeof getCompanyV>>>
export type SearchEmployeesVResult = NonNullable<Awaited<ReturnType<typeof searchEmployeesV>>>
export type CreateEmployeeVResult = NonNullable<Awaited<ReturnType<typeof createEmployeeV>>>
export type SoftDeleteEmployeeVResult = NonNullable<Awaited<ReturnType<typeof softDeleteEmployeeV>>>
export type GetEmployeeVResult = NonNullable<Awaited<ReturnType<typeof getEmployeeV>>>
export type UpdateEmployeeVResult = NonNullable<Awaited<ReturnType<typeof updateEmployeeV>>>
export type ResendVerificationEmailVResult = NonNullable<
    Awaited<ReturnType<typeof resendVerificationEmailV>>
>
export type RestoreEmployeeVResult = NonNullable<Awaited<ReturnType<typeof restoreEmployeeV>>>
export type SendOnboardingSmsEmployeeVResult = NonNullable<
    Awaited<ReturnType<typeof sendOnboardingSmsEmployeeV>>
>
