import type { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Descriptions, Dropdown, Menu, Space, Tag, Typography } from 'antd'
import { getCompanyRegistryLink, getZipFile } from '../shared/utils'
import Spinner from 'components/content-spinner'
import { BlockOutlined, DownOutlined } from '@ant-design/icons'
import { getEmojiFlag } from 'countries-list'
import CompanyAddress from 'components/company-address/company-address'
import { useClosedCompaniesByRegistration } from 'services/deimos/companies'
import type { Address } from 'types/deimos-company'
import type { ClosedCompany as ClosedCompanyType } from '@pleo-io/deimos'
import dayjs from 'packages/dayjs'
import { DetailsContainer, RegNumberRowContent, CopyIcon } from '../shared/styled'
import ClosedCompanyTabs from './closed-company-tabs'
import { PageContentLayout } from 'components/layout-containers'
import { showCategory, showReasons, showNotes } from 'components/company-offboarding/utils'
import { OnboardedViaTag } from 'pages/compliance/shared/company-onboarded-via-tag'
import type { CompanyOffboardingStatusResponse } from 'bff/moons/generated/thanatos'
import type { CompanyRequestVOnboardedVia, CompanyResponseV2 } from 'bff/moons/generated/styx-v1'
import ClosedCompanyErrorResult from './closed-company-error-result'
import { bff } from 'pages/compliance/bff'

const { Link, Text } = Typography

interface ClosedCompanyProps {
    company: CompanyResponseV2
    isManualSignup: boolean
    kycStatus: string
    offboardingStatus?: CompanyOffboardingStatusResponse
    goBack: () => void
    onClosedArchiveClick: (closedCompany: ClosedCompanyType) => void
    adverseMediaStatus?: string
    sanctionsStatus?: string
}

export const ClosedCompany: FC<React.PropsWithChildren<ClosedCompanyProps>> = ({
    company,
    isManualSignup,
    kycStatus,
    goBack,
    onClosedArchiveClick,
    children,
    offboardingStatus,
    adverseMediaStatus,
    sanctionsStatus,
}) => {
    const emojiFlag = getEmojiFlag(company.address?.country || 'GB')
    const companyRegistryLink = getCompanyRegistryLink(
        company.registryId ?? '',
        company.registrationNumber ?? '',
        company.legalName ?? ''
    )

    const { data: closedCompanyArchive } = useClosedCompaniesByRegistration(
        company.registrationNumber ?? ''
    )
    const showClosedArchive = closedCompanyArchive?.companies.length > 1 ? true : false

    const closedCompanyArchiveMenu = (
        <Menu>
            {closedCompanyArchive?.companies.map((closedCompany: ClosedCompanyType) => {
                return (
                    <Menu.Item
                        key={closedCompany.id}
                        onClick={() => onClosedArchiveClick(closedCompany)}
                    >
                        {dayjs(closedCompany.deletedAt).format('lll')}
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={goBack}
                title={
                    <Space>
                        {emojiFlag}
                        <Text
                            copyable={{ icon: [<CopyIcon key="copy-icon" />] }}
                            style={{ whiteSpace: 'normal' }}
                        >
                            {company?.legalName}
                        </Text>
                    </Space>
                }
                tags={
                    <>
                        <OnboardedViaTag
                            onboardedVia={company.onboardedVia as CompanyRequestVOnboardedVia}
                        />
                        {isManualSignup && <Tag color="warning">Manual signup</Tag>}
                        {closedCompanyArchive?.companies.length > 1 && (
                            <Tag color="warning">Previously offboarded</Tag>
                        )}
                        <Tag color="error" data-testid="closed-tag">
                            Closed
                        </Tag>
                    </>
                }
                extra={[
                    <Button onClick={() => getZipFile(company)} key="export-expenses-button">
                        Export expenses
                    </Button>,
                    showClosedArchive && (
                        <Dropdown
                            key="closed-archive-dropdown"
                            overlay={closedCompanyArchiveMenu}
                            trigger={['click']}
                        >
                            <Button key="closed-archive-button">
                                Closed archive <DownOutlined />
                            </Button>
                        </Dropdown>
                    ),
                ]}
            >
                <Descriptions size="small">
                    <Descriptions.Item>
                        <Link href={companyRegistryLink} target="_blank" rel="noopener noreferrer">
                            Go to company registry <BlockOutlined />
                        </Link>
                    </Descriptions.Item>
                </Descriptions>
                <DetailsContainer>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Reg. no.">
                            <RegNumberRowContent>{company.registryId}</RegNumberRowContent>
                        </Descriptions.Item>
                        <Descriptions.Item label="Domain">
                            {company.domainName ? (
                                <a
                                    href={`https://${company.domainName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {company.domainName}
                                </a>
                            ) : (
                                <Text>No domain found</Text>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Styx ID">
                            <Text copyable>{company.styxId}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Deimos ID">
                            <Text copyable>{company.id}</Text>
                        </Descriptions.Item>
                    </Descriptions>
                    {offboardingStatus && (
                        <Descriptions column={1} size="small">
                            <Descriptions.Item label="Offboarding category">
                                {showCategory(offboardingStatus?.category)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Offboarding reasons">
                                <Space direction="vertical" size="small">
                                    {showReasons(offboardingStatus?.reasons)}
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label="Offboarding initiated by">
                                <Text copyable>{offboardingStatus?.initiatedBy}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Offboarding notes">
                                {showNotes(offboardingStatus?.notes)}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                    <Descriptions>
                        <Descriptions.Item label="Address">
                            <CompanyAddress address={company.address as Address} />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="KYC status">{kycStatus}</Descriptions.Item>
                        <Descriptions.Item label="Adverse media">
                            {adverseMediaStatus}
                        </Descriptions.Item>
                        <Descriptions.Item label="Sanctions">{sanctionsStatus}</Descriptions.Item>
                    </Descriptions>
                </DetailsContainer>
            </PageHeader>
            <PageContentLayout>{children}</PageContentLayout>
        </>
    )
}

const ClosedCompanyContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const navigate = useNavigate()
    const { id: deimosCompanyId } = useParams()
    const { data, error } = bff.closedCompanyHeader.getClosedCompany.useQuery({
        id: deimosCompanyId,
    })

    const goBack = () => {
        navigate(-1)
    }

    const onClosedArchiveClick = (closedCompany: ClosedCompanyType) => {
        navigate(`../${closedCompany.id}`)
    }

    if (error) {
        return (
            <ClosedCompanyErrorResult
                errorCode={error.data?.code}
                errorMessage={error.message}
                goBack={goBack}
            />
        )
    }

    if (!data?.company) {
        return <Spinner />
    }

    return (
        <ClosedCompany
            isManualSignup={data.company?.isManualSignup ?? false}
            company={data.company}
            goBack={goBack}
            onClosedArchiveClick={onClosedArchiveClick}
            kycStatus={data.latestKycStatus}
            offboardingStatus={data.offboardingStatus}
            adverseMediaStatus={data.adverseMediaStatus}
            sanctionsStatus={data.sanctionsStatus}
        >
            <ClosedCompanyTabs
                companyId={data.company.id}
                companyCountry={data.company?.address?.country ?? ''}
                onboardingSource={data.company.onboardedVia}
            />
        </ClosedCompany>
    )
}

export default ClosedCompanyContainer
