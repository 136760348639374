import { Button, Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import type { FC } from 'react'

interface NoteEditorProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    onSubmit: () => void
    submitting: boolean
    value: string
}

const ComplianceNoteEditor: FC<React.PropsWithChildren<NoteEditorProps>> = ({
    onChange,
    onSubmit,
    submitting,
    value,
}: NoteEditorProps) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Note
            </Button>
        </Form.Item>
    </>
)

export default ComplianceNoteEditor
