import KycCaseContainer from 'pages/compliance/company/proof-of-business/components/kyc-case-container'
import { PageContentLayout } from 'components/layout-containers'
import { Card } from 'antd'
import { CreateKycCaseButton } from 'pages/compliance/company/proof-of-business/components/create-kyc-case-button'
import { bff } from 'pages/compliance/bff'

export const ProofOfBusiness = ({ companyId }: { companyId: string }) => {
    const { mutateAsync: createKycCase } = bff.proofOfBusiness.createKycCase.useMutation()

    return (
        <PageContentLayout>
            <Card
                title="Company Proof of Business"
                extra={<CreateKycCaseButton companyId={companyId} onClick={createKycCase} />}
                style={{ marginBottom: '1rem' }}
            >
                <KycCaseContainer companyId={companyId} />
            </Card>
        </PageContentLayout>
    )
}
