import styled from 'styled-components'
import { Table, TableColumnProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import type { CaseOutput } from 'bff/moons/generated/case-manager'
import {
    companyColumn,
    previousRiskRatingColumn,
    preliminaryRiskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStatusColumn,
    oddStartedColumn,
    dueDateColumn,
    riskRatingColumn,
    oddWaitingForCustomerColumn,
} from './columns'

export const OddTable = ({
    data,
    isFetching,
    tab,
}: {
    data?: CaseOutput[]
    isFetching?: boolean
    tab: string
}) => {
    const navigate = useNavigate()
    const onRowClick = (id: string) => navigate(id)

    return (
        <TableStyleProvider>
            <Table
                size="middle"
                dataSource={data}
                columns={columnGroups[tab]}
                onRow={({ id }) => ({ onClick: () => onRowClick(id) })}
                rowKey="id"
                pagination={false}
                loading={isFetching}
            />
        </TableStyleProvider>
    )
}

const oddListTableColumnsDefault: TableColumnProps<CaseOutput>[] = [
    companyColumn,
    previousRiskRatingColumn,
    preliminaryRiskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStatusColumn,
    oddStartedColumn,
    dueDateColumn,
]

const oddListTableColumnsClosed: TableColumnProps<CaseOutput>[] = [
    companyColumn,
    riskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStartedColumn,
    dueDateColumn,
]

const columnGroups: { [key: string]: TableColumnProps<CaseOutput>[] } = {
    new: oddListTableColumnsDefault,
    inProgress: oddListTableColumnsDefault,
    offboarding: oddListTableColumnsDefault,
    waitingForCustomer: [
        companyColumn,
        previousRiskRatingColumn,
        preliminaryRiskRatingColumn,
        previousKycColumn,
        oddTriggerColumn,
        oddStatusColumn,
        oddWaitingForCustomerColumn,
        oddStartedColumn,
        dueDateColumn,
    ],
    oddCompleted: oddListTableColumnsClosed,
}

export const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`
