import {
    Alert,
    Button,
    Collapse,
    Descriptions,
    Divider,
    Modal,
    Space,
    Tag,
    notification,
} from 'antd'
import type { HigherLimitApplicationFullDto } from 'bff/moons/generated/kale'
import Spinner from 'components/content-spinner'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useManagement } from 'services/styx/management'
import { formatMoneyIntl } from 'utils/money'
import OverdraftSection from '../overdraft-section'
import { selectEmployees } from 'store/modules/employees/selectors'
import { useState, type MouseEventHandler } from 'react'
import HigherLimitApprovalModal from './higher-limit-approval-modal'
import { getMaxLimitByCurrency, getOverdraftApplicationStatusColor } from '../../heplers'
import { creditTabBff } from '../../bff'
import HigherLimitApplicationStateTimeline from './higher-limit-application-state-timeline'
import HigherLimitInformationRequestModal from './higher-limit-infromation-request-modal'
import HigherLimitDocumentList from './higher-limit-document-list'
import HigherLimitDirectorsInfo from './higher-limit-directors-info'
import EmployeeLink from '../employee-link'
const { confirm } = Modal

export default function HigherLimitApplicationSection({ companyId }: { companyId: string }) {
    const { isInitialLoading, data, isError } = creditTabBff.getHigherLimit.useQuery({
        companyId,
    })

    const { mutateAsync: approveApplication } =
        creditTabBff.approveHigherLimitApplication.useMutation()
    const { mutateAsync: rejectApplication } =
        creditTabBff.rejectHigherLimitApplication.useMutation()
    const { mutate: sendInformationRequest, isLoading: isSendingInfoRequest } =
        creditTabBff.sendInformationRequestForHigherLimit.useMutation({
            onSuccess: () => {
                setApplicationForInformation(null)
                notification.success({
                    message: 'Information request sent!',
                })
            },
            onError: () =>
                notification.error({
                    message: 'Information request failed!',
                }),
        })

    const employees = useSelector(selectEmployees)
    const management = useManagement()
    const isManagementLoading = !management.data && !management.error
    const [applicationToApprove, setApplicationToApprove] =
        useState<HigherLimitApplicationFullDto | null>(null)
    const [applicationForInformation, setApplicationForInformation] =
        useState<HigherLimitApplicationFullDto | null>(null)

    if (isInitialLoading || isManagementLoading) {
        return (
            <OverdraftSection title="Higher Limit applications">
                <Spinner />
            </OverdraftSection>
        )
    }

    if (isError) {
        return (
            <OverdraftSection title="Higher Limit applications">
                <Alert
                    message="There was an error fetching Higher Limit application."
                    type="error"
                />
            </OverdraftSection>
        )
    }

    const handleApprovalSubmit = async (values: { approvedLimit: number }) => {
        if (applicationToApprove === null) return null
        try {
            await approveApplication({
                companyId,
                applicationId: applicationToApprove.id,
                approvedLimit: values.approvedLimit,
            })
            notification.success({
                message: 'Application approved!',
            })
        } catch {
            notification.error({
                message: 'Application approval failed!',
            })
        } finally {
            setApplicationToApprove(null)
        }
    }

    const handleRejectApplication = async (applicationId: string) => {
        try {
            await rejectApplication({ companyId, applicationId })
            notification.success({
                message: 'Application rejected!',
            })
        } catch {
            notification.error({
                message: 'Application rejection failed!',
            })
        }
    }

    const handleInformationRequestSubmit = async (values: { comment: string }) => {
        if (applicationForInformation === null) return null
        confirm({
            centered: true,
            title: 'Are you sure you want to send information request?',
            onOk() {
                sendInformationRequest({
                    companyId,
                    applicationId: applicationForInformation.id,
                    comment: values.comment,
                })
            },
        })
    }

    const showRejectionWarning = (applicationId: string) => {
        confirm({
            title: 'Warning! Higher limit has already been approved!',
            content: (
                <div>
                    <p>
                        After rejection, you need to manually adjust the customer's eligibility
                        limit to avoid them using the higher limit.
                    </p>
                    <p>
                        Approval email was already sent, so you should consider reaching out to the
                        customer to avoid confusion.
                    </p>
                </div>
            ),
            onOk() {
                handleRejectApplication(applicationId)
            },
        })
    }

    const handleStateChange: (
        action: 'approval' | 'rejection' | 'informationRequest',
        application: HigherLimitApplicationFullDto
    ) => MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement> =
        (action, application) => async (event) => {
            event.stopPropagation()
            switch (action) {
                case 'approval':
                    setApplicationToApprove(application)
                    break
                case 'rejection':
                    confirm({
                        centered: true,
                        title: 'Are you sure you want to reject this application?',
                        onOk() {
                            if (application.currentState === 'APPROVED') {
                                showRejectionWarning(application.id)
                            } else {
                                handleRejectApplication(application.id)
                            }
                        },
                    })
                    break
                case 'informationRequest':
                    setApplicationForInformation(application)
                    break
            }
        }

    const applicationItems = data?.higherLimitApplication.data
        ?.map((application) => {
            const requestingEmployee = application.stateTrail[0].isCustomer
                ? employees.find(({ userId }) => userId === application.stateTrail[0].userId)
                : null

            const financialStatementDocuments = application.documents.filter(
                ({ type }) => type === 'FINANCIAL_STATEMENT'
            )

            const bankStatementDocuments = application.documents.filter(
                ({ type }) => type === 'BANK_STATEMENT'
            )

            return {
                key: application.id,
                label: (
                    <Space direction="horizontal" size="middle">
                        <span>
                            Application created on{' '}
                            {dayjs(application.createdAt).format('YYYY-MM-DD')}
                        </span>
                        <Tag color={getOverdraftApplicationStatusColor(application.currentState)}>
                            {application.currentState}
                        </Tag>
                    </Space>
                ),
                children: (
                    <>
                        <Descriptions column={3}>
                            <Descriptions.Item label="Status">
                                <Tag
                                    color={getOverdraftApplicationStatusColor(
                                        application.currentState
                                    )}
                                >
                                    {application.currentState}
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Requested limit">
                                {formatMoneyIntl(application.requestedLimit, {
                                    isMinorUnits: true,
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved limit">
                                {application.approvedLimit
                                    ? formatMoneyIntl(application.approvedLimit, {
                                          isMinorUnits: true,
                                      })
                                    : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Requesting user">
                                {requestingEmployee ? (
                                    <EmployeeLink employee={requestingEmployee} />
                                ) : (
                                    '-'
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Is director">
                                <HigherLimitDirectorsInfo
                                    management={management.data}
                                    requestingEmployee={requestingEmployee}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Space direction="vertical" style={{ gap: '48px' }}>
                            <Divider orientation="left" orientationMargin="0" plain>
                                Documents
                            </Divider>
                            {bankStatementDocuments.length > 0 && (
                                <HigherLimitDocumentList
                                    title="Bank statements"
                                    documents={bankStatementDocuments}
                                    companyId={companyId}
                                />
                            )}

                            {financialStatementDocuments.length > 0 && (
                                <HigherLimitDocumentList
                                    title="Financial statements"
                                    companyId={companyId}
                                    documents={financialStatementDocuments}
                                />
                            )}
                            <Space direction="vertical" size="large">
                                <Divider orientation="left" orientationMargin="0" plain>
                                    State trail
                                </Divider>
                                <HigherLimitApplicationStateTimeline application={application} />
                            </Space>
                        </Space>
                    </>
                ),
                extra: (
                    <Space>
                        <Button
                            disabled={application.currentState === 'APPROVED'}
                            type="primary"
                            onClick={handleStateChange('approval', application)}
                        >
                            Approve
                        </Button>
                        <Button
                            disabled={application.currentState === 'REJECTED'}
                            danger
                            type="primary"
                            onClick={handleStateChange('rejection', application)}
                        >
                            Reject
                        </Button>
                        <Button
                            disabled={application.currentState !== 'PENDING'}
                            onClick={handleStateChange('informationRequest', application)}
                        >
                            Request information
                        </Button>
                    </Space>
                ),
            }
        })
        .reverse()

    return (
        <>
            <OverdraftSection title="Higher Limit application">
                {applicationItems?.length ? (
                    <Collapse defaultActiveKey={applicationItems[0].key}>
                        {applicationItems.map((item) => (
                            <Collapse.Panel key={item.key} header={item.label} extra={item.extra}>
                                {item.children}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                ) : (
                    <Alert
                        message="There's no higher limit application for this company"
                        type="info"
                    />
                )}
            </OverdraftSection>
            {data?.overdraftEligibility?.terms && applicationToApprove && (
                <HigherLimitApprovalModal
                    application={applicationToApprove}
                    minLimit={data.overdraftEligibility.terms.limit}
                    maxLimit={getMaxLimitByCurrency(applicationToApprove.requestedLimit.currency)}
                    onCancel={() => setApplicationToApprove(null)}
                    onSubmit={handleApprovalSubmit}
                />
            )}
            <HigherLimitInformationRequestModal
                application={applicationForInformation}
                onCancel={() => setApplicationForInformation(null)}
                onSubmit={handleInformationRequestSubmit}
                isLoading={isSendingInfoRequest}
            />
        </>
    )
}
