import { useState } from 'react'
import { Card, Table, Input, Button, Space, Form } from 'antd'
import dayjs from 'dayjs'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import { Employee, ExportHistoryModal, StatusTag } from './export-history-modal'
import { fetcher } from 'services/deimos/request'
import { useGetCompany } from 'services/deimos/companies'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { useExportHistoryExportAPI } from './export-history-export-api-request'
import type { JobStatus } from '@pleo-io/deimos'

export type ExportHistoryType = any

type ExportHistoryError = {
    error: boolean
    status: number
    message: string
    invalid: {
        companyId: string
    }
}

type ExportHistoryResponse = ExportHistoryType[] | ExportHistoryError

export const ExportHistory = ({ shouldUseDeimos }: { shouldUseDeimos: boolean }) => {
    const [selectedExport, setSelectedExport] = useState<ExportHistoryType>()
    const [fetchLimit, setFetchLimit] = useState<number | undefined>()
    const { exportHistory, error } = useExportHistoryDeimos()
    const exportHistoryExportAPI = useExportHistoryExportAPI({ limit: fetchLimit })

    const exportHistoryResults = shouldUseDeimos
        ? exportHistory
        : exportHistoryExportAPI.exportHistory

    return (
        <Card title="Export history" bodyStyle={{ padding: 0 }}>
            <LookupExportHistory error={error} />
            <ExportHistoryTable
                onExportSelect={setSelectedExport}
                exportHistory={exportHistoryResults}
                paginationInfo={exportHistoryExportAPI}
                fetchLimit={fetchLimit}
                setFetchLimit={setFetchLimit}
                shouldUseDeimos={shouldUseDeimos}
            />
            <ExportHistoryModal
                exportHistory={selectedExport}
                shouldUseDeimos={shouldUseDeimos}
                onCancel={() => setSelectedExport(undefined)}
            />
        </Card>
    )
}

const ExportHistoryTable = ({
    onExportSelect,
    exportHistory,
    paginationInfo,
    fetchLimit,
    setFetchLimit,
    shouldUseDeimos,
}: {
    exportHistory?: ExportHistoryType
    onExportSelect: (exportHistory: ExportHistoryType) => void
    paginationInfo?: any
    fetchLimit?: number
    setFetchLimit: (limit: number) => void
    shouldUseDeimos: boolean
}) => {
    const { companyId } = useParams()
    const { data: company } = useGetCompany(companyId)

    return (
        <TableStyleProvider>
            <Table
                columns={columns}
                dataSource={exportHistory}
                rowKey={'id'}
                onRow={(record) => ({
                    onClick: () => onExportSelect(record),
                })}
                locale={{
                    emptyText: company ? `No exports found for ${company?.name}` : null,
                }}
                pagination={false}
            />
            {!shouldUseDeimos &&
                paginationInfo &&
                paginationInfo.exportHistory &&
                paginationInfo.exportHistory.length > 0 && (
                    <CursorPaginationControls
                        pagination={{
                            hasPreviousPage: !!paginationInfo.pagination?.hasPreviousPage,
                            hasNextPage: !!paginationInfo.pagination?.hasNextPage,
                            currentRequestPagination: {
                                ...paginationInfo.pagination?.currentRequestPagination,
                            },
                            startCursor: paginationInfo.pagination?.startCursor ?? null,
                            endCursor: paginationInfo.pagination?.endCursor ?? null,
                        }}
                        limit={
                            fetchLimit ?? paginationInfo.pagination?.currentRequestPagination?.limit
                        }
                        onChangeLimit={(limit) => setFetchLimit(limit)}
                        isFetching={paginationInfo.isLoadingMore}
                        onNextPage={paginationInfo.fetchNextPage}
                        onPrevPage={paginationInfo.fetchPreviousPage}
                        onFirstPage={paginationInfo.fetchFirstPage}
                    />
                )}
        </TableStyleProvider>
    )
}

const LookupExportHistory = ({ error }: { error?: any }) => {
    const { companyId } = useParams()
    const navigate = useNavigate()
    const [newCompanyId, setNewCompanyId] = useState(companyId)

    return (
        <Form
            layout="vertical"
            style={{ padding: '1rem' }}
            onFinish={(values) => setNewCompanyId(values.query)}
        >
            <Form.Item
                name="query"
                label="Company ID"
                validateStatus={error ? 'error' : 'success'}
                help={
                    error?.message === 'bad_request' &&
                    `Could not find a company with the ID: ${companyId}`
                }
            >
                <Space.Compact direction="horizontal" style={{ width: '100%' }}>
                    <Input value={newCompanyId} onChange={(e) => setNewCompanyId(e.target.value)} />
                    <Button
                        type="primary"
                        onClick={() => navigate(`/customer-success/exports/${newCompanyId}`)}
                    >
                        Lookup export history
                    </Button>
                </Space.Compact>
            </Form.Item>
        </Form>
    )
}

const columns = [
    {
        title: 'Export History ID',
        dataIndex: 'id',
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt: string) => dayjs(createdAt).format('lll'),
    },
    {
        title: 'Created by',
        dataIndex: 'employeeId',
        render: (employeeId: string) => <Employee employeeId={employeeId} />,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (status: JobStatus) => <StatusTag status={status} />,
    },
]

const useExportHistoryDeimos = () => {
    const { companyId } = useParams()

    const response = useSWR<ExportHistoryResponse>(
        companyId ? `rest/v1/exports/history/${companyId}` : null,
        fetcher,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

    return { exportHistory: response?.data, ...response }
}

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`
