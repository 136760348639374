import { Progress, Space, TableColumnProps, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { getEmojiFlag } from 'countries-list'
import { ClockCircleOutlined, FlagOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { RiskRatingTag } from 'components/risk-rating-tag/risk-rating-tag'
import { formatDate } from 'packages/dates/dates'
import { capitalizeFirstLetter } from 'utils/strings'
import { StatusTag } from './status-tag'
import { green, orange, red } from '@ant-design/colors'
import { ToggleAssigneeButton } from './toggle-assignee'
import type { CaseOutput } from 'bff/moons/generated/case-manager'

const { Text } = Typography

export const oddStartedColumn: TableColumnProps<CaseOutput> = {
    title: 'ODD Started',
    dataIndex: 'oddStartDate',
    key: 'oddStartDate',
    render: (_, oddCase) => <OddStartedCell {...oddCase} />,
}

const OddStartedCell = ({ assignedTo, id, state }: CaseOutput) => {
    const isLocked = state === 'ODD_COMPLETED' || state === 'OFFBOARDING_COMPLETED'
    const caseHandlerInitials = assignedTo
        ? `${assignedTo.firstName} ${assignedTo.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    return (
        <Space>
            <ToggleAssigneeButton
                id={id}
                hasAssignee={Boolean(assignedTo)}
                caseHandlerInitials={caseHandlerInitials}
                firstName={assignedTo?.firstName}
                lastName={assignedTo?.lastName}
                isLocked={isLocked}
            />
        </Space>
    )
}

export const dueDateColumn: TableColumnProps<CaseOutput> = {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate) => {
        const getType = () => {
            if (dueDate === null) return undefined
            const today = dayjs()
            const dueDateDayJs = dayjs(dueDate)
            if (today.isAfter(dueDateDayJs)) return 'danger'
            if (today.isAfter(dueDateDayJs.subtract(1, 'w'))) return 'warning'
        }
        const type = getType()
        return <Text type={type}>{formatDate(dueDate)}</Text>
    },
}

export const companyColumn: TableColumnProps<CaseOutput> = {
    title: 'Company',
    dataIndex: 'country',
    key: 'company',
    render: (_, oddCase) => {
        const { metadata, entityId } = oddCase
        const companyId = entityId
        const {
            companyName,
            companyRegistrationNumber: registrationNumber,
            companyCountryCode,
        } = metadata

        return (
            <Tooltip
                title={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong>Company ID</strong>
                        <span style={{ whiteSpace: 'pre' }}>{companyId}</span>
                    </div>
                }
                overlayStyle={{ minWidth: 'max-content' }}
            >
                <Space direction="vertical" size={'small'}>
                    <Text>{companyName}</Text>
                    <Space size={'small'}>
                        <Tag>
                            {getEmojiFlag(companyCountryCode)} {companyCountryCode}
                        </Tag>
                        <Text type="secondary">{registrationNumber}</Text>
                    </Space>
                </Space>
            </Tooltip>
        )
    },
}

export const previousRiskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Previous Risk Score',
    dataIndex: 'previousRiskScore',
    key: 'previousRiskScore',
    width: 100,
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { previousRiskScore } = metadata
        return <RiskRatingTag riskRating={previousRiskScore} />
    },
}

export const preliminaryRiskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Preliminary Risk Score',
    dataIndex: 'preliminaryRiskScore',
    key: 'preliminaryRiskScore',
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { preliminaryRiskScore } = metadata
        return <RiskRatingTag riskRating={preliminaryRiskScore} isDraft />
    },
    width: 50,
}

export const previousKycColumn: TableColumnProps<CaseOutput> = {
    title: 'Previous KYC',
    dataIndex: 'previousKycScore',
    key: 'previousKycScore',
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { previousKycScore } = metadata
        return (
            <Space direction="vertical">
                <Tag style={{ fontSize: '0.75rem' }}>{previousKycScore}</Tag>
            </Space>
        )
    },
    width: 75,
}

export const oddTriggerColumn: TableColumnProps<CaseOutput> = {
    title: 'Trigger',
    dataIndex: 'triggerType',
    key: 'triggerType',
    render: (triggerType) => {
        switch (triggerType) {
            case 'TIME_TRIGGERED':
                return (
                    <Tag icon={<ClockCircleOutlined />} style={{ fontSize: '0.75rem' }}>
                        Time
                    </Tag>
                )
            case 'EVENT_TRIGGERED':
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        Event
                    </Tag>
                )
            default:
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        {triggerType && capitalizeFirstLetter(triggerType)}
                    </Tag>
                )
        }
    },
}

export const oddStatusColumn: TableColumnProps<CaseOutput> = {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: (state) => <StatusTag status={state} />,
}

const getReminderCountAsPercentage = (metadata: CaseOutput['metadata']) => {
    switch (metadata['waitStatus']) {
        case 'REMINDER_2':
            return 100
        case 'REMINDER_1':
            return 66
        case 'INITIAL':
            return 33
        default:
            return 0
    }
}

const getReminderCountLabel = (metadata: CaseOutput['metadata']) => {
    switch (metadata['waitStatus']) {
        case 'REMINDER_2':
            return '2nd reminder sent to company admins'
        case 'REMINDER_1':
            return '1st reminder sent to company admins'
        case 'INITIAL':
            return 'Initial information request sent to company admins'
        default:
            return 'No information request has been made'
    }
}

export const oddWaitingForCustomerColumn: TableColumnProps<CaseOutput> = {
    title: 'Reminders',
    dataIndex: 'metadata',
    key: 'waitingForCustomer',
    render: (metadata) => {
        return (
            <Space>
                <Progress
                    percent={getReminderCountAsPercentage(metadata)}
                    steps={3}
                    showInfo={false}
                    strokeColor={[green[4], orange[4], red[4]]}
                />
                <Tooltip title={getReminderCountLabel(metadata)}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </Space>
        )
    },
}

export const riskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Risk Rating',
    dataIndex: 'riskRating',
    key: 'riskRating',
    width: 100,
    render: (riskRating) => <RiskRatingTag riskRating={riskRating} />,
}
