import type { EventAudit } from 'bff/moons/generated/hydra'
import capitalize from 'lodash/capitalize'
import replace from 'lodash/replace'
import dayjs from 'packages/dayjs'
import { useEffect, useMemo, useState } from 'react'
import type { TimelineResponse } from 'types/timeline'

const CATCH_ALL_TIMELINE_LABEL = 'Activity'

interface TimelineData {
    label: string
    timestamp: string
    id: string
    category: string
    description?: string
}

export interface TimelineSource {
    timelineData: TimelineData[]
    allFilters: FilterSelectValue[]
    selectedFilters: FilterSelectValue[]
    setSelectedFilters: (filters: FilterSelectValue[]) => void
    pagination: {
        count?: number
        current: number
        onChange: (newPage: number) => void
    }
}

interface FilterSelectValue {
    disabled?: boolean
    key: string
    label: string
    value: string
}

const mapAuditLogToTimeline = (auditLog: EventAudit[]): TimelineData[] =>
    auditLog?.map((auditEvent) => ({
        label: auditEvent.description ?? '',
        timestamp: dayjs(auditEvent.occurredAt).format('L'),
        id: auditEvent.id ?? '',
        category: auditEvent.type ?? '',
    }))

const mapCompanyTimelineToTimeline = (timelineResponse: TimelineResponse): TimelineData[] =>
    timelineResponse
        .filter((timelineItem) => !!timelineItem.engagement)
        .map((timelineItem, index) => ({
            label: timelineItem.engagement,
            timestamp: dayjs(timelineItem.engagement_at).format('L'),
            description: timelineItem.note,
            id: `${timelineItem.engagement}-${index}`,
            category: CATCH_ALL_TIMELINE_LABEL,
        }))

export const useTimeline = (
    auditLog?: EventAudit[],
    companyTimeline?: TimelineResponse
): TimelineSource => {
    const [selectedFilters, setSelectedFilters] = useState<FilterSelectValue[]>([])
    const [currentPage, setCurrentPage] = useState(1)

    const uniqueCategories = useMemo(
        () => Array.from(new Set(auditLog?.map((auditEvent) => auditEvent.type))),
        [auditLog]
    )

    const allFilters = useMemo(
        () => [
            ...uniqueCategories.map((category) => ({
                key: category ?? '',
                label: capitalize(replace(category ?? '', '-', ' ')),
                value: category ?? '',
            })),
            ...(companyTimeline && companyTimeline.length > 0
                ? [
                      {
                          key: CATCH_ALL_TIMELINE_LABEL,
                          label: CATCH_ALL_TIMELINE_LABEL,
                          value: CATCH_ALL_TIMELINE_LABEL,
                      },
                  ]
                : []),
        ],
        [uniqueCategories, companyTimeline]
    )

    useEffect(() => {
        setSelectedFilters(allFilters)
    }, [allFilters])

    const timelineData = useMemo(
        () => (auditLog ? mapAuditLogToTimeline(auditLog) : []),
        [auditLog]
    )

    const companyTimelineData = useMemo(
        () => (companyTimeline ? mapCompanyTimelineToTimeline(companyTimeline) : []),
        [companyTimeline]
    )

    const filteredData = useMemo(
        () =>
            [...timelineData, ...companyTimelineData]
                .filter((item) =>
                    selectedFilters
                        .map((activeFilters) => activeFilters.value)
                        .includes(item.category)
                )
                .sort((a, b) => (dayjs(a.timestamp).isBefore(b.timestamp) ? 0 : -1)),
        [selectedFilters, timelineData, companyTimelineData]
    )

    return {
        timelineData: filteredData.slice((currentPage - 1) * 10, currentPage * 10),
        allFilters,
        selectedFilters,
        setSelectedFilters,
        pagination: {
            count: filteredData?.length,
            current: currentPage,
            onChange: (newPage: number) => setCurrentPage(newPage),
        },
    }
}
