import React, { useState } from 'react'
import { Modal, Space, Input, Alert, Button, Typography, Timeline, message, Steps, Tag } from 'antd'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CommentOutlined,
    ExclamationCircleOutlined,
    FieldTimeOutlined,
    HourglassOutlined,
    MailOutlined,
} from '@ant-design/icons'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context'
import { formatDate, formatTimestamp } from 'packages/dates/dates'
import type { CaseAuditEventType } from 'bff/moons/generated/case-manager'

const { Text } = Typography

const MIN_COMMENT_LENGTH_CHARACTERS = 30

export const ConclusionModal = () => {
    const {
        caseId,
        conclusionModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { mutateAsync: setCaseAsConcluded, isLoading } =
        bff.modals.setCaseAsConcluded.useMutation()

    const handleConclude = async () => {
        await setCaseAsConcluded({ caseId, comment })
        close()
    }

    const canConclude = comment.length >= MIN_COMMENT_LENGTH_CHARACTERS

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleConclude}
            title="Conclude this ODD case?"
            okText={'Conclude'}
            okButtonProps={{ disabled: !canConclude, loading: isLoading }}
            destroyOnClose
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <label htmlFor="conclusion-comment">
                    <Text>Add a comment (required)</Text>
                </label>
                <Input.TextArea
                    id="conclusion-comment"
                    placeholder="Add a comment"
                    minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                    showCount
                    style={{ marginBottom: '1rem' }}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Space>
        </Modal>
    )
}

export const OffboardingModal = () => {
    const {
        caseId,
        companyId,
        offboardingModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { data: offboardingData } = bff.modals.getOffboardingModalData.useQuery({
        companyId,
        caseId,
    })

    const {
        mutateAsync: setCaseAsOffboardingInitiated,
        isLoading: isSetCaseAsOffboardingInitiatedLoading,
    } = bff.modals.setCaseAsOffboardingInitiated.useMutation()

    const { mutateAsync: setCaseAsOffboarded, isLoading } =
        bff.modals.setCaseAsOffboarded.useMutation()

    const handleOffboardingInitiated = async () => {
        await setCaseAsOffboardingInitiated({ caseId })
    }

    const handleSubmit = async () => {
        await setCaseAsOffboarded({ caseId, comment })
        close()
    }

    const offboardingSteps = {
        WAITING_FOR_CUSTOMER: 0,
        WALLET_BLOCKED: 2,
        OFFBOARDING_INITIATED: 3,
        OFFBOARDING_COMPLETED: 4,
    }

    const currentOffboardingIndex =
        offboardingSteps[caseStateData?.state as keyof typeof offboardingSteps]

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title="Offboarding"
            okText={'Complete offboarding'}
            okButtonProps={{
                loading: isLoading,
                disabled:
                    comment.length < MIN_COMMENT_LENGTH_CHARACTERS || currentOffboardingIndex !== 3,
            }}
        >
            <Steps
                direction="vertical"
                size="small"
                current={currentOffboardingIndex}
                items={[
                    {
                        title: 'Waiting for customer',
                        description: (
                            <>
                                <p>
                                    This case has been flagged as missing customer information. An
                                    information request was sent to company admins on{' '}
                                    {formatTimestamp(offboardingData?.waitingForCustomer?.since)}.
                                    If customer information is not provided, the following actions
                                    will occur:
                                </p>
                                <ul style={{ padding: 0, margin: 0 }}>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.firstReminder
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>First reminder to submit information</span>
                                    </li>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.secondReminder
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>Second reminder to submit information</span>
                                    </li>
                                    <li>
                                        <strong>
                                            ~{' '}
                                            {formatDate(
                                                offboardingData?.waitingForCustomer?.walledBlocked
                                            )}
                                            :
                                        </strong>{' '}
                                        <span>Customer wallet will be automatically blocked</span>
                                    </li>
                                </ul>
                            </>
                        ),
                    },
                    {
                        title: 'Wallet blocked',
                        description: (
                            <>
                                <p>
                                    After the case has been waiting for customer information for 15
                                    days, the wallet will be automatically blocked.
                                </p>
                                <p>
                                    Wallet Status:{' '}
                                    <Tag
                                        color={
                                            offboardingData?.spendStatus === 'BLOCKED'
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {offboardingData?.spendStatus}
                                    </Tag>
                                </p>
                            </>
                        ),
                    },
                    {
                        title: 'Offboarding initiated',
                        description: (
                            <>
                                {currentOffboardingIndex < 3 ? (
                                    <Space direction="vertical">
                                        <p>
                                            Click below to indicate that offboarding has been
                                            initiated for this customer.
                                        </p>
                                        <Button
                                            disabled={caseStateData?.state !== 'WALLET_BLOCKED'}
                                            onClick={handleOffboardingInitiated}
                                            loading={isSetCaseAsOffboardingInitiatedLoading}
                                            type="primary"
                                        >
                                            Initiate offboarding
                                        </Button>
                                    </Space>
                                ) : (
                                    <p>
                                        Offboarding was initiated for this customer on{' '}
                                        {formatTimestamp(
                                            offboardingData?.offboardingInitiated?.since
                                        )}
                                        .
                                    </p>
                                )}
                            </>
                        ),
                    },
                    {
                        title: 'Complete offboarding',
                        description: (
                            <>
                                {offboardingData?.offboardingInitiated?.canCompleteOffboarding ? (
                                    <>
                                        <p>
                                            Two months has passed since offboarding was initiated.
                                            The case can now be completed by marking the offboarding
                                            as complete. Please explain why this company is being
                                            offboarded. 30 character minimum.
                                        </p>
                                        <br />
                                        <Text>
                                            <Text strong>Comment</Text> (min 30 characters)
                                        </Text>
                                        <Input.TextArea
                                            placeholder="Add a comment"
                                            minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                                            showCount
                                            style={{
                                                marginBottom: '1rem',
                                            }}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </>
                                ) : (
                                    <p>
                                        You must wait until 2 months after offboarding was iniated
                                        before offboarding can be marked as complete.
                                    </p>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </Modal>
    )
}

export const SendRequestInformationModal = () => {
    const {
        caseId,
        sendRequestInformationModal: { isOpen, close },
    } = useOddDetailContext()

    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { mutateAsync: requestInformation, isLoading } =
        bff.modals.sendRequestInformation.useMutation()

    const handleSubmit = async () => {
        try {
            await requestInformation({ caseId })
            message.success('Successfully requested missing information from company admins.')
        } catch (error) {
            message.error('Failed to request missing information from company admins.')
        }
        close()
    }

    const canRequest =
        caseStateData && ['IN_PROGRESS', 'INFORMATION_RECEIVED'].includes(caseStateData?.state)
    const isDisabled = isLoading || !canRequest

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title={'Request Missing KYC Information?'}
            okText={'Send'}
            okButtonProps={{
                loading: isLoading,
                disabled: isDisabled,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Notify all company admins to request the Missing KYC Information?</Text>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    Admins will be redirected to upload their documents in the Pleo app.
                </Text>

                <Alert
                    type="info"
                    icon={<FieldTimeOutlined />}
                    showIcon={true}
                    description={<Text>Automatic reminders will be sent after 7 and 14 days.</Text>}
                />
                {!canRequest && (
                    <Alert
                        type="warning"
                        icon={<ExclamationCircleOutlined />}
                        showIcon={true}
                        description={
                            <Text>
                                Can only request information when a case is assigned and has state
                                "In Progress" or "Information Received"!
                            </Text>
                        }
                    />
                )}
            </Space>
        </Modal>
    )
}

export const DelayNotificationsModal = () => {
    const {
        caseId,
        delayNotificationsModal: { isOpen, close },
    } = useOddDetailContext()

    const { data: caseStateData } = bff.modals.getCaseState.useQuery({ caseId })
    const { mutateAsync: delayNotifications, isLoading } =
        bff.modals.delayNotifications.useMutation()

    const handleSubmit = async () => {
        try {
            await delayNotifications({ caseId })
            message.success(
                'Successfully delayed wallet block and notifications to company admins by 7 days.'
            )
        } catch (error) {
            message.error('Failed to delay wallet block and notifications to company admins.')
        }
        close()
    }

    const canRequest = caseStateData && ['WAITING_FOR_CUSTOMER'].includes(caseStateData?.state)
    const isDisabled = isLoading || !canRequest

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title={'Delay customer notifications?'}
            okText={'Send'}
            okButtonProps={{
                loading: isLoading,
                disabled: isDisabled,
            }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>Should this company have more time to gather missing documents?</Text>
                <Alert
                    type="info"
                    icon={<HourglassOutlined />}
                    showIcon={true}
                    description={
                        <Text>
                            Upcoming automated reminders to company admins will be delayed by 1
                            week.
                        </Text>
                    }
                />
                <Alert
                    type="info"
                    icon={<CloseCircleOutlined />}
                    showIcon={true}
                    description={
                        <Text>The automatic wallet block will be delayed by 1 week as well.</Text>
                    }
                />
                {!canRequest && (
                    <Alert
                        type="warning"
                        icon={<ExclamationCircleOutlined />}
                        showIcon={true}
                        description={
                            <Text>This case currently has no open requests for information!</Text>
                        }
                    />
                )}
            </Space>
        </Modal>
    )
}

export const HistoryModal = () => {
    const {
        caseId,
        historyModal: { isOpen, close },
    } = useOddDetailContext()
    const { data } = bff.modals.getCaseTimeline.useQuery({ caseId })

    const timelineItems = data?.timeline.map((event) => ({
        ...mapEventType(event.eventType),
        children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    {formatTimestamp(event.by.at)} - {event.by.firstName} {event.by.lastName}
                </Text>
                <Text strong>{prettifyEventType(event.eventType)}</Text>
                <Text style={{ fontSize: '0.875em' }}>{event.description}</Text>
            </div>
        ),
    }))

    return (
        <Modal open={isOpen} onCancel={close} title="Case History" footer={null}>
            <Timeline items={timelineItems} style={{ marginTop: '16px' }} />
        </Modal>
    )
}

const mapEventType = (eventType: CaseAuditEventType): { color: string; dot?: React.ReactNode } => {
    switch (eventType) {
        case 'CHECK_DELETED':
            return {
                color: 'red',
                dot: <CloseCircleOutlined />,
            }
        case 'CHECK_COMPLETED':
        case 'UPDATED_STATE_TO_ODD_COMPLETED':
        case 'UPDATED_STATE_TO_OFFBOARDING_COMPLETED':
            return {
                color: 'green',
                dot: <CheckCircleOutlined />,
            }
        case 'UPDATED_CLOSED_NOTES':
            return {
                color: 'grey',
                dot: <CommentOutlined />,
            }
        case 'UPDATED_STATE_TO_WAITING_FOR_CUSTOMER':
            return {
                color: 'orange',
                dot: <HourglassOutlined />,
            }
        case 'COMMUNICATION_SCHEDULE_REQUEST_INFORMATION':
        case 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_1':
        case 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_2':
        case 'COMMUNICATION_WALLET_BLOCKED':
        case 'COMMUNICATION_ODD_COMPLETED':
            return {
                color: 'grey',
                dot: <MailOutlined />,
            }
        case 'COMMUNICATION_DELAY_EMAIL_SCHEDULE':
            return {
                color: 'grey',
                dot: <HourglassOutlined />,
            }
        case 'COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION':
        case 'COMMUNICATION_CANCEL_WALLET_BLOCKED':
            return {
                color: 'grey',
                dot: <CloseCircleOutlined />,
            }
        default:
            return {
                color: 'gray',
            }
    }
}

const prettifyEventType = (string: CaseAuditEventType) => {
    const acronyms = ['ODD']

    return string
        .split('_')
        .map((word) =>
            acronyms.includes(word)
                ? word
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
}
