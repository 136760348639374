import { MessageOutlined } from '@ant-design/icons'
import { Alert, Card, Table, TableColumnsType, Tag, Tooltip, Typography } from 'antd'
import type { CashbackResponse } from 'bff/moons/generated/tarvos'
import dayjs from 'packages/dayjs'
import styled from 'styled-components'
import { formatMoneyIntl } from 'utils/money'

const { Text } = Typography

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;
`

const FirstColumn = styled.div`
    padding-left: 16px;
`

const LastColumn = styled.div`
    padding-right: 16px;
`

export const CashbackTable = ({
    cashback,
    companyName,
    error,
}: {
    cashback?: CashbackResponse[]
    companyName?: string
    error?: Error
}) => {
    const columns: TableColumnsType<CashbackResponse> = [
        {
            key: 'expensesFrom',
            dataIndex: 'expensesFrom',
            title: <FirstColumn>Expenses from</FirstColumn>,
            render: (expensesFrom) => (
                <FirstColumn>{dayjs(expensesFrom, 'YYYY-MM-DD').format('MMM D, YYYY')}</FirstColumn>
            ),
        },
        {
            key: 'expensesTo',
            dataIndex: 'expensesTo',
            title: 'Expenses to',
            render: (expensesTo) => dayjs(expensesTo, 'YYYY-MM-DD').format('MMM D, YYYY'),
        },
        {
            key: 'invoiceId',
            dataIndex: 'invoiceId',
            title: 'Invoice ID',
            render: (invoiceId) => <Text copyable>{invoiceId}</Text>,
        },
        {
            key: 'cashbackAmount',
            dataIndex: 'cashbackAmount',
            render: (cashbackAmount) => formatMoneyIntl(cashbackAmount),
            title: 'Cashback amount',
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: 'Created at',
            render: (createdAt) => dayjs(createdAt).utc().format('MMM D, YYYY h:mm A'),
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            render: (status, row) => (
                <div>
                    <Tag>{status}</Tag>
                    {row?.failureReason && (
                        <Tooltip title={row.failureReason}>
                            <MessageOutlined />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            key: 'expenseId',
            dataIndex: 'expenseId',
            title: <LastColumn>Expense ID</LastColumn>,
            render: (expenseId) =>
                expenseId && (
                    <LastColumn>
                        <Text copyable ellipsis style={{ maxWidth: '10rem' }}>
                            {expenseId}
                        </Text>
                    </LastColumn>
                ),
        },
    ]

    return (
        <Card
            title={companyName ? `${companyName}'s cashback` : 'Cashback'}
            bodyStyle={{ padding: 0, margin: 0 }}
        >
            {error && (
                <Alert
                    showIcon
                    message={error.name}
                    description={`${error.message}. Please try again later or contact #team_loki`}
                    type="error"
                    style={{ margin: '1rem' }}
                />
            )}
            <TableStyleProvider>
                <Table
                    columns={columns}
                    dataSource={cashback}
                    size="small"
                    loading={!cashback && !error}
                    rowKey={'id'}
                    data-testid="cashback-table"
                />
            </TableStyleProvider>
        </Card>
    )
}
