import React, { FC, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import type { Store } from 'antd/lib/form/interface'
import { Formik } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import styled from 'styled-components'

import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import type { SupplementaryUnloadStatus } from 'pages/compliance/wallet-unloads/utils'
import { inputWidth } from 'theme/tokens'
import { useWalletUnloads } from 'services/deimos/wallet'

export interface Props {
    walletUnload: WalletUnloadResponse
    status: WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
    disabled: boolean
    setDisableActionButtons: (value: boolean) => void
}

export const Investigate: FC<React.PropsWithChildren<Props>> = ({
    walletUnload,
    status,
    disabled,
    setDisableActionButtons,
}) => {
    const [modalActive, setModalActive] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(false)
    const {
        mutations: { updateWalletUnload },
    } = useWalletUnloads(status)
    const onConfirm = async (values: Store) => {
        try {
            setDisableSaveButton(true)
            setDisableActionButtons(true)
            await updateWalletUnload(walletUnload.id, {
                ...walletUnload,
                statusReason: values.statusReason || '',
                status: WalletUnloadResponseStatus.UNDER_INVESTIGATION,
            })
            setModalActive(false)
            notification.success({
                message: 'Wallet unload successfully sent to investigation',
            })
        } catch (e) {
            notification.error({
                message: 'Failed to send wallet unload to investigation',
            })
        } finally {
            setDisableSaveButton(false)
            setDisableActionButtons(false)
        }
    }

    return (
        <>
            <Button type="link" onClick={() => setModalActive(true)} disabled={disabled}>
                Investigate
            </Button>
            <Modal
                title="Send wallet unload request to investigation"
                open={modalActive}
                footer={null}
                onCancel={() => setModalActive(false)}
                width={600}
                centered
            >
                <Formik initialValues={{ statusReason: '' }} onSubmit={onConfirm}>
                    <Form labelCol={{ span: 6 }}>
                        <FormItem colon={true} name="statusReason" label="Reason">
                            <Input.TextArea
                                name="statusReason"
                                style={{ width: `${inputWidth.large}` }}
                            />
                        </FormItem>
                        <ButtonContainer>
                            <Button htmlType="submit" type="primary" disabled={disableSaveButton}>
                                Save
                            </Button>
                        </ButtonContainer>
                    </Form>
                </Formik>
            </Modal>
        </>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
