import { Modal } from 'antd'
import type { HigherLimitApplicationFullDto } from 'bff/moons/generated/kale'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import * as yup from 'yup'

const getValidationSchema = ({ minLimit, maxLimit }: { minLimit: number; maxLimit: number }) =>
    yup.object().shape({
        approvedLimit: yup.number().required('This field is required').min(minLimit).max(maxLimit),
    })

const convertToMajor = (value: number) => {
    return value / 100
}

interface Props {
    application: HigherLimitApplicationFullDto | null
    minLimit: number
    maxLimit: number
    onSubmit: (values: { approvedLimit: number }) => void
    onCancel: () => void
}

export default function HigherLimitApprovalModal({
    onCancel,
    application,
    maxLimit,
    minLimit,
    onSubmit,
}: Props) {
    if (application === null) return null

    return (
        <Modal
            open={Boolean(application)}
            onCancel={onCancel}
            footer={null}
            centered
            title="Higher Limit Approval"
        >
            <Formik
                enableReinitialize
                validationSchema={getValidationSchema({ maxLimit, minLimit })}
                initialValues={{ approvedLimit: convertToMajor(application.requestedLimit.value) }}
                onSubmit={onSubmit}
            >
                {({ isValid }) => (
                    <Form>
                        <Form.Item name="approvedLimit" label="Approved Limit">
                            <Input
                                type="number"
                                name="approvedLimit"
                                suffix={application.requestedLimit.currency}
                            />
                        </Form.Item>
                        <SubmitButton disabled={!isValid} type="primary">
                            Save
                        </SubmitButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
