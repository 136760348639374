import { Button, Input, Modal, Space } from 'antd'
import type { HigherLimitApplicationFullDto } from 'bff/moons/generated/kale'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
    application: HigherLimitApplicationFullDto | null
    isLoading: boolean
    onSubmit: (values: { comment: string }) => void
    onCancel: () => void
}

export default function HigherLimitInformationRequestModal({
    onCancel,
    isLoading,
    application,
    onSubmit,
}: Props) {
    const [comment, setComment] = useState('')

    return (
        <Modal
            open={Boolean(application)}
            onCancel={onCancel}
            footer={null}
            centered
            title="Higher Limit Information Request"
        >
            <StyledSpace direction="vertical" size="middle">
                <Input.TextArea
                    name="comment"
                    autoSize={{ minRows: 10, maxRows: 20 }}
                    placeholder="Comment to customer"
                    onChange={(event) => setComment(event.target.value)}
                />
                <Button
                    disabled={!comment}
                    type="primary"
                    onClick={() => onSubmit({ comment })}
                    loading={isLoading}
                >
                    Send
                </Button>
            </StyledSpace>
        </Modal>
    )
}

const StyledSpace = styled(Space)`
    width: 100%;
`
