import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'utils/yup'

import { SessionProvider } from './providers/auth-context'
import { UserProvider } from 'providers/user-context'
import { store } from './store/store'
import { ErrorHandlerProvider } from './providers/error-handler'
import { DatadogRUMProvider } from 'providers/datadog-rum'

import Router from './router/router'

import './index.css'
import { ThemeDecorator } from 'utils/decorators/theme-decorator'
import { StrictMode } from 'react'
import { BffProvider } from 'providers/bff-provider'

const App = () => (
    <StrictMode>
        <ErrorHandlerProvider requiresPlaceholderPage={true}>
            <SessionProvider>
                <UserProvider>
                    <BffProvider>
                        <DatadogRUMProvider>
                            <Provider store={store}>
                                <ThemeDecorator>
                                    <Router />
                                </ThemeDecorator>
                            </Provider>
                        </DatadogRUMProvider>
                    </BffProvider>
                </UserProvider>
            </SessionProvider>
        </ErrorHandlerProvider>
    </StrictMode>
)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
