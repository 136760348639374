/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Case Manager API
 * Case Manager OpenAPI definitions
 * OpenAPI spec version: 5.2.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('caseManager')

export type GetCaseParams = {
    includeDeleted?: boolean
}

export type GetTotalNumberStateItem =
    (typeof GetTotalNumberStateItem)[keyof typeof GetTotalNumberStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTotalNumberStateItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
} as const

export type GetTotalNumberParams = {
    /**
     * The assignee ID of a case.
     */
    assigneeId?: string
    /**
     * The state of a case should be comprised within this list. Leaving this null or empty will return all states.
     */
    state?: GetTotalNumberStateItem[]
    /**
     * The lower bound of the created date range as ISO string.
     */
    created_from?: string
    /**
     * The upper bound of the created date range as ISO string.
     */
    created_to?: string
    /**
     * The lower bound of the due date range as ISO string.
     */
    due_from?: string
    /**
     * The upper bound of the due date range as ISO string.
     */
    due_to?: string
    /**
     * The lower bound of the assigned_at date range as ISO string.
     */
    assigned_at_from?: string
    /**
     * The upper bound of the assigned_at date range as ISO string.
     */
    assigned_at_to?: string
}

export type SearchCasesStateItem = (typeof SearchCasesStateItem)[keyof typeof SearchCasesStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchCasesStateItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
} as const

export type GetAllCasesParams = {
    includeDeleted?: boolean
}

export type UpdateCaseRequestBodyState =
    (typeof UpdateCaseRequestBodyState)[keyof typeof UpdateCaseRequestBodyState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCaseRequestBodyState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
} as const

export interface UpdateCaseRequestBody {
    assignee?: Analyst
    clearAssignment?: boolean
    closedNotes?: string
    labels?: string[]
    state?: UpdateCaseRequestBodyState
}

export type ResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultError = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorStackTraceItem[]
}

export type ResultDetails = { [key: string]: { [key: string]: any } }

export interface Result {
    details?: ResultDetails
    error?: ResultError
    healthy: boolean
    message?: string
    time: string
}

export interface RecordCheckCompletedRequestBody {
    key: string
}

export type PageOrder = (typeof PageOrder)[keyof typeof PageOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageOrder = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST',
} as const

export type SearchCasesParams = {
    /**
     * The entity ID a case is associated with.
     */
    entityId?: string
    /**
     * The assignee ID of a case.
     */
    assigneeId?: string
    /**
     * The state of a case should be comprised within this list. Leaving this null or empty will return all states.
     */
    state?: SearchCasesStateItem[]
    /**
     * The lower bound of the created date range as ISO string.
     */
    created_from?: string
    /**
     * The upper bound of the created date range as ISO string.
     */
    created_to?: string
    /**
     * The lower bound of the due date range as ISO string.
     */
    due_from?: string
    /**
     * The upper bound of the due date range as ISO string.
     */
    due_to?: string
    /**
     * The lower bound of the assigned_at date range as ISO string.
     */
    assigned_at_from?: string
    /**
     * The upper bound of the assigned_at date range as ISO string.
     */
    assigned_at_to?: string
    /**
     * Whether to include deleted cases in the search results.
     */
    include_deleted?: boolean
    metadata?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export interface DeleteCaseRequestBody {
    reason: string
}

export interface DataResponseUUID {
    data: string
}

export interface DataResponseString {
    data: string
}

export interface DataResponseResult {
    data: Result
}

export type DataResponseMapCaseStateLongData = { [key: string]: number }

export interface DataResponseMapCaseStateLong {
    data: DataResponseMapCaseStateLongData
}

export type DataResponseMapCaseCustomActionIdCaseCustomActionData = {
    [key: string]:
        | 'SCHEDULE_REQUEST_INFO_EMAILS'
        | 'SEND_REMINDER_1_EMAIL'
        | 'SEND_REMINDER_2_EMAIL'
        | 'SEND_WALLET_BLOCKED_EMAIL'
        | 'DELAY_EMAIL_SCHEDULE'
}

export interface DataResponseMapCaseCustomActionIdCaseCustomAction {
    data: DataResponseMapCaseCustomActionIdCaseCustomActionData
}

export interface DataResponseListCaseOutput {
    data: CaseOutput[]
}

export interface DataResponseCaseOutput {
    data: CaseOutput
}

export interface CursorPaginatedResponseCaseOutput {
    data: CaseOutput[]
    pagination: CursorPageInfo
}

export type CursorPageCurrentRequestInfoParameters = { [key: string]: string[] }

export interface CursorPageCurrentRequestInfo {
    after?: string
    before?: string
    limit?: number
    offset?: number
    parameters: CursorPageCurrentRequestInfoParameters
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export interface CursorPageInfo {
    currentRequestPagination: CursorPageCurrentRequestInfo
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
    total?: number
}

export type CreateCaseRequestBodyCaseMetadata = { [key: string]: string }

export interface CreateCaseRequestBody {
    caseMetadata: CreateCaseRequestBodyCaseMetadata
    dueDate: string
    entityId: string
}

export interface CompletedCheckOutput {
    caseId: string
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    key: string
}

export type CaseOutputTriggerType =
    (typeof CaseOutputTriggerType)[keyof typeof CaseOutputTriggerType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputTriggerType = {
    TIME_TRIGGERED: 'TIME_TRIGGERED',
    EVENT_TRIGGERED: 'EVENT_TRIGGERED',
} as const

export type CaseOutputState = (typeof CaseOutputState)[keyof typeof CaseOutputState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
} as const

export type CaseOutputMetadata = { [key: string]: string }

export type CaseOutputCaseType = (typeof CaseOutputCaseType)[keyof typeof CaseOutputCaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputCaseType = {
    ODD: 'ODD',
} as const

export interface CaseOutput {
    assignedTo?: AssignedAnalyst
    caseType: CaseOutputCaseType
    closedNotes?: string
    completedChecks: CompletedCheckOutput[]
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    deletedReason?: string
    dueDate: string
    entityId: string
    firstAssignedTo?: AssignedAnalyst
    id: string
    labels?: string[]
    lastAssignedTo?: AssignedAnalyst
    metadata: CaseOutputMetadata
    state: CaseOutputState
    timeline: CaseAudit[]
    triggerType: CaseOutputTriggerType
    updatedBy: AssignedAnalyst
}

export type CaseAuditEventType = (typeof CaseAuditEventType)[keyof typeof CaseAuditEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseAuditEventType = {
    NEW_CASE: 'NEW_CASE',
    UPDATED_ASSIGNEE: 'UPDATED_ASSIGNEE',
    UPDATED_LABELS: 'UPDATED_LABELS',
    UPDATED_CLOSED_NOTES: 'UPDATED_CLOSED_NOTES',
    UPDATED_STATE_TO_IN_PROGRESS: 'UPDATED_STATE_TO_IN_PROGRESS',
    UPDATED_STATE_TO_WAITING_FOR_CUSTOMER: 'UPDATED_STATE_TO_WAITING_FOR_CUSTOMER',
    UPDATED_STATE_TO_INFORMATION_RECEIVED: 'UPDATED_STATE_TO_INFORMATION_RECEIVED',
    UPDATED_STATE_TO_ODD_COMPLETED: 'UPDATED_STATE_TO_ODD_COMPLETED',
    UPDATED_STATE_TO_WALLET_BLOCKED: 'UPDATED_STATE_TO_WALLET_BLOCKED',
    UPDATED_STATE_TO_OFFBOARDING_INITIATED: 'UPDATED_STATE_TO_OFFBOARDING_INITIATED',
    UPDATED_STATE_TO_OFFBOARDING_COMPLETED: 'UPDATED_STATE_TO_OFFBOARDING_COMPLETED',
    COMMUNICATION_SCHEDULE_REQUEST_INFORMATION: 'COMMUNICATION_SCHEDULE_REQUEST_INFORMATION',
    COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION:
        'COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION',
    COMMUNICATION_REQUEST_INFORMATION_REMINDER_1: 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_1',
    COMMUNICATION_REQUEST_INFORMATION_REMINDER_2: 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_2',
    COMMUNICATION_WALLET_BLOCKED: 'COMMUNICATION_WALLET_BLOCKED',
    COMMUNICATION_DELAY_EMAIL_SCHEDULE: 'COMMUNICATION_DELAY_EMAIL_SCHEDULE',
    COMMUNICATION_CANCEL_WALLET_BLOCKED: 'COMMUNICATION_CANCEL_WALLET_BLOCKED',
    COMMUNICATION_ODD_COMPLETED: 'COMMUNICATION_ODD_COMPLETED',
    COMPANY_SPEND_BLOCKED: 'COMPANY_SPEND_BLOCKED',
    CHECK_COMPLETED: 'CHECK_COMPLETED',
    CHECK_DELETED: 'CHECK_DELETED',
    CASE_SOFT_DELETED: 'CASE_SOFT_DELETED',
    NEW_DOCUMENT_UPLOADED: 'NEW_DOCUMENT_UPLOADED',
} as const

export interface AssignedAnalyst {
    at: string
    firstName: string
    id: string
    lastName: string
}

export interface CaseAudit {
    by: AssignedAnalyst
    caseId: string
    description: string
    eventType: CaseAuditEventType
    id: string
}

export interface Analyst {
    firstName: string
    id: string
    lastName: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Returns the readiness health of the service
 */
export const checkReadiness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * @summary Returns the liveness health of the service
 */
export const checkLiveness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health/liveness`, method: 'get' }, options)
}

/**
 * Backfill cases to Kafka for the given case type
 * @summary Backfill cases to Kafka
 */
export const backfillCases = (caseType: 'ODD', options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/kafka-backfill-cases/${caseType}`, method: 'post' },
        options
    )
}

/**
 * Fetch all cases that will be created on the next job run
 * @summary Fetch all pending cases
 */
export const getAllPendingCases = (
    caseType: 'ODD',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/${caseType}/cases-to-open`, method: 'get' },
        options
    )
}

/**
 * Fetch all cases per case type (only ODD at the moment)
 * @summary Fetch all existing cases per case type
 */
export const getAllCases = (
    caseType: 'ODD',
    params: GetAllCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListCaseOutput>(
        { url: `/v1/cases/${caseType}`, method: 'get', params },
        options
    )
}

/**
 * Create a new case for the given case type
 * @summary Create a new case
 */
export const createCase = (
    caseType: 'ODD',
    createCaseRequestBody: CreateCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseUUID>(
        {
            url: `/v1/cases/${caseType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCaseRequestBody,
        },
        options
    )
}

/**
 * @summary Retrieves a list of actions an analyst can take for a given case type on a given case.
 */
export const getCustomActions = (caseType: 'ODD', options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseMapCaseCustomActionIdCaseCustomAction>(
        { url: `/v1/cases/${caseType}/custom-actions`, method: 'get' },
        options
    )
}

/**
 * Search for cases per case type and other criteria
 * @summary Search for existing cases per case type
 */
export const searchCases = (
    caseType: 'ODD',
    params: SearchCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseCaseOutput>(
        { url: `/v1/cases/${caseType}/search`, method: 'get', params },
        options
    )
}

/**
 * Calculate the total number of existing cases per case type
 * @summary Calculate the total number of existing cases per case type
 */
export const getTotalNumber = (
    caseType: 'ODD',
    params: GetTotalNumberParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapCaseStateLong>(
        { url: `/v1/cases/${caseType}/total`, method: 'get', params },
        options
    )
}

/**
 * Delete a case
 * @summary Delete a case
 */
export const deleteCase = (
    caseType: 'ODD',
    caseId: string,
    deleteCaseRequestBody: DeleteCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/cases/${caseType}/${caseId}`,
            method: 'delete',
            headers: { 'Content-Type': '*/*' },
            data: deleteCaseRequestBody,
        },
        options
    )
}

/**
 * Fetch a case by its ID
 * @summary Fetch a case by ID
 */
export const getCase = (
    caseType: 'ODD',
    caseId: string,
    params: GetCaseParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseOutput>(
        { url: `/v1/cases/${caseType}/${caseId}`, method: 'get', params },
        options
    )
}

/**
 * Update a case
 * @summary Update a case
 */
export const updateCase = (
    caseType: 'ODD',
    caseId: string,
    updateCaseRequestBody: UpdateCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/cases/${caseType}/${caseId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCaseRequestBody,
        },
        options
    )
}

/**
 * Assign a case to the current user
 * @summary Assign a case to the current user
 */
export const assignCaseToMe = (
    caseType: 'ODD',
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/cases/${caseType}/${caseId}/assign-to-me`, method: 'put' },
        options
    )
}

/**
 * Records a check as completed on a case
 * @summary Records a check as completed on a case
 */
export const recordCheckCompleted = (
    caseType: 'ODD',
    caseId: string,
    recordCheckCompletedRequestBody: RecordCheckCompletedRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/cases/${caseType}/${caseId}/checks`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: recordCheckCompletedRequestBody,
        },
        options
    )
}

/**
 * Removes a completed check from a case
 * @summary Removes a completed check from a case
 */
export const removeCompletedCheck = (
    caseType: 'ODD',
    caseId: string,
    checkKey: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/cases/${caseType}/${caseId}/checks/${checkKey}`, method: 'delete' },
        options
    )
}

export const performCustomAction = (
    caseType: 'ODD',
    caseId: string,
    actionId:
        | 'SCHEDULE_REQUEST_INFO_EMAILS'
        | 'SEND_REMINDER_1_EMAIL'
        | 'SEND_REMINDER_2_EMAIL'
        | 'SEND_WALLET_BLOCKED_EMAIL'
        | 'DELAY_EMAIL_SCHEDULE',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseString>(
        { url: `/v1/cases/${caseType}/${caseId}/custom-actions/${actionId}`, method: 'post' },
        options
    )
}

export type CheckReadinessResult = NonNullable<Awaited<ReturnType<typeof checkReadiness>>>
export type CheckLivenessResult = NonNullable<Awaited<ReturnType<typeof checkLiveness>>>
export type BackfillCasesResult = NonNullable<Awaited<ReturnType<typeof backfillCases>>>
export type GetAllPendingCasesResult = NonNullable<Awaited<ReturnType<typeof getAllPendingCases>>>
export type GetAllCasesResult = NonNullable<Awaited<ReturnType<typeof getAllCases>>>
export type CreateCaseResult = NonNullable<Awaited<ReturnType<typeof createCase>>>
export type GetCustomActionsResult = NonNullable<Awaited<ReturnType<typeof getCustomActions>>>
export type SearchCasesResult = NonNullable<Awaited<ReturnType<typeof searchCases>>>
export type GetTotalNumberResult = NonNullable<Awaited<ReturnType<typeof getTotalNumber>>>
export type DeleteCaseResult = NonNullable<Awaited<ReturnType<typeof deleteCase>>>
export type GetCaseResult = NonNullable<Awaited<ReturnType<typeof getCase>>>
export type UpdateCaseResult = NonNullable<Awaited<ReturnType<typeof updateCase>>>
export type AssignCaseToMeResult = NonNullable<Awaited<ReturnType<typeof assignCaseToMe>>>
export type RecordCheckCompletedResult = NonNullable<
    Awaited<ReturnType<typeof recordCheckCompleted>>
>
export type RemoveCompletedCheckResult = NonNullable<
    Awaited<ReturnType<typeof removeCompletedCheck>>
>
export type PerformCustomActionResult = NonNullable<Awaited<ReturnType<typeof performCustomAction>>>
