import { Breadcrumb, Button, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { HistoryOutlined } from '@ant-design/icons'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context'

import type { AssignedAnalyst } from 'bff/moons/generated/case-manager'
import { StatusTag } from './status-tag'
import { ToggleAssigneeButton } from './toggle-assignee'

const { Title, Paragraph } = Typography

export const Header = () => {
    const { caseId, historyModal } = useOddDetailContext()
    const { data } = bff.pageHeader.getOdd.useQuery({ caseId })

    return (
        <Space direction="vertical">
            <Breadcrumbs companyName={data?.companyName} />
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical" size={0}>
                    <Title level={3} style={{ margin: 0 }}>
                        {data?.companyName}
                    </Title>
                    <Paragraph type="secondary">{data?.address}</Paragraph>
                    <Space>
                        {data?.status && <StatusTag status={data.status} />}
                        <User assignee={data?.assignee} />
                    </Space>
                </Space>
                <Space>
                    <Button icon={<HistoryOutlined />} onClick={historyModal.open}>
                        History
                    </Button>
                </Space>
            </Space>
        </Space>
    )
}

const Breadcrumbs = ({ companyName }: { companyName?: string }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/compliance'}>Compliance</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{companyName}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const User = ({ assignee }: { assignee?: AssignedAnalyst }) => {
    const { caseId, isLocked } = useOddDetailContext()

    const caseHandlerInitials = assignee
        ? `${assignee.firstName} ${assignee.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    return (
        <ToggleAssigneeButton
            id={caseId}
            hasAssignee={Boolean(assignee)}
            caseHandlerInitials={caseHandlerInitials}
            firstName={assignee?.firstName}
            lastName={assignee?.lastName}
            isLocked={isLocked}
        />
    )
}
