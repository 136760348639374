import React, { FC, useEffect, useState } from 'react'
import { message, Result } from 'antd'

import { useComplianceNotes } from 'services/deimos/styx-company/styx-company'
import type { ComplianceNote } from 'types/styx'
import Spinner from 'components/content-spinner'
import dayjs from 'packages/dayjs'
import ComplianceNoteEditor from './compliance-note-editor/compliance-note-editor'
import ComplianceNoteField from './compliance-note-field/compliance-note-field'

interface NotesProps {
    companyId: string
}

const ComplianceNotes: FC<React.PropsWithChildren<NotesProps>> = ({ companyId }) => {
    const { data: notes, mutations, error, isValidating } = useComplianceNotes(companyId)

    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState('')

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    const handleSubmit = () => {
        if (!value) return

        setSubmitting(true)
        mutations.createNote(value)
        setValue('')
        setSubmitting(false)
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
    }

    const listNotes = (complianceNotes: ComplianceNote[]) => {
        return complianceNotes
            .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
            .map((note) => <ComplianceNoteField key={note.id} note={note}></ComplianceNoteField>)
    }

    if (error) {
        return (
            <Result
                status="error"
                title="Failed to load content"
                subTitle="If this issue persists, contact Team Atlas"
            />
        )
    } else if (isValidating || !notes) {
        return <Spinner />
    } else {
        return (
            <>
                {listNotes(notes)}
                <ComplianceNoteEditor
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    value={value}
                />
            </>
        )
    }
}

export default ComplianceNotes
