import React, { useState } from 'react'
import {
    Button,
    Card,
    Divider,
    Modal,
    Space,
    Table,
    Tag,
    Typography,
    message,
    notification,
    Popover,
} from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import dayjs, { Dayjs } from 'packages/dayjs'
import { Link, useNavigate } from 'react-router-dom'
import createPersistedState from 'use-persisted-state'

import type { GetFlagResponse, FlagRow, Employee } from '@pleo-io/deimos'
import type { ColumnProps } from 'antd/lib/table'

import { useArielFlagsPaginated } from 'services/deimos/transaction-monitoring'
import type { Company } from 'types/deimos-company'
import { RiskScore } from 'types/styx'
import ManuallyFlagTransactionForm from 'pages/compliance/transaction-monitoring/manually-flag-transaction-form/manually-flag-transaction-form'

import CardStatusWidget from './card-status-widget'
import { PageContentLayout, PointerContainer } from 'components/layout-containers'

import {
    investigationStatuses,
    getDateOfLatestFlag,
    DisplayInvestigationStatusFraud,
    datePopoverText,
} from 'pages/compliance/transaction-monitoring/utils'
import { snakeCaseToTitleSnakeCase, snakeCaseToTitleCase } from 'utils/strings'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { getFraudRules } from 'services/ariel/rules-report'
import ProcessList from 'components/process-list/process-list'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { FlagCategory, InvestigationStatus, TransactionFamily } from 'types/ariel'

const { Text } = Typography
const { RangePicker } = DayjsDatePicker

const columns = (status: InvestigationStatus): ColumnProps<GetFlagResponse>[] => [
    {
        title: () => <Popover content={datePopoverText(status)}>Date</Popover>,
        dataIndex: 'rows',
        key: 'date',
        render: (rows: FlagRow[]) =>
            getDateOfLatestFlag(rows) ? dayjs(getDateOfLatestFlag(rows)).format('lll') : null,
    },
    {
        title: 'Card',
        key: 'card',
        render: (record: GetFlagResponse) =>
            record.card ? (
                <>
                    <span>{record.card.pan}</span>
                    <Divider type="vertical" />
                    <Tag color="blue">{record.card.status}</Tag>
                </>
            ) : (
                '-'
            ),
    },
    {
        title: 'Rows',
        dataIndex: 'rows',
        key: 'rows',
        render: (rows: FlagRow[]) => rows.length,
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (company?: Company) =>
            company?.name ? (
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/compliance/companies/${company?.id}`}
                >
                    {company?.name}
                </Link>
            ) : (
                '-'
            ),
    },
    {
        title: 'Email',
        dataIndex: 'employee',
        key: 'email',
        render: (employee?: Employee) => <BreakText>{employee?.email ?? '-'}</BreakText>,
    },
    {
        title: 'Employee',
        dataIndex: 'employee',
        key: 'employeeName',
        render: (employee?: Employee) => `${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`,
    },
    {
        title: 'Risk Score',
        dataIndex: 'riskScore',
        key: 'company',
        render: (riskScore?: RiskScore) =>
            riskScore ? (
                <Text type={riskScore === RiskScore.HIGH ? 'danger' : undefined}>
                    {snakeCaseToTitleSnakeCase(String(riskScore))}
                </Text>
            ) : (
                '-'
            ),
    },
]

const BreakText = styled(Text)`
    word-break: break-all;
`

const tomorrow = dayjs().add(1, 'day')
const oneWeekAgo = dayjs().subtract(1, 'week')

const usePersistedState = createPersistedState<InvestigationStatus[]>('investigationStatusFraud')

const Fraud = () => {
    const hasGetFraudRulesReport = useHasPermissions(['get-ariel-fraud-rules-report'])
    const [visible, setVisible] = React.useState(false)
    const [investigationStatus, setInvestigationStatus] = usePersistedState([
        InvestigationStatus.NEW,
    ])
    const [paginationOptions, setPaginationOptions] = useState<{
        pageSize: number
        pageOffset: number
    }>({ pageSize: 50, pageOffset: 0 })
    const [category] = React.useState<FlagCategory[]>([FlagCategory.CARD_FRAUD])
    const [{ to, from }, setDateRange] = React.useState<{ to: Dayjs; from: Dayjs }>({
        to: tomorrow,
        from: oneWeekAgo,
    })

    const navigate = useNavigate()
    const {
        data: flagsPaginated,
        mutations,
        currentTransaction,
        failedTransaction,
        successfulTransaction,
    } = useArielFlagsPaginated({
        to: to.toISOString(),
        from: from.toISOString(),
        status: investigationStatus as InvestigationStatus[],
        category,
        pageSize: paginationOptions.pageSize,
        pageOffset: paginationOptions.pageOffset,
    })

    const flags = flagsPaginated?.rows

    const [successfulTransactions, setSuccessfulTransactions] = useState<string[]>([])
    const [multipleTransactionModalVisible, setMultipleTransactionModalVisible] = useState(false)
    const [allMultipleTransactions, setAllMultipleTransactions] = useState<string[]>([])
    const [failedTransactions, setFailedTransactions] = useState<string[]>([])

    if (failedTransaction && !failedTransactions.includes(failedTransaction))
        setFailedTransactions([...failedTransactions, failedTransaction])

    if (successfulTransaction && !successfulTransactions.includes(successfulTransaction))
        setSuccessfulTransactions([...successfulTransactions, successfulTransaction])

    const onRowClick = (record: GetFlagResponse) => {
        navigate(
            `${
                record.card?.id
            }?investigationStatus=${investigationStatus}&to=${to.toISOString()}&from=${from.toISOString()}&pageSize=${
                paginationOptions.pageSize
            }&pageOffset=${paginationOptions.pageOffset}`
        )
    }

    const tabList = investigationStatuses.map((status) => ({
        key: status,
        tab: snakeCaseToTitleCase(status),
    }))

    const onClickReport = async () => {
        try {
            const res = await getFraudRules()
            const rules = await res.text()
            const readableRules = rules.replace(/"/g, '').split('\\n').join('\n')
            await navigator.clipboard.writeText(readableRules)
            message.success('Fraud rules copied to clipboard')
        } catch (e) {
            notification.error({
                description: 'Unable to retrieve rules report from Ariel',
                message: (e as Error).message,
            })
        }
    }

    function onSubmit(values: any) {
        const transactionIds = multipleTransactionIds(values.transactionId)

        if (transactionIds.length > 1) {
            setAllMultipleTransactions(transactionIds)
            mutations.createMultipleManualFlags({
                transactionIds: transactionIds,
                notes: values.notes,
                investigationStatus: values.investigationStatus as InvestigationStatus,
                category: FlagCategory.CARD_FRAUD,
                family: TransactionFamily.CARD,
            })
            setMultipleTransactionModalVisible(true)
        } else {
            mutations.createManualFlag({
                ...values,
                investigationStatus: values.investigationStatus as InvestigationStatus,
                category: FlagCategory.CARD_FRAUD,
                family: TransactionFamily.CARD,
            })
        }
        setVisible(false)
    }

    function multipleTransactionIds(ids: string) {
        const transactionIds = ids.split(/\r?\n/)
        return transactionIds
    }

    function copyTransactions(transactions: string[]) {
        navigator.clipboard.writeText(transactions.join('\r\n'))
    }

    return (
        <PageContentLayout>
            <Card>
                <Row>
                    <FilterContainer>
                        <Space>
                            <Text>Filter by date:</Text>
                            <RangePicker
                                value={[from, to]}
                                onChange={(range) =>
                                    setDateRange({
                                        from: range?.[0] ?? oneWeekAgo,
                                        to: range?.[1] ?? tomorrow,
                                    })
                                }
                                allowClear={false}
                            />
                        </Space>
                        <Space>
                            <Text>Search cards:</Text>
                            <CardStatusWidget />
                        </Space>
                    </FilterContainer>
                    {hasGetFraudRulesReport && (
                        <Button type="primary" onClick={() => onClickReport()}>
                            Fraud rules report
                        </Button>
                    )}
                    <Button type="primary" onClick={() => setVisible(true)}>
                        Flag transaction
                    </Button>
                </Row>
            </Card>
            <Card
                defaultActiveTabKey={InvestigationStatus.NEW}
                tabList={tabList}
                onTabChange={(status) => {
                    setInvestigationStatus([status as InvestigationStatus])
                }}
            >
                <PointerContainer>
                    <Table
                        pagination={{
                            pageSize: 50,
                            total: flagsPaginated?.totalCount,
                            onChange: (page, pageSize) => {
                                setPaginationOptions({
                                    pageSize: pageSize,
                                    pageOffset: page - 1,
                                })
                            },
                        }}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        loading={!flags}
                        columns={columns(investigationStatus[0])}
                        dataSource={flags ?? []}
                        rowKey={(record) => record.rows[0]?.flag.id ?? '1'}
                    />
                </PointerContainer>
            </Card>
            <Modal
                data-testid="flag-transaction-modal"
                title="Flag a transaction as confirmed fraud concern"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                destroyOnClose
            >
                <ManuallyFlagTransactionForm
                    statuses={Object.entries(DisplayInvestigationStatusFraud)}
                    onSubmit={(values) => onSubmit(values)}
                />
            </Modal>
            <Modal
                title="Transaction update status"
                open={multipleTransactionModalVisible}
                closable={false}
                footer={[
                    <Button
                        key="failed"
                        id="copy-failed"
                        onClick={() => copyTransactions(failedTransactions)}
                        disabled={!failedTransactions.length}
                    >
                        Copy failed
                    </Button>,
                    <Button
                        key="successful"
                        id="copy-successful"
                        onClick={() => copyTransactions(successfulTransactions)}
                        disabled={!successfulTransactions.length}
                    >
                        Copy successful
                    </Button>,
                    <Button
                        type="primary"
                        key="close"
                        onClick={() => setMultipleTransactionModalVisible(false)}
                    >
                        Close
                    </Button>,
                ]}
            >
                <ProcessList
                    allItems={allMultipleTransactions}
                    currentItem={currentTransaction}
                    failedItems={failedTransactions}
                    successfulItems={successfulTransactions}
                />
            </Modal>
        </PageContentLayout>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

const FilterContainer = styled.div`
    display: flex;
    gap: ${spacing.space48};
`

export default Fraud
