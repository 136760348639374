import { LockOutlined, SearchOutlined, UnlockOutlined, WarningOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    Layout,
    Popconfirm,
    Select,
    Space,
    Switch,
    Tag,
    Typography,
} from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { FC, useCallback, useEffect, useState } from 'react'
import type { GroupLevelPermission } from 'types/groupPermissions'
import { highSecurityPermissions } from 'types/high-security-permissions'
import { useGroup } from '../api/use-groups'

import { bff } from '../bff'

const tagRender = (props: CustomTagProps) => {
    const { value } = props
    return (
        <Tag
            icon={highSecurityPermissions.includes(value) && <WarningOutlined color="red" />}
            color={highSecurityPermissions.includes(value) ? 'red' : ''}
        >
            {value}
        </Tag>
    )
}

const useFilter = (permissions?: GroupLevelPermission[]) => {
    const [filterQuery, setFilterQuery] = useState<string | undefined>()
    const [filteredItems, setFilteredItems] = useState<string[]>()

    useEffect(() => {
        if (filterQuery) {
            const items = (permissions || [])?.map((permission) => permission.resourceId)
            setFilteredItems(
                items?.filter((permission) =>
                    permission.toLowerCase().includes(filterQuery?.toLowerCase())
                )
            )
        }
    }, [permissions, filterQuery])

    return { setFilterQuery, filterQuery, filteredItems }
}

const PermissionsTag: FC<{
    resourceId: string
    onClose: () => void
    opacity: number
    closable: boolean
}> = ({ resourceId, onClose, opacity, closable }) => {
    return (
        <Tag
            closable={closable}
            onClose={onClose}
            icon={highSecurityPermissions.includes(resourceId) && <WarningOutlined color="red" />}
            color={highSecurityPermissions.includes(resourceId) ? 'red' : ''}
            style={{
                marginBottom: '0.5rem',
                opacity,
            }}
        >
            {resourceId}
        </Tag>
    )
}

export const GroupPermissions = () => {
    const [teamName, setTeamName] = useState<string | undefined>()
    const [teamId, setTeamId] = useState<string | undefined>()
    const [pendingPermissions, setPendingPermissions] = useState<string[]>([])
    const [availablePermissions, setAvailablePermissions] = useState<string[]>()
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [shouldConfirmAddingPermissions, setShouldConfirmAddingPermissions] = useState(false)

    const { data, addPermissions, removePermissions } = useGroup(teamId)
    const { setFilterQuery, filteredItems, filterQuery } = useFilter(data?.permissions)

    const { data: oktaPermissions } = bff.permissions.listAllPermissions.useQuery(
        { params: { include_deleted: false } },
        { refetchOnWindowFocus: false }
    )

    const { data: oktaUserGroups } = bff.permissions.getUserGroups.useQuery()

    useEffect(() => {
        const existingPermissions = data?.permissions.map((permission) => permission.resourceId)

        const oktaPermissionTags = oktaPermissions?.map((permission) => permission.tag)

        setAvailablePermissions(
            oktaPermissionTags?.filter(
                (oktaPermission) => !existingPermissions?.includes(oktaPermission)
            )
        )
    }, [teamId, data, data?.permissions, oktaPermissions])

    const handleAddPermissions = () => {
        addPermissions(pendingPermissions)
        setPendingPermissions([])
        setShouldConfirmAddingPermissions(false)
    }

    const handleAddPendingPermissions = () => {
        const pendingHighSecurityPermissions = pendingPermissions.filter((h) =>
            highSecurityPermissions.includes(h)
        )
        if (pendingHighSecurityPermissions.length > 0) {
            setShouldConfirmAddingPermissions(true)
            return
        }
        handleAddPermissions()
    }

    const getFilterStyling = useCallback(
        (resourceId: string) => {
            if (filterQuery) {
                if (filteredItems?.includes(resourceId)) {
                    return 1
                }
                if (!filteredItems?.includes(resourceId)) {
                    return 0.3
                }
            }
            return 1
        },
        [filterQuery, filteredItems]
    )

    return (
        <PageContentLayout>
            <PageHeader title="Manage group permissions" />
            <Layout>
                <Layout.Content>
                    <Card style={{ marginBottom: '1rem' }} type="inner" title="Select a team">
                        <Form layout="vertical">
                            <Form.Item
                                label="Select team"
                                name="team-select"
                                help={
                                    <Typography.Text type="secondary">
                                        Can't see the team you're looking for? Contact{' '}
                                        <Typography.Link
                                            href="https://getpleo.slack.com/app_redirect?channel=team-business-operations-enablement"
                                            target="_blank"
                                        >
                                            #team-business-operations-enablement
                                        </Typography.Link>
                                    </Typography.Text>
                                }
                            >
                                <Select
                                    showSearch
                                    options={oktaUserGroups?.map((group: any) => ({
                                        value: group.id,
                                        label: group.name,
                                    }))}
                                    optionFilterProp="label"
                                    style={{ width: '40rem' }}
                                    onSelect={(_, record: any) => {
                                        setTeamName(record.label)
                                        setTeamId(record.value)
                                    }}
                                    allowClear
                                    onClear={() => {
                                        setTeamName(undefined)
                                        setTeamId(undefined)
                                    }}
                                    value={teamId}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                    <Card style={{ marginBottom: '1rem' }} type="inner" title="Add permissions">
                        <Space direction="horizontal">
                            <Select
                                data-testid="permissions-select"
                                mode="multiple"
                                style={{ width: '40rem' }}
                                value={pendingPermissions}
                                onChange={(permissions) => setPendingPermissions(permissions)}
                                options={availablePermissions
                                    ?.sort((a, b) => (a > b ? 1 : -1))
                                    .map((p) => ({
                                        value: p,
                                        label: (
                                            <Typography.Text>
                                                {p}{' '}
                                                {highSecurityPermissions.includes(p) && (
                                                    <Tag color="red" icon={<WarningOutlined />}>
                                                        High Security
                                                    </Tag>
                                                )}
                                            </Typography.Text>
                                        ),
                                    }))}
                                disabled={!teamId}
                                showSearch
                                allowClear
                                onClear={() => setPendingPermissions([])}
                                tagRender={tagRender}
                            />
                            <Popconfirm
                                title={
                                    <Typography.Text
                                        style={{ display: 'inline-block', maxWidth: '20rem' }}
                                    >
                                        You're adding sensitive permissions to this group. Please
                                        ensure you understand what they allow before proceeding.
                                    </Typography.Text>
                                }
                                onConfirm={() => handleAddPermissions()}
                                onCancel={() => setShouldConfirmAddingPermissions(false)}
                                okText="Add high security permissions"
                                open={shouldConfirmAddingPermissions}
                            >
                                <Button
                                    onClick={() => handleAddPendingPermissions()}
                                    disabled={
                                        pendingPermissions?.length === 0 ||
                                        !teamId ||
                                        shouldConfirmAddingPermissions
                                    }
                                    type="primary"
                                >
                                    Add permissions
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Card>
                    {teamName && (
                        <Card type="inner" title={`Permissions for ${teamName}`}>
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Input
                                        type="text"
                                        onChange={(e) => setFilterQuery(e.target.value)}
                                        placeholder="Search assigned permissions"
                                        prefix={<SearchOutlined />}
                                        style={{
                                            width: '40rem',
                                        }}
                                        allowClear
                                    />
                                    <Space>
                                        <Switch
                                            unCheckedChildren={<LockOutlined />}
                                            checkedChildren={<UnlockOutlined />}
                                            onChange={(e) => setIsEditing(e)}
                                        />
                                        Edit mode
                                    </Space>
                                </Space>
                                <Divider style={{ margin: '0.5rem 0' }} />
                                <Space wrap data-testid="permission-tags">
                                    {data?.permissions
                                        .sort((a: any, b: any) =>
                                            a.resourceId > b.resourceId ? 1 : -1
                                        )
                                        .map((permission: any) => (
                                            <PermissionsTag
                                                key={permission.resourceId}
                                                resourceId={permission.resourceId}
                                                closable={isEditing}
                                                onClose={() =>
                                                    removePermissions([permission.resourceId])
                                                }
                                                opacity={getFilterStyling(permission.resourceId)}
                                            />
                                        ))}
                                    {data?.permissions.length === 0 && (
                                        <Typography.Text type="secondary">{`No permissions assigned for ${teamName}.`}</Typography.Text>
                                    )}
                                </Space>
                            </Space>
                        </Card>
                    )}
                </Layout.Content>
            </Layout>
        </PageContentLayout>
    )
}
