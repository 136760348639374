import { PageHeader } from '@ant-design/pro-layout'
import { CopyOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Descriptions, Dropdown, Space, Tag, Typography, Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyAddress from 'components/company-address/company-address'
import { getEmojiFlag } from 'countries-list'
import { getHighestVelocity } from 'pages/customer-success/utils'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { onDeleteOffboarding } from 'components/company-offboarding/cancel-offboarding-confirmation'
import UserList from 'components/users-list/users-list'
import BillingAdmin from 'pages/customer-success/company/billing/billing-admin/billing-admin'
import PartnersDropdown from '../partners/partners-dropdown'
import BookkeepersDropdown from '../bookkeepers/bookkeepers-dropdown'
import { RelatedByOrganization } from 'pages/compliance/company/shared/related-by-organization/relatated-by-organization'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import styled from 'styled-components'
import { fontSize } from 'theme/tokens'
import { useGetCompanyPartners, useGetPartnerByOwnCompanyId } from 'services/calypso/partner'
import type { AdminCompany } from 'types/deimos-company'
import type { CompanyPartner } from 'types/partner-portal'
import type { Bookkeeper, Employee } from 'types/employee'
import type { CurrentStatus } from 'types/status'
import type { SubscriptionListResponse } from 'bff/moons/generated/beyond'
import type { CompanyOffboardingStatus } from '@pleo-io/deimos'

import { bff } from '../bff'

const { Link, Text } = Typography
const { confirm } = Modal

interface Props {
    billingSubscription: SubscriptionListResponse
    company: AdminCompany
    status: CurrentStatus
    companyOffboardingStatus: CompanyOffboardingStatus | undefined
    revalidateOffboardingStatus: () => void
    selfOnboarding?: boolean
    setExportExpenseModalVisible: any
    offboardingModalActive: boolean
    setOffboardingModalActive: any
    offboardingText: string
    exportExpenseModalVisible: boolean
}

export const CustomerSuccessPageHeader = ({
    company,
    billingSubscription,
    selfOnboarding,
    revalidateOffboardingStatus,
    setExportExpenseModalVisible,
    offboardingModalActive,
    setOffboardingModalActive,
    offboardingText,
    companyOffboardingStatus,
    exportExpenseModalVisible,
}: Props) => {
    const navigate = useNavigate()

    const goToOwnPartner = (partnerId: string) =>
        navigate(`/customer-success/partners/${partnerId}`)
    const onPartnerClick = (partner: CompanyPartner) =>
        navigate(`/customer-success/partners/${partner.id}`)
    const onUserClick = (employee: Employee | Bookkeeper) =>
        navigate(`/customer-success/users/${employee.userId}`)
    const onClickCompany = (companyId: string) =>
        navigate(`/customer-success/companies/${companyId}`)

    const hasInitiateOffboarding = useHasPermissions(['initiate-offboarding'])
    const hasExportExpenses = useHasPermissions(['admin-expenses'])
    const { id } = useParams()

    const { data: billingAddress } = bff.pageHeader.getCompanyInfo.useQuery({
        companyId: company.id,
    })

    const { data: admins } = bff.pageHeader.getCompanyAdmins.useQuery({
        companyId: company.id,
    })

    const { data: partners, error: partnersError } = useGetCompanyPartners(id)
    const { data: allBookkeepers, isError: isBookkeepersError } =
        bff.pageHeader.getAllBookkeepers.useQuery({ companyId: company.id })
    const anyPartnersError = Boolean(partnersError)
    const { data: ownPartner } = useGetPartnerByOwnCompanyId(company?.id)

    const emojiFlag = getEmojiFlag(company.address.country || 'GB')
    const currentPlanType = billingSubscription?.data?.[0].platformRatePlan?.name
    const highestVelocity = getHighestVelocity(company.status)
    const velocity = highestVelocity
        ? highestVelocity.toUpperCase()
        : "Company hasn't been verified yet"

    const areBillingAddressesDifferent =
        company?.address?.addressLine1 !== billingAddress?.addressLine1

    const goBack = () => navigate(-1)

    const confirmCancelOffboarding = () => {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Cancel offboarding?',
            content: `Are you sure you want to cancel the offboarding of ${company.name}`,
            okButtonProps: { id: 'confirm-offboarding' },
            onOk: async () => {
                await onDeleteOffboarding(company.id)
                revalidateOffboardingStatus()
            },
        })
    }

    const getMenuItems = () => {
        const menuItems: ItemType[] = []

        if (hasExportExpenses) {
            menuItems.push({
                key: '1',
                onClick: () => setExportExpenseModalVisible(!exportExpenseModalVisible),
                label: 'Export expenses',
            })
        }
        if (hasInitiateOffboarding) {
            menuItems.push({
                key: '2',
                onClick: () => setOffboardingModalActive(!offboardingModalActive),
                label: offboardingText,
            })
        }
        if (hasInitiateOffboarding && !!companyOffboardingStatus) {
            menuItems.push({
                key: '3',
                onClick: () => confirmCancelOffboarding(),
                danger: true,
                label: 'Cancel offboarding',
            })
        }

        return menuItems
    }
    return (
        <PageHeader
            ghost={false}
            onBack={goBack}
            title={
                <Space>
                    {emojiFlag}
                    <Text
                        copyable={{
                            icon: [<CopyIcon key="copy-icon" />],
                        }}
                        style={{ whiteSpace: 'normal' }}
                    >
                        {company.name}
                    </Text>
                </Space>
            }
            tags={
                <>
                    {companyOffboardingStatus ? (
                        <Tag color="blue">
                            <Link href={`/customer-success/offboarding/${company.id}`}>
                                Offboarding
                            </Link>
                        </Tag>
                    ) : null}
                </>
            }
            extra={[
                <Button
                    key="customer-success-customers-button"
                    onClick={() => navigate(`/customer-success/company-360/${company.id}`)}
                >
                    Customer 360 View
                </Button>,
                (hasInitiateOffboarding || hasExportExpenses) && (
                    <Dropdown
                        menu={{
                            items: getMenuItems(),
                        }}
                        key="customer-success-actions-dropdown"
                    >
                        <Button data-testid="actions-button">
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                ),
                company?.organizationId && (
                    <RelatedByOrganization
                        organizationId={company?.organizationId}
                        entityId={company?.id}
                        onChangeEntity={onClickCompany}
                        key="customer-success-organization-relation"
                    />
                ),
            ]}
        >
            <Descriptions column={4} size="small">
                <Descriptions.Item label="Company ID" span={2}>
                    <Text copyable>{company.id}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Plan">{currentPlanType}</Descriptions.Item>
                <Descriptions.Item label="Velocity">{velocity}</Descriptions.Item>
                <Descriptions.Item label="Own partner">
                    {ownPartner ? (
                        <Link onClick={() => goToOwnPartner(ownPartner.id)}>{ownPartner.name}</Link>
                    ) : (
                        <Text>N/A</Text>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={1}>
                    <CompanyAddress address={company.address} />
                </Descriptions.Item>
                {billingAddress && areBillingAddressesDifferent && (
                    <Descriptions.Item label="Billing Address" span={1}>
                        <AddressContainer>
                            <Text>
                                {billingAddress.addressLine1 ?? '-'}
                                {billingAddress.addressLine2
                                    ? ` ${billingAddress.addressLine2} `
                                    : ''}
                            </Text>
                            <Text>
                                {billingAddress.city ?? '-'}
                                {billingAddress.region ? `, ${billingAddress.region} ` : ''}
                            </Text>
                            <Text>
                                {billingAddress.postalCode ? `${billingAddress.postalCode}, ` : ''}
                                {billingAddress.country ?? '-'}
                            </Text>
                        </AddressContainer>
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="Admins">
                    <UserList users={admins} onUserClick={onUserClick} />
                </Descriptions.Item>
                <Descriptions.Item label="Self Onboarding">
                    <Tag
                        color={
                            selfOnboarding !== undefined
                                ? selfOnboarding
                                    ? 'green'
                                    : 'red'
                                : 'black'
                        }
                    >
                        {selfOnboarding !== undefined
                            ? selfOnboarding.toString().toLocaleUpperCase()
                            : 'UNKNOWN'}
                    </Tag>
                </Descriptions.Item>
                <Descriptions.Item>
                    <Space direction="vertical">
                        <BookkeepersDropdown
                            onBookkeeperClick={onUserClick}
                            bookkeepers={allBookkeepers ?? []}
                            bookkeepersError={isBookkeepersError}
                        />
                        <PartnersDropdown
                            onPartnerClick={onPartnerClick}
                            partners={partners ?? []}
                            partnersError={anyPartnersError}
                        />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Billing Contact">
                    <BillingAdmin companyId={company.id} billingAddress={billingAddress} />
                </Descriptions.Item>
                <Descriptions.Item label="Vitally">
                    <a
                        href={`https://pleo.vitally.io/customers/${company.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Detail
                    </a>
                </Descriptions.Item>
            </Descriptions>
        </PageHeader>
    )
}

const AddressContainer = styled.address`
    display: grid;
`

const CopyIcon = styled(CopyOutlined)`
    font-size: ${fontSize.size14};
    display: flex;
    justify-content: 'center';
`
