export const encryptQueryAndReturnMetabaseURL = (queryObject: any) => {
    const unencryptedString = JSON.stringify(queryObject)
    const buf = Buffer.from(unencryptedString, 'utf8')
    return `https://metabase.pleo.io/question#${buf.toString('base64')}`
}

export const createURLForDeimosStorage = (companyId: string) => {
    const newUnencryptedQuery = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2743,
                filter: ['and', ['=', ['field-id', 42540], `${companyId}`]],
                'order-by': [['desc', ['field-id', 42544]]],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }
    return encryptQueryAndReturnMetabaseURL(newUnencryptedQuery)
}

export const createURLForPhobosQuery = (companyId: string) => {
    const query = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2786,
                filter: ['and', ['=', ['field', 42932, null], `${companyId}`]],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForDeimosExpense = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 8,
            query: {
                'source-table': 2739,
                filter: ['and', ['=', ['field-id', 42344], `${companyId}`]],
                'order-by': [['desc', ['field-id', 42362]]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForVirtualAccountNumber = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 8,
            query: {
                'source-table': 3293,
                filter: ['and', ['=', ['field', 47151, null], `${companyId}`]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }
    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForAllEmployees = (companyId: string) => {
    return `https://metabase.pleo.io/question/1106?company_id=${companyId}`
}

export const createURLForEmployeeQuery = (companyId: string) => {
    const query = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2747,
                filter: [
                    'and',
                    ['=', ['field', 42502, null], `${companyId}`],
                    ['=', ['field', 42511, null], 'arnon@pleo.io'],
                ],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForCupidWalletLoad = (companyId: string) => {
    const query = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2863,
                filter: [
                    'and',
                    ['=', ['fk->', ['field-id', 43472], ['field-id', 42925]], `${companyId}`],
                ],
                'order-by': [['desc', ['field-id', 43475]]],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForCupidInvoice = (companyId: string) => {
    const query = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2783,
                'order-by': [['desc', ['field', 42928, null]]],
                filter: ['and', ['=', ['field', 42925, null], `${companyId}`]],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForExportHistory = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 8,
            query: {
                'source-table': 2839,
                filter: ['and', ['=', ['field', 43149, null], `${companyId}`]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForDeimosCardOrder = (companyId: string) => {
    const query = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 2737,
                filter: ['and', ['=', ['field', 42382, null], `${companyId}`]],
            },
            database: 8,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForCompanyReviewStructure = (companyId: string) => {
    return `https://metabase.pleo.io/question/8362?company_id_=${companyId}`
}

export const createURLForTrustedBeneficiaries = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 18,
            query: {
                'source-table': 4525,
                filter: ['and', ['=', ['field', 70684, null], `${companyId}`]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForBalanceMismatch = (companyId: string) => {
    return `https://metabase.pleo.io/question/10495-mimas-balance-snapshot-by-companyid?company_id=${companyId}`
}

export const createURLForLimitsLog = (companyId: string) => {
    const query = {
        name: null,
        dataset_query: {
            query: {
                'source-table': 3526,
                filter: ['and', ['=', ['field', 51383, null], `${companyId}`]],
            },
            type: 'query',
            database: 18,
        },
        display: 'table',
        visualization_settings: {},
        original_card_id: null,
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForWalletWithdrawal = (companyId: string) => {
    const query = {
        name: null,
        dataset_query: {
            query: {
                'source-table': 5187,
                filter: ['and', ['=', ['field', 84528, null], `${companyId}`]],
            },
            type: 'query',
            database: 42,
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForOffboarding = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 42,
            query: {
                'source-table': 5588,
                filter: ['and', ['=', ['field', 91341, null], `${companyId}`]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForCupidPayer = (companyName: string) => {
    const query = {
        dataset_query: {
            database: 8,
            query: {
                'source-table': 2891,
                filter: [
                    'and',
                    [
                        'contains',
                        ['field', 43742, null],
                        `${companyName || ''}`,
                        { 'case-sensitive': false },
                    ],
                ],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}

export const createURLForDeimosTrasnactions = (companyId: string) => {
    const query = {
        dataset_query: {
            database: 8,
            query: {
                'source-table': 2804,
                filter: ['and', ['=', ['field', 42790, null], `${companyId}`]],
                'order-by': [['desc', ['field', 42799, null]]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }

    return encryptQueryAndReturnMetabaseURL(query)
}
