import type { FC } from 'react'
import { Table, Typography, Button, Modal } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import type { SortOrder } from 'antd/lib/table/interface'
import ReactJson from 'react-json-view'
import dayjs from 'packages/dayjs'

import { ActionsContainer } from 'components/layout-containers'
import ErrorState from 'components/error-state/error-state'

import { bff } from '../bff'
import type { EventAudit } from 'bff/moons/generated/hydra'

const { Text } = Typography
const DEFAULT_ROW_COUNT = 10

interface Props {
    eventAuditLogs?: EventAudit[]
    fetch: () => void
    companyId: string
    error: boolean
}

const EventAuditLogs: FC<React.PropsWithChildren<Props>> = ({ eventAuditLogs, fetch, error }) => {
    const needsPagination = eventAuditLogs && eventAuditLogs.length > DEFAULT_ROW_COUNT
    const columns: ColumnProps<EventAudit>[] = [
        {
            title: 'Date',
            dataIndex: 'occurredAt',
            render: (occurredAt) => <Text>{dayjs(occurredAt).format('ll')}</Text>,
            sorter: (a: EventAudit, b: EventAudit) => dayjs(a.occurredAt).diff(dayjs(b.occurredAt)),
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filters: Array.from(new Set(eventAuditLogs?.map((e) => e.type)))?.map((value) => ({
                value: value ? value : '',
                text: value,
            })),
            onFilter: (value, event) => event.type === value,
        },
        {
            title: 'Sub type',
            dataIndex: 'subType',
            filters: Array.from(new Set(eventAuditLogs?.map((e) => e.subType)))?.map((value) => ({
                value: value ? value : '',
                text: value,
            })),
            onFilter: (value, event) => event.subType === value,
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'System',
            dataIndex: 'sourceSystem',
            filters: Array.from(new Set(eventAuditLogs?.map((e) => e.sourceSystem)))?.map(
                (value) => ({
                    value: value ? value : '',
                    text: value,
                })
            ),
            onFilter: (value, event) => event.sourceSystem === value,
        },
        {
            title: 'User',
            dataIndex: 'sourceUser',
            filters: Array.from(new Set(eventAuditLogs?.map((e) => e.sourceUser)))?.map(
                (value) => ({
                    value: value ? value : '',
                    text: value,
                })
            ),
            onFilter: (value, event) => event.sourceUser === value,
        },
        {
            title: 'Actions',
            render: ({ extraData }) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        onClick={() => {
                            Modal.confirm({
                                centered: true,
                                title: 'Event Audit Extra Data',
                                width: '600px',
                                content: (
                                    <ReactJson
                                        src={JSON.parse(extraData)}
                                        name={false}
                                        displayDataTypes={false}
                                    />
                                ),
                            })
                        }}
                    >
                        More information
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    if (error) {
        return <ErrorState onRetry={fetch} />
    }

    return (
        <Table
            rowKey="id"
            dataSource={eventAuditLogs}
            columns={columns}
            pagination={needsPagination && { pageSize: DEFAULT_ROW_COUNT }}
        />
    )
}

const EventAuditLogsContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const { data, error, refetch } = bff.getAuditLogs.useQuery({
        companyId: companyId,
        from: undefined,
        to: undefined,
    })

    return (
        <EventAuditLogs
            eventAuditLogs={data}
            companyId={companyId}
            fetch={refetch}
            error={!!error}
        />
    )
}

export default EventAuditLogsContainer
