import { useEffect, useReducer } from 'react'

const LOCAL_STORAGE_KEY = 'localUserPreferences'

const userPreferencesReducer = (
    state: UserPreferences = initialState,
    action: UserPreferencesActions
): UserPreferences => {
    switch (action.type) {
        case 'SET_COLOR_SCHEME':
            return { ...state, colorScheme: action.payload }
        case 'SET_LANDING_PAGE':
            return { ...state, landingPage: action.payload }
        case 'SET_COMPLIANCE_VIEW':
            return { ...state, complianceView: action.payload }
        default:
            return state
    }
}

const initialState: UserPreferences = {
    landingPage: 'customer-success',
    colorScheme: 'light',
    complianceView: 'tabs',
}

const initialiser = (initialValue = initialState) =>
    localStorage.getItem(LOCAL_STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '')
        : initialValue

export const useUserPreferences = () => {
    const [userPreferences, dispatch] = useReducer(
        userPreferencesReducer,
        initialState,
        initialiser
    )

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userPreferences))
    }, [userPreferences])

    return { userPreferences, dispatch }
}

// Types

export interface UserPreferences {
    landingPage: LandingPages
    colorScheme: ColorScheme
    complianceView: ComplianceView
}

export type UserPreferencesActions =
    | { type: 'SET_COLOR_SCHEME'; payload: ColorScheme }
    | { type: 'SET_LANDING_PAGE'; payload: LandingPages }
    | { type: 'SET_COMPLIANCE_VIEW'; payload: ComplianceView }

export type LandingPages = 'customer-success' | 'compliance'
export type ColorScheme = 'light' | 'dark' | 'system'
type ComplianceView = 'collapse' | 'tabs'
