import dayjs, { Dayjs } from 'packages/dayjs'
import type { FieldProps } from 'formik'

import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker, {
    PickerDateProps,
    RangePickerProps as DayjsRangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import type { PickerProps } from 'antd/es/date-picker/generatePicker'

import type { FormikFieldProps } from '../FieldProps'
import Field from '../field/field'

// Adapted from https://4x.ant.design/docs/react/replace-moment
// @ts-ignore
export const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const {
    RangePicker: DayjsRangePicker,
    MonthPicker: DayjsMonthPicker,
    WeekPicker: DayjsWeekPicker,
} = DayjsDatePicker

export const DatePicker = ({ name, validate, onChange, fast, ...restProps }: DatePickerProps) => (
    <Field name={name} validate={validate} fast={fast}>
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
            <DayjsDatePicker
                value={value ? dayjs(value) : undefined}
                onChange={(date, dateString) => {
                    setFieldValue(name, date ? date.toISOString() : null)
                    setFieldTouched(name, true, false)
                    onChange && onChange(date, dateString)
                }}
                {...restProps}
            />
        )}
    </Field>
)

// eslint-disable-next-line react/display-name
DatePicker.MonthPicker = ({ name, validate, onChange, ...restProps }: MonthPickerProps) => (
    <Field name={name} validate={validate}>
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
            <DayjsMonthPicker
                value={value ? dayjs(value) : undefined}
                onChange={(date, dateString) => {
                    setFieldValue(name, date ? date.toISOString() : null)
                    setFieldTouched(name, true, false)
                    onChange && onChange(date, dateString)
                }}
                {...restProps}
            />
        )}
    </Field>
)

// eslint-disable-next-line react/display-name
DatePicker.RangePicker = ({ name, validate, onChange, ...restProps }: RangePickerProps) => (
    <Field name={name} validate={validate}>
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
            <DayjsRangePicker
                name={name}
                value={value}
                onChange={(dates, dateStrings) => {
                    setFieldValue(name, dates)
                    setFieldTouched(name, true, false)
                    onChange && onChange(dates, dateStrings)
                }}
                {...restProps}
            />
        )}
    </Field>
)

// eslint-disable-next-line react/display-name
DatePicker.WeekPicker = ({ name, validate, onChange, ...restProps }: WeekPickerProps) => (
    <Field name={name} validate={validate}>
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
            <DayjsWeekPicker
                name={name}
                value={value}
                onChange={(date, dateString) => {
                    setFieldValue(name, date)
                    setFieldTouched(name, true, false)
                    onChange && onChange(date, dateString)
                }}
                {...restProps}
            />
        )}
    </Field>
)

export type DatePickerProps = PickerProps<Dayjs> & FormikFieldProps
export type WeekPickerProps = FormikFieldProps & Omit<PickerDateProps<Dayjs>, 'picker'>
export type RangePickerProps = FormikFieldProps & DayjsRangePickerProps<Dayjs>
export type MonthPickerProps = FormikFieldProps &
    Omit<PickerDateProps<Dayjs>, 'picker'> & { keepOffset?: boolean }

export default DatePicker
