import { FormAction } from '../enums'
import { UpdateMessage } from '../../entitlement-dashboard.styles'
import { message, Modal } from 'antd'
import { isBeforeToday } from '../plan-table/utils'
import { useCallback } from 'react'
import type { FormikStructure, PlanTableProps } from '../types'
import { generateUpdatedPlansFromForm } from '../plan-table/helpers'
import PlanChanges from '../plan-changes'
import { Formik } from 'formik'
import type PlanTable from '../plan-table'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { bff } from '../../bff'

type PlanTableFormikWrapperProps = Pick<
    PlanTableProps,
    'header' | 'isEditing' | 'plans' | 'currentView'
> & {
    onSubmit: () => void
    onCancel: () => void
}

const withFormikWrapper = (TableComponent: typeof PlanTable) =>
    function TableFormikWrapper({
        header,
        isEditing,
        plans,
        currentView,
        onSubmit,
        onCancel,
    }: PlanTableFormikWrapperProps) {
        const [modal, contextHolder] = Modal.useModal()
        const { mutateAsync: updatePlans } = bff.plans.updatePlans.useMutation()

        const getModalContent = (
            action: FormAction,
            onDatePicked: (date: unknown, dateString: string) => void
        ) => {
            const isSubmit = action === FormAction.Submit

            return {
                title: isSubmit ? 'Apply changes' : 'Schedule changes',
                okText: isSubmit ? 'Submit' : 'Schedule',
                content: isSubmit ? (
                    <UpdateMessage>
                        <p>New entitlements will take effect immediately.</p>
                    </UpdateMessage>
                ) : (
                    <UpdateMessage>
                        <p>
                            Select the date and time when the selected changes to entitlements will
                            take effect.
                        </p>
                        <DayjsDatePicker
                            onChange={onDatePicked}
                            style={{ width: '100%', maxWidth: '256px' }}
                            disabledDate={isBeforeToday}
                        />
                    </UpdateMessage>
                ),
            }
        }

        const handleSubmit = useCallback(
            async (formValues: FormikStructure, action: FormAction, resetForm: () => void) => {
                const bulkUpdatePlans = generateUpdatedPlansFromForm(formValues, plans, currentView)

                const updateStartDate = (date: unknown, dateString: string) => {
                    bulkUpdatePlans.forEach((plan) => (plan.startDate = dateString))
                }

                const { title, okText, content } = getModalContent(action, updateStartDate)

                modal.confirm({
                    title,
                    centered: true,
                    width: 520,
                    icon: undefined,
                    closable: true,
                    content: (
                        <div>
                            {content}
                            <PlanChanges
                                basePlans={plans}
                                updatedPlans={bulkUpdatePlans}
                                currentView={currentView}
                            />
                        </div>
                    ),
                    okText,
                    okButtonProps: {
                        id: 'confirm-entitlement-changes',
                        disabled: bulkUpdatePlans.length === 0,
                    },
                    onOk: async () => {
                        try {
                            await updatePlans(bulkUpdatePlans)
                            message.success('Successfully changed plan!')
                            onSubmit?.()
                            resetForm()
                        } catch (e) {
                            message.error(`Failed to change plan: ${(e as Error).message}`)
                        }
                    },
                })
            },
            [currentView, modal, onSubmit, plans, updatePlans]
        )

        const submitCallback = useCallback(
            (formValues: FormikStructure, formAction: FormAction, resetForm: () => void) =>
                handleSubmit(formValues, formAction, resetForm),
            [handleSubmit]
        )

        return (
            <>
                {contextHolder}
                <Formik initialValues={{}} onSubmit={() => {}}>
                    <TableComponent
                        plans={plans}
                        currentView={currentView}
                        header={header}
                        isEditing={isEditing}
                        onSubmit={submitCallback}
                        onCancel={onCancel}
                    />
                </Formik>
            </>
        )
    }

export default withFormikWrapper
