import type { FC } from 'react'
import { Form, Formik } from 'formik'
import { Divider } from 'antd'
import { FormItem, SubmitButton } from 'formik-antd'
import styled from 'styled-components'

import type { Person } from 'types/styx'
import { Input } from 'components/input/input'
import { DaySelect, MonthSelect, YearSelect } from 'components/date-of-birth-pickers'
import AddressSearch from 'components/address-search/address-search'
import CountrySelect from 'components/country-select/country-select'
import { validationSchema, FormValues } from './validation-schema'

import { spacing } from 'theme/tokens'

import nationalities from 'i18n-nationality'
import 'i18n-nationality/langs/en.json'
import CitizenshipsSelect from 'components/citizenships-select/citizenships-select'
import { filterCitizenships, isNotDueDiligenceAgentInput } from 'utils/kyc-citizenship'

nationalities.registerLocale(require('i18n-nationality/langs/en.json'))

interface Props {
    onSubmit: (values: FormValues) => Promise<void>
    person: Person | null
}

const EditPersonForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit, person }) => {
    if (!person) {
        return null
    }

    const {
        name,
        address,
        dateOfBirth = {},
        registryId = '',
        nationalId = '',
        kycNationality,
        kycCitizenships,
    } = person
    return (
        <Formik
            initialTouched={{
                postalCode: true,
                country: true,
            }}
            initialValues={{
                givenName: name.givenName ?? '',
                middleName: name.middleName ?? '',
                familyName: name.familyName ?? '',
                registryId,
                nationalId,
                day: dateOfBirth?.day ?? null,
                month: dateOfBirth?.month ?? null,
                year: dateOfBirth?.year ?? null,
                nationality: kycNationality?.nationality
                    ? kycNationality.nationality.length > 2
                        ? nationalities.getAlpha2Code(kycNationality.nationality, 'en')
                        : kycNationality.nationality
                    : null,
                country: address?.country ?? '',
                locality: address?.locality ?? '',
                postalCode: address?.postalCode ?? '',
                houseNumber: address?.houseNumber ?? '',
                street: address?.street ?? '',
                floor: address?.floor ?? '',
                region: address?.region ?? '',
                citizenships:
                    kycCitizenships?.flatMap((citizenships) => citizenships.citizenships) ?? [],
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <FormLayout>
                            <Input name="givenName" label="First name" />
                            <Input name="middleName" label="Middle name(s)" />
                            <Input name="familyName" label="Last name" />
                        </FormLayout>
                        <FormLayout>
                            <Input name="nationalId" label="National Id" />
                            <Input name="registryId" label="Registry Id" />
                            <FormItem name="nationality">
                                <Label>Nationality</Label>
                                <CountrySelect
                                    name="nationality"
                                    placeholder="Nationality"
                                    showSearch
                                />
                            </FormItem>
                        </FormLayout>
                        <FormItem name="citizenships" hasFeedback colon={false}>
                            <Label>Citizenships</Label>
                            <CitizenshipsSelect
                                name="citizenships"
                                placeholder="Select citizenships"
                                showSearch
                                disabledValues={filterCitizenships(
                                    person,
                                    isNotDueDiligenceAgentInput
                                ).map((citizenship) => citizenship.citizenship)}
                                data-testid="citizenships-select"
                            />
                        </FormItem>
                        <Label>Date of birth</Label>
                        <FormLayout>
                            <DaySelect name="day" placeholder="Day" />
                            <MonthSelect name="month" placeholder="Month" />
                            <YearSelect name="year" placeholder="Year" />
                        </FormLayout>
                        <Divider />
                        <AddressSearchContainer>
                            <Label>Address search</Label>
                            <AddressSearch
                                country={values.country}
                                setAddress={(detailedAddress) => {
                                    for (const key in detailedAddress) {
                                        setFieldValue(
                                            key,
                                            detailedAddress[key as keyof typeof detailedAddress]
                                        )
                                    }
                                }}
                            />
                        </AddressSearchContainer>
                        <FormLayout>
                            <Input name="street" label="Street" />
                            <Input name="houseNumber" label="House number" />
                            <Input name="floor" label="Flat number / floor" />
                            <Input name="locality" label="City / locality" />
                            <Input name="postalCode" label="Postal code" />
                            <Input name="region" label="Region" />
                            <FormItem name="country">
                                <Label>Country</Label>
                                <CountrySelect name="country" placeholder="Country" showSearch />
                            </FormItem>
                        </FormLayout>
                        <FormControl>
                            <SubmitButton data-testid="submit" type="primary">
                                Save
                            </SubmitButton>
                        </FormControl>
                    </Form>
                )
            }}
        </Formik>
    )
}

const FormLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0 ${spacing.space8};
`

const Label = styled.label`
    line-height: 32px;
`

const FormControl = styled.div`
    display: flex;
    justify-content: flex-end;
`

const AddressSearchContainer = styled.div`
    margin-bottom: ${spacing.space24};
`

export default EditPersonForm
