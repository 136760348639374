import { SourceOfFunds } from './source-of-funds'
import { CompanyActions } from './company-actions'
import { AML } from './aml'
import { Documents } from './documents'
import CompanyBalance from 'components/company-balance/company-balance'
import { TransactionOverview } from './transaction-overview'
import { DirectAgreement } from './directdebit-agreements'

export const ComplianceInfo = ({ companyId }: { companyId: string }) => {
    return (
        <>
            <TransactionOverview companyId={companyId} />
            <CompanyBalance companyId={companyId} />
            <SourceOfFunds companyId={companyId} />
            <AML companyId={companyId} />
            <Documents companyId={companyId} />
            <DirectAgreement companyId={companyId} />
            <CompanyActions companyId={companyId} />
        </>
    )
}
