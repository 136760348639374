import type { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { Button, Layout } from 'antd'

import BO_login_GTA from 'components/logo/Pleo_Animated_Logo.gif'

import styled from 'styled-components'
import LoginWithGoogle from './login-with-google/login-with-google'

import { useIsLoggedIn } from 'providers/auth-context'
import { getGoogleLoginUrl } from 'services/auth/auth'
import config from 'config'

const LoginPage: FC<React.PropsWithChildren<unknown>> = () => {
    const isLoggedIn = useIsLoggedIn()

    if (isLoggedIn) {
        return <Navigate to="/" />
    }

    return (
        <Page data-testid="login-page">
            <Wrapper>
                <LogoContainer>
                    <img src={BO_login_GTA} alt="Backoffice logo" />
                </LogoContainer>
                <CenterAlign>
                    <h1>Back Office</h1>
                </CenterAlign>
                {(window.location.host.includes('staging') ||
                    window.location.host.includes('dev') ||
                    process.env.NODE_ENV === 'development') && (
                    <LoginWithGoogle href={getGoogleLoginUrl()} />
                )}
                <Button
                    href={`${config.endpoints.auth}/saml/backoffice/login`}
                    type="primary"
                    size="large"
                >
                    Sign in with SAML
                </Button>
            </Wrapper>
        </Page>
    )
}

const Page = styled(Layout)`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
`

const LogoContainer = styled.div`
    height: 50vh;
    max-height: 470px;
    img {
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`

const CenterAlign = styled.div`
    text-align: center;
`

export default LoginPage
