import { SupplementaryUnloadStatus } from 'pages/compliance/wallet-unloads/utils'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'

export function getOutstandingInvoicesParam(
    status: WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
): boolean | undefined {
    let outstandingInvoices: boolean | undefined

    switch (status) {
        case WalletUnloadResponseStatus.NEW:
        case WalletUnloadResponseStatus.UNDER_INVESTIGATION:
            outstandingInvoices = false
            break
        case SupplementaryUnloadStatus.OUTSTANDING_INVOICES:
            outstandingInvoices = true
            break
        default:
            break
    }

    return outstandingInvoices
}

export const outstandingInvoicesQuery = (
    status: WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
) => {
    const outstandingInvoicesParam = getOutstandingInvoicesParam(status)

    return typeof outstandingInvoicesParam === 'undefined'
        ? ''
        : `&outstandingInvoices=${outstandingInvoicesParam}`
}
