import { Card } from 'antd'
import NarrativeContainer from '../shared/narrative'

export const Documents = ({ companyId }: { companyId: string }) => {
    return (
        <Card style={{ marginBottom: '1rem' }}>
            <NarrativeContainer
                label="Industry Narrative"
                companyId={companyId}
                category={'COMPANY_INDUSTRY'}
            />
        </Card>
    )
}
