import { RegistryBaseUrl } from 'types/company-registries'
import * as styx from 'types/styx'
import { getCompanyZipFileUrl } from 'services/deimos/styx-company/styx-company'
import { message } from 'antd'
import type { StyxCompany } from 'types/styx'
import type { CompanyResponseV2 } from 'bff/moons/generated/styx-v1'

export const getPersonName = (nameObj: styx.Person['name']) => Object.values(nameObj).join(' ')

export const getRoleNames = (roles?: styx.Role[]) => {
    if (!roles) {
        return 'Unknown role'
    }

    const deDupedRoles = Array.from(new Set(roles))
    return (
        deDupedRoles
            // Roles are always in uppercase, so we lowercase everything but the first letter unless it's UBO
            .map((role) =>
                role === styx.Role.UBO ? role : `${role.charAt(0)}${role.slice(1).toLowerCase()}`
            )
            .join(', ')
    )
}

export const getEntityName = (entity: styx.Entity) => {
    switch (entity.type) {
        case 'PERSON':
            return getPersonName(entity.name)
        case 'COMPANY':
            return entity.legalName
        case 'UNKNOWN':
            return entity.name
    }
}

export const getDirectorName = (director: styx.Director) => {
    switch (director.type) {
        case 'PERSON':
            return getPersonName(director.name)
        case 'UNKNOWN':
            return director.name.givenName
    }
}

export const getCompanyRegistryLink = (
    registryId: string,
    registrationNumber: string,
    legalName: string
) => {
    switch (registryId.substring(0, 2)) {
        case 'DK':
            return `${RegistryBaseUrl.DK}${registrationNumber}`
        case 'GB':
            return `${RegistryBaseUrl.UK}${registrationNumber}`
        case 'IE':
            return RegistryBaseUrl.IE
        case 'DE':
            return RegistryBaseUrl.DE
        case 'SE':
            return `${RegistryBaseUrl.PrefixSE}${registrationNumber}${RegistryBaseUrl.SuffixSE}`
        case 'NO':
            return `${RegistryBaseUrl.NO}${registrationNumber}`
        case 'ES':
            return `${RegistryBaseUrl.ES}${legalName}`
        case 'FR':
            return `${RegistryBaseUrl.FR}${registrationNumber.substring(0, 9)}`
        case 'AT':
            return `${RegistryBaseUrl.AT}${registrationNumber.substring(3, 9)}`
        case 'FI':
            return `${RegistryBaseUrl.FI}`
        case 'NL':
            return `${RegistryBaseUrl.NL}${registrationNumber}`
        case 'BE':
            return `${RegistryBaseUrl.BE}${registrationNumber}`
        default:
            return '#'
    }
}

export const onGetZipFile = async (company: StyxCompany) => {
    try {
        const res = await getCompanyZipFileUrl(company?.deimosId!)

        window.open(window.URL.createObjectURL(await res.blob()), '_blank')
    } catch (e) {
        message.error((e as Error).message)
    }
}

export const getZipFile = async (company: CompanyResponseV2) => {
    try {
        const res = await getCompanyZipFileUrl(company?.id)

        window.open(window.URL.createObjectURL(await res.blob()), '_blank')
    } catch (e) {
        message.error((e as Error).message)
    }
}

export type TypeOfKycCheck = 'eKyc' | 'idv' | 'pep/sis'

export const hasKycCheck = (
    entity: styx.Entity | styx.Director,
    typeOfKycCheck: TypeOfKycCheck
) => {
    if (entity.type === 'PERSON') {
        switch (typeOfKycCheck) {
            case 'eKyc':
                return !!entity.ekycChecks[0]
            case 'idv':
                return !!entity.idvChecks[0]
            case 'pep/sis':
                return !!entity.pepChecks[0] || !!entity.sisChecks[0]
        }
    }
}

export type NarrativeCategory =
    | 'COMPANY_INDUSTRY'
    | 'COMPANY_SOURCE_OF_WEALTH'
    | 'UBO_SOURCE_OF_WEALTH'
