import { Card, notification } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Input, ResetButton, Radio, SubmitButton } from 'formik-antd'
import dayjs, { Dayjs } from 'packages/dayjs'
import { useEffect, type FC } from 'react'
import { CARD_BODY_HEIGHT } from './constants'
import {
    ChangeExpensesStatusRequest,
    ChangeExpensesStatusResponse,
    ExpenseStatus,
} from '@pleo-io/deimos'
import { changeExpensesStatus } from 'services/deimos/export'
import { ContentContainer } from 'components/layout-containers'
import DatePicker from 'packages/form/date-picker'
import useSWR from 'swr'
import { buildFetcher, exportRequest } from 'services/request'

type FormValues = {
    status: ExpenseStatus
    expenseIds: string | null
    companyId: string | null
    exportHistoryId: string | null
    dateRange: [Dayjs | null, Dayjs | null] | null
    fetchedExpenseIds: string | null
}

const initialValues: FormValues = {
    status: ExpenseStatus.NOT_EXPORTED,
    expenseIds: null,
    companyId: null,
    exportHistoryId: null,
    dateRange: null,
    fetchedExpenseIds: null,
}

const useGetExportJobItems = (exportHistoryId: string) => {
    const { data, error } = useSWR(
        exportHistoryId ? `rest/v0/export-jobs/${exportHistoryId}/items` : null,
        buildFetcher(exportRequest)
    )

    return {
        data,
        error,
    }
}

const ChangeExpensesStatusComponent: FC<React.PropsWithChildren<{ shouldUseDeimos: boolean }>> = ({
    shouldUseDeimos,
}) => {
    const UUID_LENGTH = 36

    const handleSubmit = async (
        {
            status,
            expenseIds,
            companyId,
            exportHistoryId,
            dateRange,
            fetchedExpenseIds,
        }: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => {
        let payload: ChangeExpensesStatusRequest = {
            status,
        }

        if (expenseIds) {
            payload = {
                ...payload,
                expenseIds: expenseIds.split(/\n/).filter((it) => it.length === UUID_LENGTH),
            }
        } else if (!!companyId && !!dateRange) {
            payload = {
                ...payload,
                companyId,
                from: dateRange[0]?.toDate(),
                to: dateRange[1]?.toDate(),
            }
        } else if (exportHistoryId) {
            shouldUseDeimos
                ? (payload.exportHistoryId = exportHistoryId)
                : (payload = {
                      ...payload,
                      expenseIds: fetchedExpenseIds
                          ?.split(/\n/)
                          .filter((it) => it.length === UUID_LENGTH),
                  })
        }

        try {
            const { count }: ChangeExpensesStatusResponse = await (
                await changeExpensesStatus(payload)
            ).json()

            if (count) {
                notification.success({
                    message: `Successfully changed ${count} expenses.`,
                    placement: 'topRight',
                    style: {
                        whiteSpace: 'pre',
                    },
                })
            } else {
                notification.info({
                    message: `No expenses where changed.`,
                    placement: 'topRight',
                })
            }
        } catch (error) {
            notification.error({
                message: 'Could not change expenses.',
                description: (error as Error).message,
                placement: 'topRight',
            })
        }

        formikHelpers.resetForm()
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, isSubmitting, setValues }) => {
                const expenseIdsProvided = !!values.expenseIds
                const companyIdProvided = !!values.companyId
                const exportHistoryIdProvided = !!values.exportHistoryId
                const dateRangeProvided = !!values.dateRange
                const exportHistoryId = values.exportHistoryId?.trim()

                const { data: exportJobItems } = useGetExportJobItems(exportHistoryId!)

                useEffect(() => {
                    if (!shouldUseDeimos && exportHistoryIdProvided) {
                        const fetchedExpenseIds = exportJobItems?.data
                            .map((expense: any) => expense.accountingEntryId)
                            .join('\n')
                        setValues({ ...values, fetchedExpenseIds })
                    }
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [values.exportHistoryId, exportJobItems])

                const disableTextArea =
                    !shouldUseDeimos || companyIdProvided || exportHistoryIdProvided

                const disableCompanyId =
                    !shouldUseDeimos || expenseIdsProvided || exportHistoryIdProvided

                const disableExportHistoryId =
                    expenseIdsProvided || companyIdProvided || dateRangeProvided

                const disableRangePicker = disableCompanyId || !values.companyId

                const disableSubmit = !(
                    (expenseIdsProvided &&
                        !companyIdProvided &&
                        !exportHistoryIdProvided &&
                        !dateRangeProvided) ||
                    (!expenseIdsProvided && companyIdProvided && dateRangeProvided) ||
                    (!expenseIdsProvided &&
                        !companyIdProvided &&
                        !dateRangeProvided &&
                        exportHistoryIdProvided)
                )

                const disabledDate = (current: Dayjs) => current && current > dayjs().endOf('day')

                const VALID_STATUSES = [
                    ExpenseStatus.NOT_EXPORTED,
                    ExpenseStatus.QUEUED,
                    ExpenseStatus.EXPORTED,
                ]

                return (
                    <Form>
                        <Card
                            bodyStyle={{ height: CARD_BODY_HEIGHT }}
                            style={{ height: '100%', marginBottom: '1rem' }}
                            title="Change expense status"
                            type="inner"
                            actions={[
                                <SubmitButton
                                    type="link"
                                    size="small"
                                    loading={isSubmitting}
                                    disabled={disableSubmit || isSubmitting}
                                    data-testid="change-expenses-button"
                                    key="change-expenses-button"
                                >
                                    Update
                                </SubmitButton>,
                                <ResetButton
                                    type="link"
                                    danger
                                    size="small"
                                    data-testid="reset-button"
                                    key="reset-button"
                                >
                                    Reset form
                                </ResetButton>,
                            ]}
                        >
                            <ContentContainer>
                                <label htmlFor="status">Status</label>
                                <Radio.Group
                                    name="status"
                                    data-testid="status"
                                    options={VALID_STATUSES}
                                    size="small"
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                                <Input.TextArea
                                    name="expenseIds"
                                    rows={7}
                                    allowClear
                                    placeholder="Expense IDs"
                                    disabled={disableTextArea}
                                    data-testid="expense-ids"
                                />
                                <Input
                                    name="companyId"
                                    allowClear
                                    placeholder="Company ID"
                                    disabled={disableCompanyId}
                                    data-testid="company-id"
                                />
                                <Input
                                    name="exportHistoryId"
                                    allowClear
                                    placeholder="ExportHistory ID"
                                    disabled={disableExportHistoryId}
                                    data-testid="export-history-id"
                                />
                                <DatePicker.RangePicker
                                    picker="date"
                                    name="dateRange"
                                    disabled={disableRangePicker}
                                    style={{ width: '100%' }}
                                    allowClear
                                    allowEmpty={[true, true]}
                                    disabledDate={disabledDate}
                                    placeholder={['From', 'To']}
                                    data-testid="date-range"
                                />
                            </ContentContainer>
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ChangeExpensesStatusComponent
