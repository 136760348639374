import type { Company } from '@pleo-io/deimos'

import type { WalletUnloadResponse, WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'

export enum WalletUnloadsStatusDisplay {
    NEW = 'New',
    OUTSTANDING_INVOICES = 'Outstanding Invoices',
    UNDER_INVESTIGATION = 'Investigation',
    UNLOADED = 'Unloaded',
    CANCELLED = 'Rejected',
    EXPORTED = 'Exported',
    FAILED = 'Failed',
}

export enum SupplementaryUnloadStatus {
    OUTSTANDING_INVOICES = 'OUTSTANDING_INVOICES',
    EXPORTS = 'EXPORTS',
}

// Back-end only uses the WalletUnloadResponseStatus types.
// Front-end uses an additional type for the NEW wallet unloads
// for companies that have outstanding invoices and should be settled
// before a wallet unload can be approved. Therefore, we're separating
// them from the new unloads with no invoices to settle.
export type WalletUnloadsStatusAPI =
    | WalletUnloadResponseStatus
    | SupplementaryUnloadStatus.OUTSTANDING_INVOICES

export const removeSpaces = (str?: string | null) => str?.replace(/ /g, '') || ''

export enum CountryCode {
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FI = 'FI',
    FO = 'FO',
    FR = 'FR',
    GB = 'GB',
    GR = 'GR',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IS = 'IS',
    IT = 'IT',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MT = 'MT',
    NL = 'NL',
    NO = 'NO',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    UNDEFINED = 'N/A',
}

const IBAN_REGEX = /([A-Z]{2}[0-9]{2}(?=[A-Z0-9]{9,30})(?:[A-Z0-9]{3,5}){2,7}(?:[A-Z0-9]{1,3})?)/
const GB_SORT_CODE_REGEX = /(?:[^0-9])\d{6}(?!\d)/
const GB_ACCOUNT_NUMBER_REGEX = /(?:[^0-9])\d{8}(?!\d)/

export const ibanPatternMatch = (countryCode: CountryCode, data?: string) => {
    if (!data) return
    const noSpacesData = removeSpaces(data)
    if (!noSpacesData || countryCode === CountryCode.UNDEFINED) return

    const matched = noSpacesData.match(IBAN_REGEX)
    return matched ? matched[0] : undefined
}

export const accountNumberPatternMatch = (countryCode: CountryCode, data?: string) => {
    if (!data || countryCode === CountryCode.UNDEFINED) return

    // Regex look behind is not supported in Safari, hence the regex captures an additional
    // character that needs to be removed. Moreover, the original string needs to be
    // padded, in case the account number has no other characters before it (case when
    // the regex won't match otherwise)
    const matched = data.padStart(data.length + 1, ' ').match(GB_ACCOUNT_NUMBER_REGEX)
    return matched ? matched[0].substring(1) : undefined
}

export const bankCodePatternMatch = (countryCode: CountryCode, data?: string) => {
    if (!data || countryCode === CountryCode.UNDEFINED) return

    // Regex look behind is not supported in Safari, hence the regex captures an additional
    // character that needs to be removed. Moreover, the original string needs to be
    // padded, in case the account number has no other characters before it (case when
    // the regex won't match otherwise)
    const matched = data.padStart(data.length + 1, ' ').match(GB_SORT_CODE_REGEX)
    return matched ? matched[0].substring(1) : undefined
}

export const bankDetailsMismatches = (
    hasIban: boolean,
    hasAccountNumber: boolean,
    hasSortCode: boolean,
    ibanMismatch: boolean,
    accountMismatch: boolean,
    sortCodeMismatch: boolean
) => {
    const mismatchMessages = []
    if (!hasIban && !hasAccountNumber && !hasSortCode) {
        mismatchMessages.push('No bank details')
    } else if (!hasIban && (!hasAccountNumber || !hasSortCode)) {
        mismatchMessages.push('Incomplete bank details')
    } else if (hasIban && ibanMismatch) {
        mismatchMessages.push('IBAN mismatch')
    } else if (hasAccountNumber && hasSortCode && accountMismatch && sortCodeMismatch) {
        mismatchMessages.push('Account number mismatch')
        mismatchMessages.push('Sort code mismatch')
    } else if (hasAccountNumber && hasSortCode && accountMismatch) {
        mismatchMessages.push('Account number mismatch')
    } else if (hasAccountNumber && hasSortCode && sortCodeMismatch) {
        mismatchMessages.push('Sort code mismatch')
    }
    return mismatchMessages
}

export const getCountryForWalletUnload = (
    walletUnload: WalletUnloadResponse,
    company?: Company
) => {
    const possibleCountryCode =
        walletUnload.account.bankCountry ||
        walletUnload.beneficiaryAddress.country ||
        company?.address?.country
    return possibleCountryCode ? (possibleCountryCode as CountryCode) : CountryCode.UNDEFINED
}

export const getBankInformation = (
    walletUnload: WalletUnloadResponse,
    company?: Company,
    senderBank?: string,
    senderInformation?: string
) => {
    const countryCode = getCountryForWalletUnload(walletUnload, company) || CountryCode.UNDEFINED

    const iban =
        ibanPatternMatch(countryCode, senderBank) ||
        ibanPatternMatch(countryCode, senderInformation)
    const accountNumber =
        accountNumberPatternMatch(countryCode, senderBank) ||
        accountNumberPatternMatch(countryCode, senderInformation)
    const bankCode =
        bankCodePatternMatch(countryCode, senderBank) ||
        bankCodePatternMatch(countryCode, senderInformation)

    return { iban, accountNumber, bankCode }
}

export const getTypeForWalletUnload = (walletUnload: WalletUnloadResponse) =>
    walletUnload.fullAmount ? 'Full' : 'Partial'
export const getAmountForWalletUnload = (walletUnload: WalletUnloadResponse) =>
    `${walletUnload.amount.value} ${walletUnload.amount.currency}`

export const fileTypeLabel = (param: any) => {
    switch (param?.fileType) {
        case 'csv':
            return 'CSV File'
        case 'xml':
            return 'XML File'
        default:
            return 'File'
    }
}
