import { Card, Result } from 'antd'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectCompanyId } from 'store/modules/support/company/selectors'
import { OverviewBeyondPlan } from './overview/overview-beyond'
import { bff } from '../../bff'
import type { SubscriptionListResponse, CompanyResponse } from 'bff/moons/generated/beyond'

export interface Props {
    beyondSubscriptionError: any
    beyondSubscription?: SubscriptionListResponse | false
    beyondCompany?: CompanyResponse
}

export const BillingSubscription: FC<React.PropsWithChildren<Props>> = ({
    beyondSubscriptionError,
    beyondSubscription,
    beyondCompany,
}) => {
    const beyondSub =
        beyondSubscription && beyondSubscription?.data && beyondSubscription?.data.length > 0
            ? beyondSubscription.data[0]
            : undefined
    const beyondSubNoData = beyondSubscription === false
    const beyondSubLoading = !beyondSub && !beyondSubNoData && !beyondSubscriptionError
    const beyondCompanyData = beyondCompany && beyondCompany?.data

    return (
        <Card title="Subscription" loading={beyondSubLoading}>
            {beyondSubscriptionError && (
                <Result
                    status="error"
                    title="Failed to load subscription data"
                    subTitle="Please try again later"
                />
            )}
            {beyondSubNoData && <Result status="warning" title="No data found" />}
            {beyondSub && (
                <OverviewBeyondPlan subscription={beyondSub} company={beyondCompanyData} />
            )}
        </Card>
    )
}

const BillingSubscriptionContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const companyId = useSelector(selectCompanyId)

    const { data: beyondSubscription, error: beyondSubscriptionError } =
        bff.subscription.getCompanySubscription.useQuery({
            params: { companyId },
        })

    const { data: beyondCompany } = bff.subscription.getCompany.useQuery({ companyId })

    return (
        <BillingSubscription
            beyondSubscription={beyondSubscription}
            beyondCompany={beyondCompany}
            beyondSubscriptionError={beyondSubscriptionError}
        />
    )
}

export default BillingSubscriptionContainer
