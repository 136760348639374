import { Button, Table, Typography } from 'antd'
import type { ColumnType } from 'antd/lib/table/interface'
import * as Yup from 'yup'

import type { DateOfBirth, PersonSearchResponse, StyxCompanyV2 } from 'types/styx'
import { Link } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { spacing } from 'theme/tokens'
import { getPersonName } from '../shared/utils'
import NameFilterForm from './filters/name-filter-form'
import BirthdateFilterForm from './filters/birthdate-filter-form'
import { useState } from 'react'
import InScopeFilterForm from './filters/inscope-filter-form'

const { Text } = Typography

// This function manages the DoB where some parts of the date are missing in the database
const formatDateOfBirth = (dob: DateOfBirth | undefined) => {
    if (!dob) return '-'
    const day = dob.day ? String(dob.day).padStart(2, '0') : '00'
    const month = dob.month ? String(dob.month).padStart(2, '0') : '00'
    const year = dob.year ? String(dob.year) : '0000'
    return `${day}-${month}-${year}`
}

const validationSchema = Yup.object()
    .shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        middleName: Yup.string(),
        dateOfBirth: Yup.number(),
        monthOfBirth: Yup.number(),
        yearOfBirth: Yup.number(),
        inScope: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    persons: PersonSearchResponse[]
    onSubmit: (values: FormValues) => void
    loading: boolean
}

const ListPersons = ({ persons, onSubmit, loading }: Props) => {
    const [tableKey, setTableKey] = useState(0)

    const resetFilters = () => {
        setSearchValues(initialValues)
        onSubmit(initialValues)
        setTableKey((key) => key + 1)
    }

    const initialValues: FormValues = {
        firstName: '',
        lastName: '',
        middleName: '',
        dateOfBirth: undefined,
        monthOfBirth: undefined,
        yearOfBirth: undefined,
        inScope: '',
    }

    const [searchValues, setSearchValues] = useState<FormValues>(initialValues)

    const handleSearch = (values: FormValues) => {
        setSearchValues(values)
        onSubmit(values)
    }

    const nameColumnSearchProps = (): ColumnType<PersonSearchResponse> => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: spacing.space16 }}>
                <NameFilterForm
                    loading={loading}
                    onSubmit={(values) => {
                        handleSearch({
                            ...searchValues,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            middleName: values.middleName,
                        })
                        confirm()
                    }}
                />
            </div>
        ),
        filterIcon: () => <SearchOutlined data-testid="name-filter-search" />,
    })

    const birthColumnSearchProps = (): ColumnType<PersonSearchResponse> => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: spacing.space16 }}>
                <BirthdateFilterForm
                    loading={loading}
                    onSubmit={(values) => {
                        handleSearch({
                            ...searchValues,
                            dateOfBirth: values.dateOfBirth,
                            monthOfBirth: values.monthOfBirth,
                            yearOfBirth: values.yearOfBirth,
                        })
                        confirm()
                    }}
                />
            </div>
        ),
        filterIcon: () => <SearchOutlined data-testid="birthdate-filter-search" />,
    })

    const inScopeColumnSearchProps = (): ColumnType<PersonSearchResponse> => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: spacing.space16 }}>
                <InScopeFilterForm
                    loading={loading}
                    onSubmit={(values) => {
                        handleSearch({
                            ...searchValues,
                            inScope: values.inScope,
                        })
                        confirm()
                    }}
                />
            </div>
        ),
        filterIcon: () => <SearchOutlined data-testid="inscope-filter-search" />,
    })

    const columns: ColumnType<PersonSearchResponse>[] = [
        {
            title: 'First name',
            dataIndex: 'name',
            render: (name) => getPersonName(name),
            ...nameColumnSearchProps(),
        },
        {
            title: 'Date of birth',
            dataIndex: 'dateOfBirth',
            render: (record) => {
                return <Text>{formatDateOfBirth(record)}</Text>
            },
            ...birthColumnSearchProps(),
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (registryId) => registryId,
        },
        {
            title: 'Associated company',
            dataIndex: 'company',
            render: (company?: StyxCompanyV2) =>
                company ? (
                    <Link to={`/compliance/companies/${company.id}`}>{company.legalName}</Link>
                ) : (
                    '-'
                ),
        },
        {
            title: 'Company velocity',
            dataIndex: 'companyVelocity',
            render: (companyVelocity?: string) => companyVelocity ?? '-',
        },
        {
            title: 'In scope',
            dataIndex: 'kycPerson',
            render: (record) => (record ? <Text type="danger">Yes</Text> : <Text>No</Text>),
            ...inScopeColumnSearchProps(),
        },
    ]

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    onClick={() => {
                        resetFilters()
                    }}
                    loading={loading}
                >
                    Reset filters
                </Button>
            </div>

            <Table<PersonSearchResponse>
                key={tableKey}
                loading={loading}
                dataSource={persons}
                rowKey="id"
                columns={columns}
                pagination={false}
            />
        </>
    )
}

export default ListPersons
