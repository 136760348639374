import { Button, Col, Divider, Input, Popover, Row, Select, Table, Tag, Typography } from 'antd'
import Icon, { AppleFilled, WindowsFilled, MobileFilled } from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import dayjs, { Dayjs } from 'packages/dayjs'
import type { RangeValue } from 'rc-picker/lib/interface'
import UAParser from 'ua-parser-js'
import { useDebounce } from 'utils/use-debounce'
import { useLoginEvents } from '../api/login-events'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { disableFutureDate } from 'pages/customer-success/utils'
import { EVENT_NAME, type UserAgentDetails } from '../types'
import type { FC } from 'react'
import { Params } from '../enums'

const { RangePicker } = DayjsDatePicker

const { Text } = Typography

const { Group, Search } = Input

const FirefoxSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M130.22 127.548C130.38 127.558 130.3 127.558 130.22 127.548V127.548ZM481.64 172.898C471.03 147.398 449.56 119.898 432.7 111.168C446.42 138.058 454.37 165.048 457.4 185.168C457.405 185.306 457.422 185.443 457.45 185.578C429.87 116.828 383.098 89.1089 344.9 28.7479C329.908 5.05792 333.976 3.51792 331.82 4.08792L331.7 4.15792C284.99 30.1109 256.365 82.5289 249.12 126.898C232.503 127.771 216.219 131.895 201.19 139.035C199.838 139.649 198.736 140.706 198.066 142.031C197.396 143.356 197.199 144.87 197.506 146.323C197.7 147.162 198.068 147.951 198.586 148.639C199.103 149.327 199.76 149.899 200.512 150.318C201.264 150.737 202.096 150.993 202.954 151.071C203.811 151.148 204.676 151.045 205.491 150.768L206.011 150.558C221.511 143.255 238.408 139.393 255.541 139.238C318.369 138.669 352.698 183.262 363.161 201.528C350.161 192.378 326.811 183.338 304.341 187.248C392.081 231.108 368.541 381.784 246.951 376.448C187.487 373.838 149.881 325.467 146.421 285.648C146.421 285.648 157.671 243.698 227.041 243.698C234.541 243.698 255.971 222.778 256.371 216.698C256.281 214.698 213.836 197.822 197.281 181.518C188.434 172.805 184.229 168.611 180.511 165.458C178.499 163.75 176.392 162.158 174.201 160.688C168.638 141.231 168.399 120.638 173.51 101.058C148.45 112.468 128.96 130.508 114.8 146.428H114.68C105.01 134.178 105.68 93.7779 106.25 85.3479C106.13 84.8179 99.022 89.0159 98.1 89.6579C89.5342 95.7103 81.5528 102.55 74.26 110.088C57.969 126.688 30.128 160.242 18.76 211.318C14.224 231.701 12 255.739 12 263.618C12 398.318 121.21 507.508 255.92 507.508C376.56 507.508 478.939 420.281 496.35 304.888C507.922 228.192 481.64 173.82 481.64 172.898Z" />
    </svg>
)

const ChromeSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M0 256C0 209.4 12.47 165.6 34.27 127.1L144.1 318.3C166 357.5 207.9 384 256 384C270.3 384 283.1 381.7 296.8 377.4L220.5 509.6C95.9 492.3 0 385.3 0 256zM365.1 321.6C377.4 302.4 384 279.1 384 256C384 217.8 367.2 183.5 340.7 160H493.4C505.4 189.6 512 222.1 512 256C512 397.4 397.4 511.1 256 512L365.1 321.6zM477.8 128H256C193.1 128 142.3 172.1 130.5 230.7L54.19 98.47C101 38.53 174 0 256 0C350.8 0 433.5 51.48 477.8 128V128zM168 256C168 207.4 207.4 168 256 168C304.6 168 344 207.4 344 256C344 304.6 304.6 344 256 344C207.4 344 168 304.6 168 256z" />
    </svg>
)

const SafariSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M274.69,274.69l-37.38-37.38L166,346ZM256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8ZM411.85,182.79l14.78-6.13A8,8,0,0,1,437.08,181h0a8,8,0,0,1-4.33,10.46L418,197.57a8,8,0,0,1-10.45-4.33h0A8,8,0,0,1,411.85,182.79ZM314.43,94l6.12-14.78A8,8,0,0,1,331,74.92h0a8,8,0,0,1,4.33,10.45l-6.13,14.78a8,8,0,0,1-10.45,4.33h0A8,8,0,0,1,314.43,94ZM256,60h0a8,8,0,0,1,8,8V84a8,8,0,0,1-8,8h0a8,8,0,0,1-8-8V68A8,8,0,0,1,256,60ZM181,74.92a8,8,0,0,1,10.46,4.33L197.57,94a8,8,0,1,1-14.78,6.12l-6.13-14.78A8,8,0,0,1,181,74.92Zm-63.58,42.49h0a8,8,0,0,1,11.31,0L140,128.72A8,8,0,0,1,140,140h0a8,8,0,0,1-11.31,0l-11.31-11.31A8,8,0,0,1,117.41,117.41ZM60,256h0a8,8,0,0,1,8-8H84a8,8,0,0,1,8,8h0a8,8,0,0,1-8,8H68A8,8,0,0,1,60,256Zm40.15,73.21-14.78,6.13A8,8,0,0,1,74.92,331h0a8,8,0,0,1,4.33-10.46L94,314.43a8,8,0,0,1,10.45,4.33h0A8,8,0,0,1,100.15,329.21Zm4.33-136h0A8,8,0,0,1,94,197.57l-14.78-6.12A8,8,0,0,1,74.92,181h0a8,8,0,0,1,10.45-4.33l14.78,6.13A8,8,0,0,1,104.48,193.24ZM197.57,418l-6.12,14.78a8,8,0,0,1-14.79-6.12l6.13-14.78A8,8,0,1,1,197.57,418ZM264,444a8,8,0,0,1-8,8h0a8,8,0,0,1-8-8V428a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8Zm67-6.92h0a8,8,0,0,1-10.46-4.33L314.43,418a8,8,0,0,1,4.33-10.45h0a8,8,0,0,1,10.45,4.33l6.13,14.78A8,8,0,0,1,331,437.08Zm63.58-42.49h0a8,8,0,0,1-11.31,0L372,383.28A8,8,0,0,1,372,372h0a8,8,0,0,1,11.31,0l11.31,11.31A8,8,0,0,1,394.59,394.59ZM286.25,286.25,110.34,401.66,225.75,225.75,401.66,110.34ZM437.08,331h0a8,8,0,0,1-10.45,4.33l-14.78-6.13a8,8,0,0,1-4.33-10.45h0A8,8,0,0,1,418,314.43l14.78,6.12A8,8,0,0,1,437.08,331ZM444,264H428a8,8,0,0,1-8-8h0a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8h0A8,8,0,0,1,444,264Z" />
    </svg>
)

const EdgeSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M120.1 37.44C161.1 12.23 207.7-.7753 255 .0016C423 .0016 512 123.8 512 219.5C511.9 252.2 499 283.4 476.1 306.7C453.2 329.9 422.1 343.2 389.4 343.7C314.2 343.7 297.9 320.6 297.9 311.7C297.9 307.9 299.1 305.5 302.7 302.3L303.7 301.1L304.1 299.5C314.6 288 320 273.3 320 257.9C320 179.2 237.8 115.2 136 115.2C98.46 114.9 61.46 124.1 28.48 142.1C55.48 84.58 111.2 44.5 119.8 38.28C120.6 37.73 120.1 37.44 120.1 37.44V37.44zM135.7 355.5C134.3 385.5 140.3 415.5 152.1 442.7C165.7 469.1 184.8 493.7 208.6 512C149.1 500.5 97.11 468.1 59.2 422.7C21.12 376.3 0 318.4 0 257.9C0 206.7 62.4 163.5 136 163.5C172.6 162.9 208.4 174.4 237.8 196.2L234.2 197.4C182.7 215 135.7 288.1 135.7 355.5V355.5zM469.8 400L469.1 400.1C457.3 418.9 443.2 435.2 426.9 449.6C396.1 477.6 358.8 495.1 318.1 499.5C299.5 499.8 281.3 496.3 264.3 488.1C238.7 477.8 217.2 458.1 202.7 435.1C188.3 411.2 181.6 383.4 183.7 355.5C183.1 335.4 189.1 315.2 198.7 297.3C212.6 330.4 236.2 358.6 266.3 378.1C296.4 397.6 331.8 407.6 367.7 406.7C398.7 407 429.8 400 457.9 386.2L459.8 385.3C463.7 383 467.5 381.4 471.4 385.3C475.9 390.2 473.2 394.5 470.2 399.3C470 399.5 469.9 399.8 469.8 400V400z" />
    </svg>
)

const InternetExplorerSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M483.049 159.706c10.855-24.575 21.424-60.438 21.424-87.871 0-72.722-79.641-98.371-209.673-38.577-107.632-7.181-211.221 73.67-237.098 186.457 30.852-34.862 78.271-82.298 121.977-101.158C125.404 166.85 79.128 228.002 43.992 291.725 23.246 329.651 0 390.94 0 436.747c0 98.575 92.854 86.5 180.251 42.006 31.423 15.43 66.559 15.573 101.695 15.573 97.124 0 184.249-54.294 216.814-146.022H377.927c-52.509 88.593-196.819 52.996-196.819-47.436H509.9c6.407-43.581-1.655-95.715-26.851-141.162zM64.559 346.877c17.711 51.15 53.703 95.871 100.266 123.304-88.741 48.94-173.267 29.096-100.266-123.304zm115.977-108.873c2-55.151 50.276-94.871 103.98-94.871 53.418 0 101.981 39.72 103.981 94.871H180.536zm184.536-187.6c21.425-10.287 48.563-22.003 72.558-22.003 31.422 0 54.274 21.717 54.274 53.722 0 20.003-7.427 49.007-14.569 67.867-26.28-42.292-65.986-81.584-112.263-99.586z" />
    </svg>
)

const OperaSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
        <path d="M313.9 32.7c-170.2 0-252.6 223.8-147.5 355.1 36.5 45.4 88.6 75.6 147.5 75.6 36.3 0 70.3-11.1 99.4-30.4-43.8 39.2-101.9 63-165.3 63-3.9 0-8 0-11.9-.3C104.6 489.6 0 381.1 0 248 0 111 111 0 248 0h.8c63.1.3 120.7 24.1 164.4 63.1-29-19.4-63.1-30.4-99.3-30.4zm101.8 397.7c-40.9 24.7-90.7 23.6-132-5.8 56.2-20.5 97.7-91.6 97.7-176.6 0-84.7-41.2-155.8-97.4-176.6 41.8-29.2 91.2-30.3 132.9-5 105.9 98.7 105.5 265.7-1.2 364z" />
    </svg>
)

const Chrome = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={ChromeSVG} {...props} />
)

const InternetExplorer = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={InternetExplorerSVG} {...props} />
)

const Safari = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SafariSVG} {...props} />
)

const Firefox = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={FirefoxSVG} {...props} />
)

const Edge = (props: Partial<CustomIconComponentProps>) => <Icon component={EdgeSVG} {...props} />

const Opera = (props: Partial<CustomIconComponentProps>) => <Icon component={OperaSVG} {...props} />

interface UADetailLineProps {
    label: string
    value?: string
}

const UADetailLine: FC<UADetailLineProps> = ({ label, value }) => (
    <Col span={24}>
        <span>
            {label}:{' '}
            {value ? <Text type="success">{value}</Text> : <Text type="secondary">Unknown</Text>}
        </span>
    </Col>
)
interface UserAgentDetailsProps {
    UADetails: UserAgentDetails
}

const UserAgentComponent: FC<UserAgentDetailsProps> = ({ UADetails }) => {
    const hasAppVersion = UADetails.ua.includes('Pleo/')
    const appVersion = hasAppVersion ? UADetails.ua.split('Pleo/')[1].split(' ')[0] : undefined

    return (
        <Row gutter={[0, 0]} style={{ width: '250px' }}>
            <UADetailLine label="OS name" value={UADetails.os.name} />
            <UADetailLine label="Device model" value={UADetails.device.model} />
            <UADetailLine label="Device vendor" value={UADetails.device.vendor} />
            <UADetailLine label="Type" value={UADetails.device.type} />
            <UADetailLine label="Browser" value={UADetails.browser.name} />
            <UADetailLine label="Browser version" value={UADetails.browser.version} />
            <UADetailLine label="Engine name" value={UADetails.engine.name} />
            <UADetailLine label="Engine version" value={UADetails.engine.version} />
            <UADetailLine label="App version" value={appVersion} />
        </Row>
    )
}

interface UserAgentSummaryProps {
    UADetails: UserAgentDetails
}

export const UserAgentSummary: FC<UserAgentSummaryProps> = ({ UADetails }) => {
    const { browser, device, os, ua } = UADetails

    /* Browser */
    const isChrome = browser.name?.includes('Chrome')
    const isIe = browser.name?.includes('Ie')
    const isSafari = browser.name?.includes('Safari')
    const isFirefox = browser.name?.includes('Firefox')
    const isEdge = browser.name?.includes('Edge')
    const isOpera = browser.name?.includes('Opera')
    const isOtherBrowser = !(isChrome || isIe || isSafari || isFirefox || isEdge || isOpera)
    /* OS */
    const isApple =
        os.name?.includes('Mac') || os.name?.includes('Apple') || os.name?.includes('iOS')
    const isWindows = os.name?.includes('Windows')
    /* Device */
    const isMobile = device.type?.includes('mobile')
    /* Pleo version */
    const hasPleoVersion = ua.includes('Pleo/') && ua.split('Pleo/') && ua.split('Pleo/').length > 0
    const appVersion = hasPleoVersion ? ua.split('Pleo/')[1].split(' ')[0] : undefined

    return (
        <>
            User agent info: {/* Browser */}
            {isChrome && <Chrome style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isIe && (
                <InternetExplorer style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />
            )}
            {isSafari && <Safari style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isFirefox && <Firefox style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isEdge && <Edge style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isOpera && <Opera style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isOtherBrowser && browser.name && (
                <span style={{ marginLeft: 4 }}>{browser.name}</span>
            )}
            {/* OS */}
            {isApple && <AppleFilled style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />}
            {isWindows && (
                <WindowsFilled style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />
            )}
            {!isApple && !isWindows && os.name && <span style={{ marginLeft: 4 }}>{os.name}</span>}
            {/* Device */}
            {isMobile ? (
                <MobileFilled style={{ width: 14, fill: 'currentcolor', marginLeft: 4 }} />
            ) : (
                device.type && <span style={{ marginLeft: 4 }}>{device.type}</span>
            )}
            {/* App version */}
            {hasPleoVersion && <Tag style={{ marginLeft: 4 }}>{appVersion}</Tag>}
        </>
    )
}

const eventColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '150px',
        render: (name: string, record: any) => {
            const hasError = record?.payload && record.payload.includes('error')
            const parsedPayload = record?.payload && JSON.parse(record.payload)
            return hasError ? (
                <>
                    {name}{' '}
                    <Popover content={parsedPayload?.error} title="Error details" trigger="click">
                        <Button>Details</Button>
                    </Popover>
                </>
            ) : (
                <>{name}</>
            )
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        width: '200px',
    },
    {
        title: 'User agent',
        dataIndex: 'payload',
        width: '300px',
        render: (payload: string) => {
            const hasUserAgent = payload && payload.includes('userAgent')
            const parsedPayload = JSON.parse(payload)
            const parser = new UAParser(parsedPayload.userAgent)
            const UADetails: UserAgentDetails = parser.getResult()
            return hasUserAgent ? (
                <Popover
                    content={<UserAgentComponent UADetails={UADetails} />}
                    title="Full user-agent"
                    trigger="click"
                >
                    <Button>
                        <UserAgentSummary UADetails={UADetails} />
                    </Button>
                </Popover>
            ) : (
                'Missing user agent'
            )
        },
    },
    {
        title: 'IP Address',
        dataIndex: 'payload',
        render: (payload: string) => getIpFromPayload(payload),
    },
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        render: (timestamp: string) => dayjs(timestamp).format('lll'),
    },
]

const getIpFromPayload = (payload: string) => {
    const user = JSON.parse(payload)
    if (user?.ip) return user.ip
    return '-'
}

interface Props {
    showEmailFilter: boolean
    showDomainFilter: boolean
    currentPageParam?: string
    domainParam?: string
    emailParam?: string
    eventParam?: string
    fromDateParam?: string
    pageSizeParam?: string
    toDateParam?: string
    onFilterChange: (filter: Params, value: string | undefined) => void
}

export const LoginEventsTable: FC<Props> = ({
    showEmailFilter,
    showDomainFilter,
    currentPageParam,
    domainParam,
    emailParam,
    eventParam,
    fromDateParam,
    pageSizeParam,
    toDateParam,
    onFilterChange,
}) => {
    const debouncedDomainFilter = useDebounce(domainParam, 300)
    const debouncedEmailSearch = useDebounce(emailParam, 300)

    const calculateOffset = () => {
        const currentPage = Number(currentPageParam)
        const pageSize = Number(pageSizeParam)
        const offset = currentPage !== 1 ? (currentPage - 1) * pageSize : 0
        return offset
    }

    const { data, isValidating } = useLoginEvents({
        email: debouncedEmailSearch ?? undefined,
        limit: Number(pageSizeParam),
        offset: calculateOffset(),
        from: fromDateParam ?? undefined,
        to: toDateParam ?? undefined,
        eventName: eventParam ?? undefined,
        domain: debouncedDomainFilter ?? undefined,
    })

    const onChangeRange = (date: RangeValue<Dayjs>) => {
        const fromFormatted = date?.[0] ? dayjs(date?.[0]).toISOString() : undefined
        const toFormatted = date?.[1] ? dayjs(date?.[1]).toISOString() : undefined

        onFilterChange(Params.FROM_DATE, fromFormatted)
        onFilterChange(Params.TO_DATE, toFormatted)
    }

    const items = Object.values(EVENT_NAME).map((value) => ({
        label: value,
        value,
    }))

    return (
        <>
            <Group>
                <RangePicker
                    value={[
                        fromDateParam ? dayjs(fromDateParam) : null,
                        toDateParam ? dayjs(toDateParam) : null,
                    ]}
                    allowClear
                    allowEmpty={[true, true]}
                    onChange={onChangeRange}
                    placeholder={['Start date', 'End date']}
                    disabledDate={(date) => disableFutureDate(dayjs(date.toDate()))}
                    showTime
                    style={{ marginRight: 10, marginBottom: 5 }}
                />
                <Select
                    data-testid="event-name-filter"
                    value={eventParam}
                    options={items}
                    allowClear
                    onChange={(value) => onFilterChange(Params.EVENT, value)}
                    placeholder="Select name event filter"
                    style={{ width: '25%', marginRight: 10, marginBottom: 5 }}
                />
                {showEmailFilter && (
                    <Search
                        allowClear
                        value={emailParam ?? undefined}
                        onChange={(value) => onFilterChange(Params.EMAIL, value.target.value)}
                        placeholder="Email search (exact match)"
                        style={{ width: '25%', marginRight: 10, marginBottom: 5 }}
                    />
                )}
                {showDomainFilter && (
                    <Search
                        allowClear
                        value={domainParam ?? undefined}
                        onChange={(value) => onFilterChange(Params.DOMAIN, value.target.value)}
                        placeholder="Domain filter eg 'gmail.com'"
                        style={{ width: '25%', marginRight: 10, marginBottom: 5 }}
                    />
                )}
            </Group>
            <Divider style={{ margin: '8px 0 12px' }} />
            <Table
                loading={isValidating}
                dataSource={data?.data}
                columns={eventColumns}
                rowKey="id"
                pagination={{
                    onChange: (page, pageSize) => {
                        if (pageSize) {
                            onFilterChange(Params.PAGE_SIZE, String(pageSize))
                            onFilterChange(Params.CURRENT_PAGE, String(page))
                        }
                    },
                    current: Number(currentPageParam),
                    total: Number(data?.totalCount || 10),
                    pageSize: data?.limit,
                }}
                bordered
            />
        </>
    )
}
