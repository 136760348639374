import {
    Button,
    Card,
    Col,
    Row,
    Divider,
    Modal,
    notification,
    Space,
    Switch as AntdSwitch,
    Table,
    Tooltip,
    Typography,
} from 'antd'
import { FieldArray, Formik, FormikErrors } from 'formik'
import { Form, FormItem, Input, Switch, Select } from 'formik-antd'
import { not } from 'ramda'
import React, { FC, useState } from 'react'
import {
    CardOrderStatus,
    CustomOrderFormValues,
    Order,
    Manufacturer,
    DeliveryType,
    DisplayManufacturer,
    DisplayDeliveryType,
    ActiveManufacturers,
} from 'store/modules/support/card-orders/types'
import type { Address, Company } from 'types/deimos-company'
import ConfirmOrder from './confirm-order'
import * as s from './custom-order-form.styles'
import ImportCSV from './import-csv'
import { mapValuesToRequest, validationSchema } from './validation-schema'
import { useEmployees, useGetCompanies } from 'services/deimos/companies'
import { useOrders } from 'services/deimos/card-orders'
import { groupByAddress } from 'pages/customer-success/card-orders/utils'
import { inputWidth } from 'theme/tokens'
import { ContentContainer } from 'components/layout-containers'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import type { Employee } from 'types/employee'
import { Label } from 'components/sidebar-title/sidebar-title'

const { Option } = Select
const { Text } = Typography

const defaultAddress: Address = {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    locality: '',
    region: '',
    country: '',
}

const defaultOrder = {
    name: '',
    address: undefined,
    deliveryType: DeliveryType.STANDARD,
    names: [''],
    cardOwners: [{ name: '', employeeId: '' }],
}
interface Props {
    initialValues: CustomOrderFormValues
    companies: Company[]
    selectedCompany?: Company
    employees: Employee[]
    disableExpressCardOrders: boolean
    handleSearch: (value: string) => void
    onCancelClick: () => void
    onOrderSubmit: (values: CustomOrderFormValues, isGPS: boolean) => void
    onCompanySelect: (companyId: string) => void
}

interface PreviousOrder {
    index: number
    address: Address
}

export const CustomCardOrder: FC<React.PropsWithChildren<Props>> = ({
    initialValues,
    companies,
    employees,
    selectedCompany,
    disableExpressCardOrders,
    handleSearch,
    onCancelClick,
    onOrderSubmit,
    onCompanySelect,
}) => {
    const companyAddress = selectedCompany?.address || defaultAddress
    const [showDeliveryTooltip, setShowDeliveryTooltip] = useState(false)
    const [showManufacturerTooltip, setShowManufacturerTooltip] = useState(false)
    const [showPreviousOrdersModal, setShowPreviousOrdersModal] = useState(false)
    const [showOnlyReceived, setShowOnlyReceived] = useState(false)
    const deliveryDisabledTooltipTitle = disableExpressCardOrders
        ? 'Need permission to modify'
        : 'Available only for Idemia'
    const isGPS = false
    const { data: orders } = useOrders({ companyId: selectedCompany?.id })

    return (
        <s.Card>
            <Formik
                onSubmit={() => {}}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount
            >
                {({
                    values,
                    touched,
                    errors,
                    isValid,
                    isSubmitting,
                    setFieldValue,
                    setValues,
                    setTouched,
                    setSubmitting,
                    validateForm,
                    resetForm,
                }) => {
                    // When the user selects a company, the company emboss line
                    // will automatically be populated. In some cases, it might
                    // be invalid so we need to show the error, by touching the
                    // field if the user hasn't touched it.
                    if (
                        selectedCompany &&
                        !touched['companyEmbossLine'] &&
                        errors['companyEmbossLine']
                    ) {
                        setTouched({ companyEmbossLine: true })
                    }
                    // Auto-complete company name on company emboss line
                    if (selectedCompany && !values['companyEmbossLine']) {
                        setValues({ ...values, companyEmbossLine: selectedCompany.name })
                    }

                    const checkAddresses = () =>
                        setValues({
                            ...values,
                            orders: groupByAddress(values.orders),
                        })

                    const populateAddress = (index: number) => {
                        const newValues = { ...values }
                        newValues.orders[index].address = companyAddress
                        setValues(newValues)
                    }
                    const populateAddressPreviousOrder = ({ index, address }: PreviousOrder) => {
                        const newValues = { ...values }
                        newValues.orders[index].address = address
                        setValues(newValues)
                        setShowPreviousOrdersModal(false)
                    }
                    const formatAddress = (address: Address) => {
                        const addressLine1 = address.addressLine1
                        const addressLine2 = address.addressLine2
                        const addressLine3 = `${address.locality}, ${
                            address.region ? `${address.region} ` : ''
                        }${address.postalCode}`
                        const addressLine4 = address.country

                        return (
                            <>
                                {addressLine1 && <p>{addressLine1}</p>}
                                {addressLine2 && <p>{addressLine2}</p>}
                                {addressLine3 && <p>{addressLine3}</p>}
                                {addressLine4 && <p>{addressLine4}</p>}
                            </>
                        )
                    }

                    const columns = (index: number) => [
                        {
                            title: 'Address',
                            dataIndex: 'address',
                            render: (address: Address) => formatAddress(address),
                        },
                        {
                            title: 'Action',
                            render: (value: any) => (
                                <Button
                                    onClick={() =>
                                        populateAddressPreviousOrder({
                                            index,
                                            address: value.address,
                                        })
                                    }
                                >
                                    Select address
                                </Button>
                            ),
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            render: (status: CardOrderStatus) => (
                                <Text
                                    type={
                                        status === CardOrderStatus.RECEIVED ? 'success' : 'warning'
                                    }
                                >
                                    {status}
                                </Text>
                            ),
                        },
                    ]
                    const recipientError = (index: number) =>
                        errors.orders && errors.orders[index]
                            ? (errors.orders[index] as FormikErrors<Order>)
                            : null

                    const cardOwnerErrors = (index: number, cardOwnerIndex: number) => {
                        const error = recipientError(index)
                        return error?.cardOwners?.[cardOwnerIndex] as
                            | FormikErrors<Order['cardOwners'][0]>
                            | undefined
                    }

                    const isNiteCrest = values.manufacturer === Manufacturer.NITECREST
                    const deliveryTypeDisabled = isNiteCrest || disableExpressCardOrders

                    return (
                        <Form layout="vertical">
                            <s.FormContainer>
                                <FormItem name="companyId" label="Search for a company">
                                    <Select
                                        name="companyId"
                                        placeholder="Search for a company"
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        onChange={(companyId: string) => {
                                            onCompanySelect(companyId)
                                            setValues({
                                                ...values,
                                                companyId,
                                                companyEmbossLine: '',
                                            })
                                        }}
                                        showArrow={false}
                                        style={{ width: `${inputWidth.huge}` }}
                                    >
                                        {companies.map((company: Company) => (
                                            <Option
                                                key={company.id}
                                                value={company.id}
                                                className="company-search-option"
                                            >
                                                <Text strong>{company.name}</Text>{' '}
                                                <Text type="secondary">({company.id})</Text>
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                                <FormItem
                                    name="companyEmbossLine"
                                    label="Company emboss line"
                                    style={{ width: `${inputWidth.medium}` }}
                                >
                                    <Input name="companyEmbossLine" placeholder="Company name" />
                                </FormItem>
                                <FormItem name="manufacturer" label="Manufacturer">
                                    <Tooltip
                                        title="GPS companies can only use NiteCrest"
                                        open={isGPS && showManufacturerTooltip}
                                    >
                                        <div
                                            data-testid="manufacturer-hover-over"
                                            onMouseEnter={() => setShowManufacturerTooltip(true)}
                                            onMouseLeave={() => setShowManufacturerTooltip(false)}
                                        >
                                            <Select
                                                name="manufacturer"
                                                defaultValue={initialValues.manufacturer}
                                                disabled={isGPS}
                                                data-testid="manufacturer"
                                                style={{ minWidth: `${inputWidth.small}` }}
                                            >
                                                {ActiveManufacturers.map((manufacturer) => (
                                                    <Option key={manufacturer} value={manufacturer}>
                                                        {DisplayManufacturer[manufacturer]}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Tooltip>
                                </FormItem>
                                <FormItem name="fee" label="Add fee">
                                    <Switch name="fee" /> {values.fee ? 'Yes' : 'No'}
                                </FormItem>
                                <s.ImportCSVContainer>
                                    <ImportCSV
                                        companyId={selectedCompany?.id || ''}
                                        isGPS={isGPS}
                                        disabled={!selectedCompany}
                                        setValues={(newValues) => {
                                            setValues(newValues)
                                            validateForm(newValues)
                                        }}
                                    />
                                </s.ImportCSVContainer>
                            </s.FormContainer>
                            <Divider dashed style={{ marginTop: 0 }} />
                            <FieldArray
                                name="orders"
                                render={(arrayHelpers) => (
                                    <ContentContainer>
                                        {values.orders.map((order, index) => {
                                            // NiteCrest only allows express delivery for SE
                                            if (
                                                values.manufacturer === Manufacturer.NITECREST &&
                                                order.address?.country === 'SE' &&
                                                order.deliveryType === DeliveryType.STANDARD
                                            ) {
                                                setFieldValue(
                                                    `orders[${index}].deliveryType`,
                                                    DeliveryType.EXPRESS
                                                )
                                            }
                                            // NiteCrest only allows standard delivery for non SE
                                            if (
                                                values.manufacturer === Manufacturer.NITECREST &&
                                                order.address?.country !== 'SE' &&
                                                order.deliveryType === DeliveryType.EXPRESS
                                            ) {
                                                setFieldValue(
                                                    `orders[${index}].deliveryType`,
                                                    DeliveryType.STANDARD
                                                )
                                            }
                                            return (
                                                <Card
                                                    key={index}
                                                    title={
                                                        <s.TitleContainer>
                                                            <s.ListItem>
                                                                Card order {index + 1}
                                                            </s.ListItem>
                                                            <Button
                                                                type="link"
                                                                size="small"
                                                                danger
                                                                data-testid="delete-order-button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(index)
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </s.TitleContainer>
                                                    }
                                                >
                                                    <s.RowContainer key={index}>
                                                        <FormItem
                                                            name={`orders[${index}].attention`}
                                                            label="Recipient name"
                                                            help={
                                                                recipientError(index)?.attention ||
                                                                ''
                                                            }
                                                            hasFeedback={false}
                                                            validateStatus={
                                                                recipientError(index)?.attention
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].attention`}
                                                                placeholder="Recipient name"
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].phone`}
                                                            label="Recipient phone number"
                                                            help={
                                                                recipientError(index)?.phone || ''
                                                            }
                                                            hasFeedback={false}
                                                            validateStatus={
                                                                recipientError(index)?.phone
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].phone`}
                                                                placeholder="Recipient phone number"
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].deliveryType`}
                                                            label="Delivery"
                                                            help={
                                                                recipientError(index)
                                                                    ?.deliveryType || ''
                                                            }
                                                            hasFeedback={false}
                                                            validateStatus={
                                                                recipientError(index)?.deliveryType
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={deliveryDisabledTooltipTitle}
                                                                open={
                                                                    deliveryTypeDisabled &&
                                                                    showDeliveryTooltip
                                                                }
                                                            >
                                                                <div
                                                                    data-testid="delivery-hover-over"
                                                                    onMouseEnter={() =>
                                                                        setShowDeliveryTooltip(true)
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        setShowDeliveryTooltip(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <Select
                                                                        name={`orders[${index}].deliveryType`}
                                                                        data-testid={`orders[${index}].deliveryType`}
                                                                        defaultValue={
                                                                            DeliveryType.STANDARD
                                                                        }
                                                                        disabled={
                                                                            deliveryTypeDisabled
                                                                        }
                                                                    >
                                                                        {Object.keys(
                                                                            DeliveryType
                                                                        ).map((deliveryType) => (
                                                                            <Option
                                                                                key={deliveryType}
                                                                                value={deliveryType}
                                                                            >
                                                                                {
                                                                                    DisplayDeliveryType[
                                                                                        deliveryType as DeliveryType
                                                                                    ]
                                                                                }
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </Tooltip>
                                                        </FormItem>
                                                    </s.RowContainer>
                                                    <s.RowContainer>
                                                        <FormItem
                                                            name={`orders[${index}].address.addressLine1`}
                                                            hasFeedback
                                                            label="Address line 1"
                                                            validateStatus={
                                                                recipientError(index)?.address
                                                                    ?.addressLine1
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.addressLine1`}
                                                                placeholder="Address line 1"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].address.addressLine2`}
                                                            hasFeedback
                                                            label="Address line 2"
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.addressLine2`}
                                                                placeholder="Address line 2"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].address.postalCode`}
                                                            hasFeedback
                                                            label="Postal Code"
                                                            validateStatus={
                                                                recipientError(index)?.address
                                                                    ?.postalCode
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.postalCode`}
                                                                placeholder="Postal Code"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].address.locality`}
                                                            hasFeedback
                                                            label="City"
                                                            validateStatus={
                                                                recipientError(index)?.address
                                                                    ?.locality
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.locality`}
                                                                placeholder="City"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].address.region`}
                                                            hasFeedback
                                                            label="Region"
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.region`}
                                                                placeholder="Region"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                        <FormItem
                                                            name={`orders[${index}].address.country`}
                                                            hasFeedback
                                                            label="Country Code"
                                                            validateStatus={
                                                                recipientError(index)?.address
                                                                    ?.country
                                                                    ? 'error'
                                                                    : 'success'
                                                            }
                                                        >
                                                            <Input
                                                                name={`orders[${index}].address.country`}
                                                                placeholder="Country Code"
                                                                onBlur={() => checkAddresses()}
                                                            />
                                                        </FormItem>
                                                    </s.RowContainer>
                                                    <Button
                                                        type="link"
                                                        style={{ padding: 0 }}
                                                        onClick={() => populateAddress(index)}
                                                    >
                                                        Auto-fill company address
                                                    </Button>
                                                    <br />
                                                    {selectedCompany?.id &&
                                                        orders &&
                                                        orders?.cardOrders &&
                                                        orders?.cardOrders.length > 0 && (
                                                            <Button
                                                                type="link"
                                                                style={{ padding: 0 }}
                                                                onClick={() =>
                                                                    setShowPreviousOrdersModal(true)
                                                                }
                                                            >
                                                                View previous order addresses
                                                            </Button>
                                                        )}

                                                    <Modal
                                                        open={showPreviousOrdersModal}
                                                        onCancel={() =>
                                                            setShowPreviousOrdersModal(false)
                                                        }
                                                        footer={null}
                                                        width={400}
                                                        centered
                                                    >
                                                        {orders &&
                                                            orders?.cardOrders &&
                                                            orders?.cardOrders.length > 0 && (
                                                                <Col>
                                                                    <Row
                                                                        style={{ marginBottom: 8 }}
                                                                    >
                                                                        <AntdSwitch
                                                                            onChange={(val) =>
                                                                                setShowOnlyReceived(
                                                                                    val
                                                                                )
                                                                            }
                                                                        />
                                                                        <Label
                                                                            style={{
                                                                                marginLeft: 8,
                                                                            }}
                                                                        >
                                                                            Show only received
                                                                            orders
                                                                        </Label>
                                                                    </Row>
                                                                    <Row>
                                                                        <Table
                                                                            dataSource={
                                                                                showOnlyReceived
                                                                                    ? orders?.cardOrders.filter(
                                                                                          (
                                                                                              cardOrder
                                                                                          ) =>
                                                                                              cardOrder.status ===
                                                                                              CardOrderStatus.RECEIVED
                                                                                      )
                                                                                    : orders?.cardOrders
                                                                            }
                                                                            columns={columns(index)}
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                        />
                                                                    </Row>
                                                                </Col>
                                                            )}
                                                    </Modal>

                                                    <Divider />
                                                    <FieldArray
                                                        name={`orders[${index}].cardOwners`}
                                                        render={(nameArrayHelpers) => (
                                                            <>
                                                                {order.cardOwners.map(
                                                                    (cardOwner, cardOwnerIndex) => (
                                                                        <Space key={cardOwnerIndex}>
                                                                            <s.ListItem>
                                                                                <Text>
                                                                                    {cardOwnerIndex +
                                                                                        1}
                                                                                    .
                                                                                </Text>
                                                                            </s.ListItem>
                                                                            <FormItem
                                                                                initialValue={
                                                                                    cardOwner.employeeId
                                                                                }
                                                                                name={`orders[${index}].cardOwners[${cardOwnerIndex}].employeeId`}
                                                                                hasFeedback
                                                                                label={`Employee `}
                                                                                style={{
                                                                                    width: `${inputWidth.huge}`,
                                                                                }}
                                                                                validateStatus={
                                                                                    cardOwnerErrors(
                                                                                        index,
                                                                                        cardOwnerIndex
                                                                                    )?.employeeId
                                                                                        ? 'error'
                                                                                        : 'success'
                                                                                }
                                                                            >
                                                                                <Select
                                                                                    name={`orders[${index}].cardOwners[${cardOwnerIndex}].employeeId`}
                                                                                    placeholder="Search for an employee"
                                                                                    allowClear
                                                                                    loading={
                                                                                        !employees
                                                                                    }
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(
                                                                                        input,
                                                                                        option
                                                                                    ) => {
                                                                                        return option?.children
                                                                                            ? (
                                                                                                  option!.children[0].props.children.join(
                                                                                                      ' '
                                                                                                  ) as unknown as string
                                                                                              )
                                                                                                  .toLowerCase()
                                                                                                  .includes(
                                                                                                      input.toLowerCase()
                                                                                                  ) ||
                                                                                                  (
                                                                                                      option!
                                                                                                          .children[2]
                                                                                                          .props
                                                                                                          .children[1] as unknown as string
                                                                                                  )
                                                                                                      .toLowerCase()
                                                                                                      .includes(
                                                                                                          input.toLowerCase()
                                                                                                      )
                                                                                            : false
                                                                                    }}
                                                                                    showSearch
                                                                                    defaultActiveFirstOption={
                                                                                        false
                                                                                    }
                                                                                    onChange={(
                                                                                        selectedEmployeeId: string
                                                                                    ) => {
                                                                                        const selectedEmployee =
                                                                                            employees.find(
                                                                                                (
                                                                                                    employee
                                                                                                ) =>
                                                                                                    employee.id ===
                                                                                                    selectedEmployeeId
                                                                                            )

                                                                                        if (
                                                                                            !selectedEmployee
                                                                                        ) {
                                                                                            // there is no selectedEmployee on clear action
                                                                                            return
                                                                                        }

                                                                                        const cardOwnerName = `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`

                                                                                        nameArrayHelpers.replace(
                                                                                            cardOwnerIndex,
                                                                                            {
                                                                                                employeeId:
                                                                                                    selectedEmployeeId,
                                                                                                name: cardOwnerName,
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                    showArrow={
                                                                                        false
                                                                                    }
                                                                                    style={{
                                                                                        width: `${inputWidth.huge}`,
                                                                                    }}
                                                                                >
                                                                                    {(
                                                                                        employees ||
                                                                                        []
                                                                                    ).map(
                                                                                        (
                                                                                            employee: Employee
                                                                                        ) => (
                                                                                            <Option
                                                                                                value={
                                                                                                    employee.id ??
                                                                                                    ''
                                                                                                }
                                                                                                key={
                                                                                                    employee.id
                                                                                                }
                                                                                                className="employee-search-option"
                                                                                            >
                                                                                                <Text
                                                                                                    strong
                                                                                                >
                                                                                                    {
                                                                                                        employee.firstName
                                                                                                    }{' '}
                                                                                                    {
                                                                                                        employee.lastName
                                                                                                    }
                                                                                                </Text>{' '}
                                                                                                <Text type="secondary">
                                                                                                    (
                                                                                                    {
                                                                                                        employee.id
                                                                                                    }

                                                                                                    )
                                                                                                </Text>
                                                                                            </Option>
                                                                                        )
                                                                                    )}
                                                                                </Select>
                                                                            </FormItem>
                                                                            <FormItem
                                                                                initialValue={
                                                                                    cardOwner.name
                                                                                }
                                                                                name={`orders[${index}].cardOwners[${cardOwnerIndex}].name`}
                                                                                hasFeedback
                                                                                label={`Name on Card`}
                                                                                style={{
                                                                                    width: `${inputWidth.medium}`,
                                                                                }}
                                                                                validateStatus={
                                                                                    cardOwnerErrors(
                                                                                        index,
                                                                                        cardOwnerIndex
                                                                                    )?.name
                                                                                        ? 'error'
                                                                                        : 'success'
                                                                                }
                                                                            >
                                                                                <Input
                                                                                    defaultValue={
                                                                                        cardOwner.name
                                                                                    }
                                                                                    name={`orders[${index}].cardOwners[${cardOwnerIndex}].name`}
                                                                                    value={
                                                                                        cardOwner.name
                                                                                    }
                                                                                />
                                                                            </FormItem>

                                                                            <s.DeleteButton
                                                                                type="link"
                                                                                size="small"
                                                                                danger
                                                                                data-testid="delete-name-button"
                                                                                onClick={() => {
                                                                                    nameArrayHelpers.remove(
                                                                                        cardOwnerIndex
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </s.DeleteButton>
                                                                        </Space>
                                                                    )
                                                                )}
                                                                <Button
                                                                    type="link"
                                                                    style={{
                                                                        padding: 0,
                                                                    }}
                                                                    onClick={() =>
                                                                        nameArrayHelpers.push({
                                                                            employeeId: '',
                                                                            name: '',
                                                                        })
                                                                    }
                                                                >
                                                                    + Add card to order
                                                                </Button>
                                                            </>
                                                        )}
                                                    />
                                                </Card>
                                            )
                                        })}
                                        <s.AddOrderButtonContainer>
                                            <s.AddOrderButton
                                                type="dashed"
                                                data-testid="add-order-button"
                                                disabled={!selectedCompany}
                                                onClick={() => {
                                                    arrayHelpers.push(defaultOrder)
                                                    const newValues = {
                                                        ...arrayHelpers.form.values,
                                                    }
                                                    newValues.orders = [
                                                        ...newValues.orders,
                                                        { ...defaultOrder },
                                                    ]
                                                    arrayHelpers.form.setValues(newValues)
                                                }}
                                            >
                                                + Add order
                                            </s.AddOrderButton>
                                        </s.AddOrderButtonContainer>
                                    </ContentContainer>
                                )}
                            />
                            <s.ButtonContainer>
                                <Space>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={not(isValid)}
                                        loading={isSubmitting}
                                        data-testid="submit-button"
                                        onClick={() =>
                                            Modal.confirm({
                                                onOk: () => {
                                                    onOrderSubmit(values, isGPS)
                                                    resetForm()
                                                },
                                                onCancel: () => {
                                                    setSubmitting(false)
                                                },
                                                centered: true,
                                                width: '900px',
                                                title: 'Send orders to processing',
                                                content: (
                                                    <ConfirmOrder
                                                        company={selectedCompany}
                                                        companyEmbossLine={values.companyEmbossLine}
                                                        manufacturer={values.manufacturer}
                                                        fee={values.fee}
                                                        orders={values.orders}
                                                    />
                                                ),
                                                okButtonProps: {
                                                    type: 'primary',
                                                },
                                                okText: 'Send all to processing',
                                                cancelText: 'Back to edit order',
                                            })
                                        }
                                    >
                                        Confirm order details
                                    </Button>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            onCancelClick()
                                            resetForm()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Space>
                            </s.ButtonContainer>
                        </Form>
                    )
                }}
            </Formik>
        </s.Card>
    )
}

const CreateCustomOrderContainer: FC<
    React.PropsWithChildren<{ setCustomOrderActive: (value: boolean) => void }>
> = ({ setCustomOrderActive }) => {
    const enableExpressCardOrders = useHasPermissions(['deimos:express-card-orders'])

    const [query, setQuery] = useState('')
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const { data: searchedCompanies } = useGetCompanies(query)
    const companies = searchedCompanies || []
    const selectedCompany = companies.find((company: Company) => company.id === selectedCompanyId)
    const {
        mutations: { createOrders },
    } = useOrders({ companyId: selectedCompanyId })
    const employees = useEmployees(selectedCompanyId)

    const handleSearch = (value: string): void => setQuery(value)

    const onCancelClick = () => {
        setCustomOrderActive(false)
        setQuery('')
    }
    const onOrderSubmit = async (values: CustomOrderFormValues, isGPS: boolean) => {
        try {
            await createOrders(mapValuesToRequest(values, isGPS, enableExpressCardOrders))
            notification.info({ message: 'Orders successfully created' })
        } catch (e) {
            notification.error({
                message: `Something went wrong while creating the orders`,
                description: (e as Error).message,
            })
        }
        setCustomOrderActive(false)
        setQuery('')
    }
    const onCompanySelect = (companyId: string) => {
        setSelectedCompanyId(companyId)
    }

    return (
        <CustomCardOrder
            initialValues={{
                companyId: selectedCompany?.id || '',
                orders: [],
                manufacturer: Manufacturer.IDEMIA,
                companyEmbossLine: selectedCompany?.name || '',
                fee: false,
            }}
            companies={companies}
            selectedCompany={selectedCompany}
            employees={employees}
            disableExpressCardOrders={!enableExpressCardOrders}
            handleSearch={handleSearch}
            onCancelClick={onCancelClick}
            onOrderSubmit={onOrderSubmit}
            onCompanySelect={onCompanySelect}
        />
    )
}

export default CreateCustomOrderContainer
