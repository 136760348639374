import { FC, useState } from 'react'
import { Card, message, Button, Upload, Space, Descriptions, Table } from 'antd'
import { PageContentLayout } from '../layout-containers'
import { UploadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import { adjustBalance } from '../../services/prospero/companies'
import validateUuid from 'uuid-validate'
import { MoonRequestError } from '@pleo-io/bff-moon-clients'

interface CsvSummary {
    totalDebitAdjustment: Record<string, number>
    totalCreditAdjustment: Record<string, number>
    count: number
}

interface AdjustmentResult {
    successfulCount: number
    failedCount: number
    failedEntries: FailedEntry[]
}

interface CsvEntry {
    company_id: string
    amount: number
    currency: string
    note: string
}

interface FailedEntry extends CsvEntry {
    response_status_code?: number
    ersa_status?: string
    phobos_status?: string
}

const validateCsv = (data: CsvEntry[]): boolean => {
    return data.every((entry) => validateUuid(entry.company_id) && !isNaN(entry.amount))
}

const BatchBalanceAdjustment: FC = () => {
    const [csvData, setCsvData] = useState<CsvEntry[]>([])
    const [csvSummary, setCsvSummary] = useState<CsvSummary | null>(null)
    const [adjustmentResult, setAdjustmentResult] = useState<AdjustmentResult | null>(null)
    const [processing, setProcessing] = useState<boolean>(false)
    const [processingText, setProcessingText] = useState<string>('')

    const handleUpload = (file: File) => {
        Papa.parse<CsvEntry>(file, {
            header: true,
            complete: (results) => {
                const data = results.data
                if (validateCsv(data)) {
                    const totalDebitAdjustment: Record<string, number> = {}
                    const totalCreditAdjustment: Record<string, number> = {}

                    data.forEach((entry) => {
                        if (entry.amount < 0) {
                            totalDebitAdjustment[entry.currency] =
                                (totalDebitAdjustment[entry.currency] || 0) + Number(entry.amount)
                        } else {
                            totalCreditAdjustment[entry.currency] =
                                (totalCreditAdjustment[entry.currency] || 0) + Number(entry.amount)
                        }
                    })

                    const count = data.length
                    setCsvData(data)
                    setCsvSummary({ totalDebitAdjustment, totalCreditAdjustment, count })
                } else {
                    message.error(
                        'CSV validation failed. Ensure all company_id are UUIDs and all amounts are numbers.'
                    )
                }
            },
            error: (error) => {
                message.error(`Error reading CSV file: ${error.message}`)
            },
        })
    }

    async function processCsvLinesBatched(data: CsvEntry[], limit: number) {
        let position = 0
        const result: AdjustmentResult = { successfulCount: 0, failedCount: 0, failedEntries: [] }
        while (position < data.length) {
            const itemsForBatch = data.slice(position, position + limit)
            setProcessingText(`Processing batch of ${position + limit} / ${data.length}`)
            await Promise.allSettled(
                itemsForBatch.map(({ company_id, amount, currency, note }) =>
                    adjustBalance(company_id, amount, currency, note).then(
                        (response) => {
                            if (
                                response.status === 'fulfilled' &&
                                response.ersaStatus === 'SETTLED' &&
                                response.phobosStatus === 'SETTLED'
                            ) {
                                result.successfulCount++
                            } else {
                                result.failedCount++
                                result.failedEntries.push({
                                    ...{ company_id, amount, currency, note },
                                    response_status_code: 200,
                                    ersa_status: response.ersaStatus,
                                    phobos_status: response.phobosStatus,
                                })
                            }
                        },
                        (error) => {
                            let statusCode = undefined
                            if (error instanceof MoonRequestError) {
                                statusCode = error.httpStatus
                            }
                            result.failedCount++
                            result.failedEntries.push({
                                ...{ company_id, amount, currency, note },
                                response_status_code: statusCode,
                            })
                        }
                    )
                )
            )
            position += limit
        }
        return result
    }

    const handleAdjustBalances = async () => {
        setProcessing(true)
        const result: AdjustmentResult = await processCsvLinesBatched(csvData, 10)

        setProcessing(false)
        setProcessingText('')
        setAdjustmentResult(result)
    }

    const downloadFailedEntries = () => {
        const csv = Papa.unparse(adjustmentResult?.failedEntries || [])
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'failed_entries.csv'
        link.click()
    }

    const adjustmentResultColumns = [
        { title: 'Company ID', dataIndex: 'company_id', key: 'company_id' },
        { title: 'Amount', dataIndex: 'amount', key: 'amount' },
        { title: 'Currency', dataIndex: 'currency', key: 'currency' },
        { title: 'Note', dataIndex: 'note', key: 'note' },
        {
            title: 'Response Status Code',
            dataIndex: 'response_status_code',
            key: 'response_status_code',
        },
        { title: 'ERSA Status', dataIndex: 'ersa_status', key: 'ersa_status' },
        { title: 'Phobos Status', dataIndex: 'phobos_status', key: 'phobos_status' },
    ]

    return (
        <PageContentLayout>
            <Card title="Batch Balance Adjustment">
                <div>
                    <h3>
                        This page will batch add or subtract amount from company balance without
                        generating any expenses.
                    </h3>

                    <p>
                        Remarks:
                        <br />
                        1. upload a csv with column header with the following 4 columns:
                        "company_id", "amount", "currency", "note". <br />
                        2. For "amount", Positive value = credit. Negative (-) value = debit. <br />
                        3. If the operation FAILED but the Response Status Code is 200, try again
                        with another "note" <br />
                    </p>
                </div>
                <Upload
                    accept=".csv"
                    beforeUpload={(file) => {
                        handleUpload(file)
                        return false
                    }}
                    showUploadList={false}
                >
                    <Button icon={<UploadOutlined />} style={{ marginTop: '16px' }}>
                        Upload CSV
                    </Button>
                </Upload>
                {csvSummary && (
                    <Descriptions
                        title="CSV Summary"
                        bordered
                        column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1 }}
                        size="small"
                        style={{ marginTop: '32px' }}
                    >
                        <Descriptions.Item label="Total Debit Adjustment">
                            {Object.entries(csvSummary.totalDebitAdjustment).map(
                                ([currency, amount]) => (
                                    <div key={currency}>{`${currency}: ${amount}`}</div>
                                )
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Credit Adjustment">
                            {Object.entries(csvSummary.totalCreditAdjustment).map(
                                ([currency, amount]) => (
                                    <div key={currency}>{`${currency}: ${amount}`}</div>
                                )
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Count">{csvSummary.count}</Descriptions.Item>
                    </Descriptions>
                )}
                {csvSummary && (
                    <Space>
                        <Button
                            type="primary"
                            onClick={handleAdjustBalances}
                            disabled={processing}
                            style={{ marginTop: '16px' }}
                        >
                            Confirm Balance Adjustment
                        </Button>
                    </Space>
                )}
                {processing && <p>{processingText}</p>}
                {adjustmentResult && (
                    <>
                        <Descriptions
                            title="Adjustment Result"
                            bordered
                            column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1 }}
                            size="small"
                            style={{ marginTop: '32px' }}
                        >
                            <Descriptions.Item label="Successful Adjustments">
                                {adjustmentResult.successfulCount}
                            </Descriptions.Item>
                            <Descriptions.Item label="Failed Adjustments">
                                {adjustmentResult.failedCount}
                            </Descriptions.Item>
                        </Descriptions>
                        {adjustmentResult.failedCount > 0 && (
                            <>
                                <Table
                                    dataSource={adjustmentResult.failedEntries}
                                    columns={adjustmentResultColumns}
                                    rowKey="company_id"
                                    pagination={false}
                                    style={{ marginTop: '16px' }}
                                />
                                <Button
                                    onClick={downloadFailedEntries}
                                    type="primary"
                                    style={{ marginTop: '16px' }}
                                >
                                    Download Failed Entries
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Card>
        </PageContentLayout>
    )
}

export default BatchBalanceAdjustment
