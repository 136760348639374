import React, { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Descriptions, message, Modal, Result, Space, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { mutate } from 'swr'
import { pickBy, identity } from 'ramda'

import type { Company, Address } from 'types/deimos-company'
import type { PartnerEmployee } from 'types/employee'
import type { Partner } from 'types/partner-portal'
import CompanyAddress from 'components/company-address/company-address'
import { getEmojiFlag } from 'countries-list'
import PartnerTabs from './partner-tabs'
import { spacing } from 'theme/tokens'
import Spinner from 'components/content-spinner'
import styled from 'styled-components'
import { useGetCompany } from 'services/deimos/companies'
import { deletePartner } from 'services/deimos/partners'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import EditDetailsForm from './edit-partner-info'
import type { FormValues } from './partner-info-validation-schema'
import { capitalizeFirstLetter } from 'utils/strings'
import { useGetPartner } from 'services/calypso/partner'
import { bff } from 'components/bff-hooks'

const { confirm } = Modal
const { Link, Paragraph, Text } = Typography

export const Admins: FC<
    React.PropsWithChildren<{
        admins?: PartnerEmployee[]
        onUserClick: (employee: PartnerEmployee) => void
    }>
> = ({ admins, onUserClick }) => {
    const [showedAdmins, setShowedAdmins] = useState(4)
    const hasAdmins = admins?.length ?? 0
    const hasMoreAdmins = showedAdmins < (admins?.length ?? 0)
    const hasLessAdmins = (showedAdmins === admins?.length ?? false) && showedAdmins > 4
    const showMoreAdmins = () => setShowedAdmins(admins?.length ?? 0)
    const showLessAdmins = () => setShowedAdmins(4)

    return (
        <>
            {hasAdmins ? (
                <AdminContainer>
                    {admins?.slice(0, showedAdmins).map((admin, index) => (
                        <InfoRowItem key={index} data-testid="admin">
                            <Link onClick={() => onUserClick(admin)} data-testid="admin-name">
                                {admin.firstName} {admin.lastName ?? ''}
                            </Link>
                            {window.innerWidth >= 2560 && (
                                <>
                                    <Text>:</Text>
                                    <BreakText copyable type="secondary">
                                        {admin.email}
                                    </BreakText>
                                </>
                            )}
                        </InfoRowItem>
                    ))}
                    {hasMoreAdmins && (
                        <StyledButton type="link" onClick={showMoreAdmins}>
                            ...more
                        </StyledButton>
                    )}
                    {hasLessAdmins && (
                        <StyledButton type="link" onClick={showLessAdmins}>
                            ...less
                        </StyledButton>
                    )}
                </AdminContainer>
            ) : (
                <Text type="secondary">None for this partner</Text>
            )}
        </>
    )
}

const StyledButton = styled(Button)`
    padding: 0;
    height: 22px;
`

interface Props {
    error?: {}
    goBack: () => void
    goToOwnCompany: (companyId: string) => void
    goToSearch: () => void
    modalActive: boolean
    mutating: boolean
    onEdit: (body: FormValues) => void
    onUserClick: (employee: PartnerEmployee) => void
    ownCompany?: Company
    partner: Partner
    setModalActive: (value: boolean) => void
}

export const PartnerSummary: FC<React.PropsWithChildren<Props>> = ({
    goBack,
    goToOwnCompany,
    goToSearch,
    modalActive,
    mutating,
    onEdit,
    onUserClick,
    ownCompany,
    partner,
    setModalActive,
}) => {
    const { data: employees = [] } = bff.components.partnerEmployees.getPartnerEmployees.useQuery({
        partnerId: partner.id,
    })
    const admins: PartnerEmployee[] = employees ? employees.filter((e) => e.role === 'owner') : []
    const emojiFlag = partner?.address?.country ? getEmojiFlag(partner.address.country) : ''
    const hasBasicComplianceTools = useHasPermissions(['styx'])
    const canManagePartners = useHasPermissions(['partner-manager']) // only enforced in frontend
    const canUpdatePartners = canManagePartners || hasBasicComplianceTools

    const onDeletePartner = () => {
        setModalActive(false)
        confirm({
            icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            title: <Text type="danger">Danger Zone</Text>,
            content: (
                <>
                    <Paragraph>
                        Deleting the partner <Text strong>{partner.name}</Text> will unassign
                        bookkeepers from their clients and deactivate Partner employees
                    </Paragraph>
                    {ownCompany && (
                        <Text>
                            Their company Pleo account ({ownCompany.name}) will remain active
                        </Text>
                    )}
                </>
            ),
            okText: 'Delete Partner',
            okType: 'danger',
            async onOk() {
                const { error, message: msg, success } = await deletePartner(partner.id)
                if (success) {
                    mutate('rest/v1/partners')
                    message.success(`Done! ${partner.name} Deleted!`, 5)
                    goToSearch()
                } else if (error && msg) {
                    message.error(`Error: ${msg}`, 8)
                } else {
                    message.error('Something is not working. Please contact Team Zeus', 8)
                }
            },
            width: 500,
            autoFocusButton: 'cancel',
            maskClosable: true,
        })
    }

    return (
        <PageHeader
            ghost={false}
            onBack={goBack}
            title={
                <Space>
                    {emojiFlag}
                    <Text copyable>{partner.name}</Text>
                </Space>
            }
            extra={
                canUpdatePartners && [
                    <Button onClick={() => setModalActive(true)} key="edit-details-button">
                        Edit details
                    </Button>,
                    <Button danger onClick={onDeletePartner} key="delete-partner-button">
                        Delete partner
                    </Button>,
                ]
            }
        >
            <DetailsContainer>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="ID">
                        <Text copyable>{partner.id}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Reg no.">
                        <Text copyable>{partner.registrationNumber}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Own company">
                        {ownCompany ? (
                            <Link onClick={() => goToOwnCompany(ownCompany?.id)}>
                                {ownCompany.name}
                            </Link>
                        ) : (
                            <Text>N/A</Text>
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Partnership type">
                        <Text>{capitalizeFirstLetter(partner.type || '') || 'Not Provided'}</Text>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Address">
                        {partner.address ? (
                            <CompanyAddress address={partner.address} />
                        ) : (
                            <Text>Not Provided</Text>
                        )}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Admins">
                        <Admins admins={admins} onUserClick={onUserClick} />
                    </Descriptions.Item>
                </Descriptions>
            </DetailsContainer>
            <Modal
                title="Edit partner information"
                open={!!modalActive}
                onCancel={() => setModalActive(false)}
                footer={null}
                width={1000}
                centered
            >
                <EditDetailsForm
                    isLoading={mutating}
                    partner={partner}
                    ownCompany={ownCompany}
                    onSubmit={onEdit}
                />
            </Modal>
        </PageHeader>
    )
}

const PartnerContainer = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data: partner, mutations, error } = useGetPartner(id)
    const { data: ownCompany } = useGetCompany(partner?.ownCompanyId)
    const [modalActive, setModalActive] = useState(false)
    const [mutating, setMutating] = useState(false)
    const goBack = () => navigate(-1)
    const goToOwnCompany = (companyId: string) =>
        navigate(`/customer-success/companies/${companyId}`)
    const goToSearch = () => navigate('/customer-success')
    const onUserClick = (employee: PartnerEmployee) =>
        navigate(`/customer-success/users/${employee.userId}`)

    const handleEditPartner = async ({
        name,
        tradingName,
        registrationNumber,
        ownCompanyId,
        type,
        ...address
    }: FormValues) => {
        // removes undefined properties from address
        const cleanedAddress: Address = pickBy(identity, address)
        const requestBody = {
            name,
            tradingName: tradingName || undefined,
            registrationNumber,
            ownCompanyId,
            address: cleanedAddress,
            type: type || undefined,
        }
        setMutating(true)
        await mutations
            .editPartner(requestBody)
            .then(() => {
                setModalActive(false)
                message.success(`Updated info for ${partner?.name}`)
            })
            .catch((e) => message.error((e as Error).message))
            .finally(() => setMutating(false))
    }

    if (partner) {
        return (
            <>
                <PartnerSummary
                    goBack={goBack}
                    goToOwnCompany={goToOwnCompany}
                    goToSearch={goToSearch}
                    modalActive={modalActive}
                    setModalActive={setModalActive}
                    mutating={mutating}
                    onEdit={handleEditPartner}
                    onUserClick={onUserClick}
                    partner={partner}
                    ownCompany={ownCompany}
                />
                <PartnerTabs partner={partner} />
            </>
        )
    } else if (error) {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle="We had trouble fetching this partner"
                extra={
                    <Button type="primary" onClick={goBack}>
                        Go back
                    </Button>
                }
            />
        )
    } else {
        return <Spinner />
    }
}

const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const BreakText = styled(Text)`
    word-break: break-all;
`

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: ${spacing.space24};
    .ant-descriptions * {
        width: auto;
    }
`

const InfoRowItem = styled.div`
    display: flex;
    align-items: baseline;
    gap: ${spacing.space4};
`

export default PartnerContainer
