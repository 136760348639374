import { Card, Table } from 'antd'
import { bff } from '../bff'
import { formatDate } from 'packages/dates/dates'
import Tooltip from 'antd/lib/tooltip'
import { InfoCircleOutlined } from '@ant-design/icons'

export const OnboardingJourneyDetails = ({ companyId }: { companyId: string }) => {
    const { data, error } = bff.onboardingJourneyDetailsRouter.getData.useQuery({ companyId })

    if (!data || error) {
        return null
    }

    if (data?.tasks.length === 0) {
        return (
            <Card type="inner" title="Onboarding Journey" style={{ margin: '0 24px 24px 0' }}>
                <p>
                    This company is on the old onboarding journey and does not have onboarding
                    details
                </p>
            </Card>
        )
    }

    return (
        <Card type="inner" title="Onboarding Journey" style={{ margin: '0 24px 24px 0' }}>
            <Table
                columns={columns}
                dataSource={data.tasks.map((task) => ({
                    key: task.id,
                    milestone: task.milestone,
                    task: task.label,
                    startedAt: formatDate(task.startedAt),
                    completedAt: formatDate(task.completedAt),
                }))}
                size="small"
            />
        </Card>
    )
}

const columns = [
    {
        title: 'Milestone',
        dataIndex: 'milestone',
        key: 'milestone',
    },
    {
        title: 'Task',
        dataIndex: 'task',
        key: 'task',
    },
    {
        title: () => (
            <>
                Started At{' '}
                <Tooltip title="Started at only available when started from the new Get Started page">
                    <InfoCircleOutlined />
                </Tooltip>
            </>
        ),
        dataIndex: 'startedAt',
        key: 'startedAt',
    },
    {
        title: 'Completed At',
        dataIndex: 'completedAt',
        key: 'completedAt',
    },
]
