import { Button, message, Typography, Checkbox, Form, Input } from 'antd'
import React, { FC, useState } from 'react'
import type { BusinessExplanation } from '@pleo-io/deimos'
import Spinner from 'components/content-spinner'
import { inputWidth, spacing } from 'theme/tokens'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { bff } from 'pages/compliance/bff'

const { TextArea } = Input
const { Title } = Typography

const BUSINESS_EXPLANATION_MAX_LENGTH = 400

type FormValues = BusinessExplanation

const BusinessExplanationContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { id: companyId } = useParams()
    const [form] = Form.useForm()
    const { data: businessExplanation, isFetching: loading } =
        bff.businessExplanation.getBusinessExplanation.useQuery({ companyId })
    const { mutateAsync: updateBusinessExplanation } =
        bff.businessExplanation.updateBusinessExplanation.useMutation()
    const [disableWhileSubmitting, setDisableWhileSubmitting] = useState(false)
    const initialValues: FormValues = {
        explanation: businessExplanation?.explanation ?? '',
        hidden: businessExplanation?.hidden ?? true,
    }

    const updateExplanation = async (values: FormValues) => {
        try {
            setDisableWhileSubmitting(true)
            await updateBusinessExplanation({
                ...values,
                companyId,
            })
            message.info('Successfully saved business explanation')
        } catch (e) {
            message.error(`Failed to save business explanation: ${(e as Error).message}`)
        } finally {
            setDisableWhileSubmitting(false)
        }
    }

    if (!businessExplanation && loading) {
        return <Spinner />
    }

    return (
        <Form form={form} initialValues={initialValues} onFinish={updateExplanation}>
            <BusinessExplanationFormItem
                name="explanation"
                label={<Title level={5}>Business Explanation</Title>}
                colon={false}
                labelAlign="left"
                style={{
                    flexDirection: 'column',
                }}
            >
                <TextArea
                    autoSize={{ minRows: 2 }}
                    showCount
                    maxLength={BUSINESS_EXPLANATION_MAX_LENGTH}
                />
            </BusinessExplanationFormItem>
            <CheckboxFormItem name="hidden" valuePropName="checked">
                <Checkbox>Business explanation is hidden to users</Checkbox>
            </CheckboxFormItem>
            <Button htmlType="submit" type="primary" disabled={disableWhileSubmitting}>
                Save
            </Button>
        </Form>
    )
}

const BusinessExplanationFormItem = styled(Form.Item)`
    margin-bottom: 0;
    width: ${inputWidth.huge};
`
const CheckboxFormItem = styled(Form.Item)`
    margin-bottom: ${spacing.space8};
`

export default BusinessExplanationContainer
