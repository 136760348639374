import { useCallback, useEffect } from 'react'
import { Form, Space, DatePicker, Button, Input, Segmented } from 'antd'
import validateUuid from 'uuid-validate'
import { useSearchParams } from 'react-router-dom'
import dayjs, { type Dayjs } from 'packages/dayjs'

type RolesHistoryFields = {
    userId?: string
    changeType?: 'all' | 'granted' | 'revoked'
    from?: Dayjs
}

type RolesHistoryRequest = {
    userId?: string
    changeType?: 'all' | 'granted' | 'revoked'
    from?: string
}

const fieldNames = ['userId', 'changeType', 'from']

export const RolesHistoryFilters = () => {
    const [form] = Form.useForm<RolesHistoryFields>()
    const [searchParams, setSearchParams] = useSearchParams()

    const updateUrlWithFormValues = (values: RolesHistoryRequest) => {
        const rolesHistoryFieldsKeys = Object.keys(values) as Array<keyof typeof values>
        rolesHistoryFieldsKeys.forEach((key) => {
            const value = values[key]
            if (key === 'changeType' && value === 'all') {
                searchParams.delete(key)
            } else if (value) {
                searchParams.set(key, value)
            } else {
                searchParams.delete(key)
            }
        })
        searchParams.sort()
        setSearchParams(searchParams)
    }

    const onSubmit = (values: RolesHistoryFields) => {
        updateUrlWithFormValues({
            ...values,
            from: formatDateToIsoString(values.from),
        })
    }

    const readParamsFromUrl = useCallback(() => {
        fieldNames.forEach((fieldName) => {
            const value = searchParams.get(fieldName)
            if (value) {
                if (fieldName === 'from') {
                    form.setFieldValue(fieldName, value ? dayjs(value) : undefined)
                } else {
                    form.setFieldValue(fieldName, value)
                }
            }
        })
    }, [form, searchParams])

    useEffect(() => {
        readParamsFromUrl()
    }, [readParamsFromUrl])

    return (
        <Form
            form={form}
            style={{ textAlign: 'left', margin: '0.5rem 1rem' }}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{
                at: dayjs(),
            }}
        >
            <Space align="end" size="large">
                <Form.Item
                    name="userId"
                    label="User ID"
                    rules={[
                        {
                            validator: async (_, value) => {
                                if (value && !validateUuid(value)) {
                                    throw new Error('Invalid format for User ID')
                                }
                            },
                        },
                    ]}
                >
                    <Input allowClear style={{ width: '24rem' }} spellCheck={false} />
                </Form.Item>
                <Form.Item name="changeType" label="Permission changes">
                    <Segmented
                        options={[
                            {
                                label: 'All',
                                value: 'all',
                            },
                            {
                                label: 'Granted',
                                value: 'granted',
                            },
                            {
                                label: 'Revoked',
                                value: 'revoked',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="From" name="from">
                    <DatePicker allowClear={true} disabledDate={(current) => current > dayjs()} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    )
}

const formatDateToIsoString = (date?: Dayjs) => (date ? dayjs(date).toISOString() : '')
