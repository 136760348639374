import type { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import { color, spacing } from 'theme/tokens'
import {
    CheckCircleTwoTone,
    CheckSquareTwoTone,
    MinusCircleTwoTone,
    WarningTwoTone,
} from '@ant-design/icons'
import { Button, message, Tag } from 'antd'

import type { Person } from 'types/styx'
import { Role, VerificationResult } from 'types/styx'
import { getPersonName, getRoleNames } from 'pages/compliance/shared/utils'
import {
    getHasPassedRequiredKycChecks,
    hasMissingKycInfo,
    hasMissingPepInfo,
} from 'pages/compliance/company/people/helpers'
import { useManagement } from 'services/styx/management'
import { useMissingInformation } from 'services/deimos/kyc'

interface StatusIconProps {
    isMissingKycDetails: boolean
    hasPassedRequiredKycChecks: boolean
    isInScope: boolean
}

export const StatusIcon: FC<React.PropsWithChildren<StatusIconProps>> = ({
    isMissingKycDetails,
    hasPassedRequiredKycChecks,
    isInScope,
}) => {
    if (!isInScope) {
        return <MinusCircleTwoTone twoToneColor={color.gray} />
    } else if (isMissingKycDetails && hasPassedRequiredKycChecks) {
        return <CheckSquareTwoTone twoToneColor={color.cyan} />
    } else if (hasPassedRequiredKycChecks) {
        return <CheckCircleTwoTone twoToneColor={color.green} />
    } else {
        return <WarningTwoTone twoToneColor={color.gold} />
    }
}

interface Props {
    person: Person
    addPersonToScope: () => void
    removePersonFromScope: () => void
    isVerified: boolean
}

export const KycStatus: FC<React.PropsWithChildren<Props>> = ({
    person,
    removePersonFromScope,
    addPersonToScope,
    children,
    isVerified,
}) => {
    const name = getPersonName(person.name)
    const roles = getRoleNames(person.roles)
    const isInScope = person.kycPerson
    const isAdmin = person.roles?.includes(Role.ADMIN)
    const ownershipPercentage = person.ownershipPercentage

    const toggleScope = (event: MouseEvent) => {
        // prevents panel from opening
        event.stopPropagation()

        if (isInScope) {
            removePersonFromScope()
        } else {
            addPersonToScope()
        }
    }

    return (
        <PanelHeader>
            {children}
            <span data-testid="name-and-title">
                <strong>{name}</strong>: {roles}
                {isAdmin ? (isVerified ? ' (Verified)' : ' (Not verified)') : null}
                {ownershipPercentage ? ' (ownership: ' + ownershipPercentage + '%)' : ''}
                {hasMissingPepInfo(person) ? (
                    <MissingKycInfoTag>
                        <Tag color="orange">Missing Pep Info</Tag>
                    </MissingKycInfoTag>
                ) : null}
            </span>
            <Button
                data-testid="toggle-scope"
                size="small"
                danger={isInScope}
                onClick={toggleScope}
            >
                {isInScope ? 'Remove from scope' : 'Add to scope'}
            </Button>
        </PanelHeader>
    )
}

const MissingKycInfoTag = styled.div`
    display: inline-block;
    margin-left: ${spacing.space8};
`

const PanelHeader = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${spacing.space8};
`

const KycStatusContainer: FC<
    React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>
> = ({ person, refetchPeople }) => {
    const { mutations } = useManagement()
    const { data: missingKycInformation } = useMissingInformation()
    const isVerified: boolean = person.verification?.result === VerificationResult.PASS ?? false
    const hasPassedRequiredKycChecks = getHasPassedRequiredKycChecks(
        {
            pepChecks: person.pepChecks,
            sisChecks: person.sisChecks,
            idvChecks: person.idvChecks,
            ekycChecks: person.ekycChecks,
        },
        isVerified
    )

    const addPersonToScope = async () => {
        try {
            await mutations.addToScope(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to add to scope: ${(e as Error).message}`)
        }
    }

    const removePersonFromScope = async () => {
        try {
            await mutations.removeFromScope(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to add to scope: ${(e as Error).message}`)
        }
    }

    return (
        <KycStatus
            person={person}
            addPersonToScope={addPersonToScope}
            removePersonFromScope={removePersonFromScope}
            isVerified={isVerified}
        >
            <StatusIcon
                isInScope={!!person.kycPerson}
                isMissingKycDetails={hasMissingKycInfo(person.id, missingKycInformation)}
                hasPassedRequiredKycChecks={hasPassedRequiredKycChecks}
            />
        </KycStatus>
    )
}

export default KycStatusContainer
