import { PageHeader } from '@ant-design/pro-layout'
import { Card, Layout, Table, Tag } from 'antd'
import { PageContentLayout } from 'components/layout-containers'

import { bff } from '../bff'

export const PermissionRequests = () => {
    const {
        data: permissionChangeRequests,
        error: permissionChangeRequestsError,
        isLoading: permissionChangeRequestsLoading,
    } = bff.permissions.listPermissionsChangeRequests.useQuery(
        { params: { status: ['PENDING', 'APPROVED', 'DENIED'] } },
        { refetchOnWindowFocus: false }
    )

    const { data: oktaPermissions } = bff.permissions.listAllPermissions.useQuery(
        { params: { include_deleted: false } },
        { refetchOnWindowFocus: false }
    )

    const columns = [
        {
            title: 'Requester',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Request ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'IDs to Add',
            dataIndex: 'idsToAdd',
            key: 'idsToAdd',
            render: (text: string[]) => {
                return text.map((id) => {
                    const permission = oktaPermissions?.find((perm) => perm.id === id)
                    return `${permission?.tag} - (${permission?.description})`
                })
            },
        },
        {
            title: 'IDs to Remove',
            dataIndex: 'idsToRemove',
            key: 'idsToRemove',
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                if (text === 'PENDING') {
                    return <Tag style={{ backgroundColor: 'grey' }}>{text.toLocaleLowerCase()}</Tag>
                } else if (text === 'APPROVED') {
                    return (
                        <Tag style={{ backgroundColor: 'green' }}>{text.toLocaleLowerCase()}</Tag>
                    )
                } else if (text === 'DENIED') {
                    return <Tag style={{ backgroundColor: 'red' }}>{text.toLocaleLowerCase()}</Tag>
                }
            },
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text: string) => new Date(text).toLocaleString(),
        },
    ]

    if (permissionChangeRequestsError) {
        return <div>Error: {permissionChangeRequestsError.message}</div>
    }

    return (
        <PageContentLayout>
            <PageHeader title="Manage permission requests" />
            <Layout>
                <Layout.Content>
                    <Card
                        style={{ marginBottom: '1rem' }}
                        type="inner"
                        title="Permission Requests"
                        loading={permissionChangeRequestsLoading}
                    >
                        <Table dataSource={permissionChangeRequests?.data} columns={columns} />
                    </Card>
                </Layout.Content>
            </Layout>
        </PageContentLayout>
    )
}
