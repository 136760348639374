import React, { FC, ReactText, useState } from 'react'
import { Button, Card, notification, Result, Space, Table, Typography } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import { Formik } from 'formik'
import { Select } from 'formik-antd'
import { inputWidth } from 'theme/tokens'

import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons'
import type { WalletUnloadSummary } from '@pleo-io/deimos'

import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadsStatusDisplay, WalletUnloadsStatusAPI, fileTypeLabel } from './utils'
import { PageContentLayout } from 'components/layout-containers'
import { useWalletUnloadSummaries, useWalletUnloadSummaryDocumentUrl } from 'services/deimos/wallet'
import dayjs from 'packages/dayjs'
import type { OnboardingSource } from 'types/deimos-company'
import SupportedCountrySelect from 'components/supported-countries-select'

import { useWalletUnloadsFiltered } from './use-wallet-unloads-filtered'
import { useWalletUnloadsCount } from './use-wallet-unloads-count'
import { renderColumns } from './render-columns'

const { Option } = Select

const { Text } = Typography

const Summary: FC<React.PropsWithChildren<{ id: string }>> = ({ id }) => {
    const { data } = useWalletUnloadSummaryDocumentUrl(id)
    if (data)
        return (
            <div>
                <PaperClipOutlined />{' '}
                <a href={data?.url} target="_">
                    {fileTypeLabel(data)}
                </a>
            </div>
        )
    else
        return (
            <div>
                <LoadingOutlined /> Loading
            </div>
        )
}

export const Exports: FC<React.PropsWithChildren<unknown>> = () => {
    const { data: walletUnloadSummaries, error, isValidating } = useWalletUnloadSummaries()
    const exportedColumns: ColumnProps<WalletUnloadSummary>[] = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
            render: (date) => <Text>{dayjs(date).format('DD-MM-YY HH:mm')}</Text>,
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            render: (bank) => <Text>{bank}</Text>,
        },
        {
            title: 'Document',
            dataIndex: 'id',
            render: (id) => <Summary id={id} />,
        },
        {
            title: 'Owner',
            dataIndex: 'createdBy',
            render: (owner) => <Text>{owner}</Text>,
        },
    ]

    if (error) {
        return (
            <Result
                status="error"
                title="Something went wrong"
                subTitle="Try refreshing. If it's still not working please contact Team Atlas"
            />
        )
    }

    return (
        <Table
            dataSource={walletUnloadSummaries}
            columns={exportedColumns}
            loading={isValidating}
            rowKey="id"
        />
    )
}

interface WalletUnloadsProps {
    status: WalletUnloadsStatusAPI
    countryFilter: string[]
    onboardingViaFilter: OnboardingSource[]
}

export const WalletUnloads: FC<React.PropsWithChildren<WalletUnloadsProps>> = ({
    status,
    countryFilter,
    onboardingViaFilter,
}) => {
    const {
        data: walletUnloads,
        error,
        mutations: { exportWalletUnloads },
    } = useWalletUnloadsFiltered(status, { countryFilter, onboardingViaFilter })

    const [selectedWalletUnloadIds, setSelectedWalletUnloadIds] = useState<string[]>([])
    const [disableExportButton, setDisableExportButton] = useState(false)
    const onSelectChange = (selectedRowKeys: ReactText[]) => {
        setSelectedWalletUnloadIds(selectedRowKeys as string[])
    }

    const download = (url: string) => {
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.click()
    }

    const onExport = async () => {
        try {
            setDisableExportButton(true)
            const response = await exportWalletUnloads(selectedWalletUnloadIds)

            // If there is only one file exported auto-download it
            const numberOfExportedFiles = Object.keys(response.urls).length
            if (numberOfExportedFiles === 1) {
                download(response.urls[Object.keys(response.urls)[0]])
            }

            setSelectedWalletUnloadIds([])
            // Exported files list
            notification.success({
                message: 'Wallet unloads successfully exported',
                description:
                    Object.keys(response.urls).length +
                    ' files were exported and can be found in the Exported tab',
            })
        } catch (e) {
            notification.error({
                message: 'Failed to export wallet unloads',
                description: (e as Error).message,
            })
        } finally {
            setDisableExportButton(false)
        }
    }
    const rowSelection = { selectedRowKeys: selectedWalletUnloadIds, onChange: onSelectChange }
    const shouldDisableExportButton = selectedWalletUnloadIds.length === 0

    if (error) {
        return (
            <Result
                status="error"
                title="Something went wrong"
                subTitle="Try refreshing. If it's still not working please contact Team Atlas"
            />
        )
    }

    if (
        status === WalletUnloadResponseStatus.UNLOADED ||
        status === WalletUnloadResponseStatus.EXPORTED
    ) {
        return (
            <>
                <Table
                    dataSource={walletUnloads}
                    rowKey="id"
                    rowSelection={rowSelection}
                    loading={!walletUnloads}
                >
                    {renderColumns(status)}
                </Table>
                <Button
                    type="primary"
                    onClick={onExport}
                    disabled={shouldDisableExportButton || disableExportButton}
                >
                    Export
                </Button>
            </>
        )
    }

    return (
        <Table
            dataSource={walletUnloads}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            loading={!walletUnloads}
        >
            {renderColumns(status)}
        </Table>
    )
}

const FilterPanel: FC<{
    onCountryFilter: (value: string[]) => void
    onOnboardingViaFilter: (value: OnboardingSource[]) => void
}> = ({ onCountryFilter, onOnboardingViaFilter }) => {
    return (
        <Space size={'middle'}>
            <Text>{'Filter by:'}</Text>

            <Formik<{ country: string[]; onboardedVia: OnboardingSource[] }>
                enableReinitialize={true}
                onSubmit={() => {}}
                initialValues={{
                    country: [],
                    onboardedVia: [],
                }}
            >
                <Space size="small">
                    <SupportedCountrySelect
                        mode="multiple"
                        name={'country'}
                        showSearch
                        allowClear={true}
                        style={{ minWidth: `${inputWidth.medium}` }}
                        onChange={onCountryFilter}
                        placeholder="Select country"
                    />

                    <Select
                        mode="multiple"
                        name="onboardedVia"
                        allowClear
                        style={{ minWidth: `${inputWidth.medium}` }}
                        onChange={onOnboardingViaFilter}
                        placeholder="Select Onboarded via"
                    >
                        <Option key="B4B" value="B4B" label="B4B">
                            <Text>B4B</Text>
                        </Option>
                        <Option key="PLEO" value="PLEO" label="PLEO">
                            <Text>PLEO</Text>
                        </Option>
                    </Select>
                </Space>
            </Formik>
        </Space>
    )
}

export const WalletUnloadsContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [currentTab, setCurrentTab] = useState('NEW')
    const [countryFilter, setCountryFilter] = useState<string[]>([])
    const [onboardingViaFilter, setOnboardingViaFilter] = useState<OnboardingSource[]>([])

    const walletUnloadsTabsCount = useWalletUnloadsCount({ countryFilter, onboardingViaFilter })

    const tabList = Object.entries(WalletUnloadsStatusDisplay).map((status) => {
        const [apiStatus, displayStatus] = status
        const countForStatus = walletUnloadsTabsCount[displayStatus]
        return {
            key: apiStatus,
            tab: `${displayStatus}(${countForStatus})`,
        }
    })
    tabList.push({ key: 'EXPORTS', tab: 'Exports' })

    const onCountryFilter = (value: string[]) => {
        setCountryFilter(value)
    }

    const onOnboardingViaFilter = (value: OnboardingSource[]) => {
        setOnboardingViaFilter(value)
    }

    return (
        <PageContentLayout>
            <Card>
                <FilterPanel
                    onCountryFilter={onCountryFilter}
                    onOnboardingViaFilter={onOnboardingViaFilter}
                />
            </Card>
            <Card
                defaultActiveTabKey={WalletUnloadsStatusDisplay.NEW}
                tabList={tabList}
                onTabChange={(status) => setCurrentTab(status)}
            >
                {currentTab === 'EXPORTS' ? (
                    <Exports />
                ) : (
                    <>
                        <WalletUnloads
                            status={currentTab as WalletUnloadsStatusAPI}
                            countryFilter={countryFilter}
                            onboardingViaFilter={onboardingViaFilter}
                        />
                    </>
                )}
            </Card>
        </PageContentLayout>
    )
}

export default WalletUnloadsContainer
