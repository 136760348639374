import { Space, Typography } from 'antd'
import dayjs, { Dayjs } from 'packages/dayjs'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { disableFutureDate } from 'pages/customer-success/utils'
import type { RangeValue } from 'rc-picker/lib/interface'
import type { FC } from 'react'
import type { OrderFilters } from 'store/modules/support/card-orders/types'

const { RangePicker } = DayjsDatePicker
const { Text } = Typography

const FilterByDate: FC<
    React.PropsWithChildren<{
        filters?: OrderFilters
        setFilters?: React.Dispatch<React.SetStateAction<OrderFilters>>
    }>
> = ({ filters, setFilters }) => {
    const onChange = (date: RangeValue<Dayjs>) => {
        const from = date?.[0]?.toISOString() ?? ''
        const to = date?.[1]?.toISOString() ?? ''
        if (filters && setFilters) {
            setFilters({ ...filters, dateRange: { to, from } })
        }
    }

    return (
        <Space>
            <Text>Filter by date:</Text>
            <RangePicker
                onChange={onChange}
                placeholder={['Start date', 'End date']}
                disabledDate={(date) => disableFutureDate(dayjs(date.toDate()))}
            />
        </Space>
    )
}

export default FilterByDate
