export const buildReimbursementsMetabaseLink = (employeeId: string) => {
    //https://metabase.pleo.io/question#eyJuYW1lIjpudWxsLCJkYXRhc2V0X3F1ZXJ5Ijp7InF1ZXJ5Ijp7InNvdXJjZS10YWJsZSI6NTk1MSwiZmlsdGVyIjpbImFuZCIsWyI9IixbImZpZWxkIiw5NzkxMixudWxsXSwiMDAwMTIzYTktODhkOC00YjU5LTg3ZTYtZDBjOTNmMjE2YjQzIl1dfSwidHlwZSI6InF1ZXJ5IiwiZGF0YWJhc2UiOjQyfSwiZGlzcGxheSI6InRhYmxlIiwidmlzdWFsaXphdGlvbl9zZXR0aW5ncyI6e319
    const preformattedJson = {
        name: null,
        dataset_query: {
            query: {
                'source-table': 5951,
                filter: ['and', ['=', ['field', 97912, null], `${employeeId}`]],
            },
            type: 'query',
            database: 42,
        },
        display: 'table',
        visualization_settings: {},
    }
    const unencryptedString = JSON.stringify(preformattedJson)
    const buf = Buffer.from(unencryptedString, 'utf8')
    return `https://metabase.pleo.io/question#${buf.toString('base64')}`
}
