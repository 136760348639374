import { useEffect } from 'react'
import { Card, Form, Space, DatePicker, Button, Input } from 'antd'
import validateUuid from 'uuid-validate'
import type { Dayjs } from 'dayjs'
import { useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'packages/dayjs'

type CompanyAndDateForm = {
    companyId?: string
    at?: Dayjs
    diffWith?: Dayjs
}

export const CompanyAndDateSelector = () => {
    const { companyId: companyIdFromParams } = useParams()
    const [form] = Form.useForm<CompanyAndDateForm>()
    const [searchParams, setSearchParams] = useSearchParams()

    const updateUrlWithFormValues = (companyId?: string, at?: string, diffWith?: string) => {
        companyId ? searchParams.set('company', companyId) : searchParams.delete('company')
        at ? searchParams.set('at', at) : searchParams.delete('at')
        diffWith ? searchParams.set('diffWith', diffWith) : searchParams.delete('diffWith')
        searchParams.sort()
        setSearchParams(searchParams)
    }

    const onSubmit = (values: CompanyAndDateForm) => {
        updateUrlWithFormValues(
            values?.companyId,
            formatDate(values?.at),
            formatDate(values?.diffWith)
        )
    }

    useEffect(() => {
        form.setFieldValue('companyId', searchParams.get('company') || '')
        searchParams.has('at') && form.setFieldValue('at', dayjs(searchParams.get('at')))
        searchParams.has('diffWith') &&
            form.setFieldValue('diffWith', dayjs(searchParams.get('diffWith')))
    }, [companyIdFromParams, form, searchParams])

    return (
        <Card>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    at: dayjs(),
                }}
            >
                <Space.Compact>
                    <Form.Item
                        name="companyId"
                        label="Company ID"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (value && !validateUuid(value)) {
                                        throw new Error('Invalid format for Company ID')
                                    }
                                },
                            },
                        ]}
                    >
                        <Input allowClear style={{ width: '24rem' }} spellCheck={false} />
                    </Form.Item>
                    <Form.Item label="At" name="at">
                        <DatePicker allowClear={true} />
                    </Form.Item>
                    <Form.Item label="Compare with" name="diffWith">
                        <DatePicker allowClear={true} />
                    </Form.Item>
                    <Form.Item style={{ alignSelf: 'flex-end' }}>
                        <Button htmlType="submit">Submit</Button>
                    </Form.Item>
                </Space.Compact>
            </Form>
        </Card>
    )
}

const formatDate = (date?: Dayjs) => (date ? dayjs(date).toISOString() : '')
