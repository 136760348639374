import type { FC } from 'react'
import { Formik } from 'formik'
import { Form, FormItem, SubmitButton } from 'formik-antd'
import { message } from 'antd'

import { Input } from 'components/input/input'
import { DaySelect, MonthSelect, YearSelect } from 'components/date-of-birth-pickers'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'

import { validationSchema, FormValues, mapValuesToRequest } from './validation-schema'

import * as s from './create-person-form.styles'
import CountrySelect from 'components/country-select/country-select'
import { Label } from 'components/sidebar-title/sidebar-title'

interface Props {
    onCreatePerson: OwnershipMutations['createPerson']
    rootCompanyId: string
}

const initialValues = {
    givenName: '',
    middleName: '',
    familyName: '',
    registryId: '',
    nationalId: '',
    day: null,
    month: null,
    year: null,
    country: '',
    locality: '',
    postalCode: '',
    houseNumber: '',
    street: '',
    floor: '',
    region: '',
}
// initially Touch all conditionally required fields
const initialTouched = {
    country: true,
    postalCode: true,
    firstName: true,
    month: true,
    year: true,
}

const CreatePersonForm: FC<React.PropsWithChildren<Props>> = ({
    onCreatePerson,
    rootCompanyId,
}) => {
    const onSubmit = async (values: FormValues) => {
        try {
            await onCreatePerson(mapValuesToRequest(values, rootCompanyId))
        } catch (e) {
            message.error(`Failed to create person: ${(e as Error).message}`)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialTouched={initialTouched}
        >
            <Form layout="vertical">
                <s.InputGrid>
                    <label htmlFor="givenName">Name</label>
                    <Input name="givenName" placeholder="First name" />
                    <Input name="middleName" placeholder="Middle name(s)" />
                    <Input name="familyName" placeholder="Last name" />
                    <label htmlFor="nationalId">
                        National Id <Input name="nationalId" placeholder="National Id" />
                    </label>
                    <label htmlFor="registryId">
                        Registry Id <Input name="registryId" placeholder="Registry Id" />
                    </label>
                    <label htmlFor="nationality">
                        <FormItem name="nationality">
                            <Label>Nationality</Label>
                            <CountrySelect
                                name="nationality"
                                placeholder="Nationality"
                                showSearch
                            />
                        </FormItem>
                    </label>
                    <label htmlFor="birthday">Date of birth</label>
                    <FormItem name="day">
                        <DaySelect name="day" placeholder="Day" />
                    </FormItem>
                    <FormItem name="month">
                        <MonthSelect name="month" placeholder="Month" />
                    </FormItem>
                    <FormItem name="year">
                        <YearSelect name="year" placeholder="Year" />
                    </FormItem>
                    <label htmlFor="street">Address</label>
                    <Input name="street" placeholder="Street" />
                    <Input name="houseNumber" placeholder="House number" />
                    <Input name="floor" placeholder="Flat number / floor" />
                    <Input name="locality" placeholder="City / locality" />
                    <Input name="postalCode" placeholder="Postal code" />
                    <Input name="region" placeholder="Region" />
                    <Input name="country" placeholder="Country" />
                </s.InputGrid>
                <s.ButtonContainer>
                    <SubmitButton data-testid="submit" type="primary">
                        Save
                    </SubmitButton>
                </s.ButtonContainer>
            </Form>
        </Formik>
    )
}

export default CreatePersonForm
