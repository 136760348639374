import React, { FC, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal, notification, Space } from 'antd'

import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import type { SupplementaryUnloadStatus } from 'pages/compliance/wallet-unloads/utils'
import { useWalletUnloads } from 'services/deimos/wallet'
import { color } from 'theme/tokens'

export interface Props {
    walletUnload: WalletUnloadResponse
    status: WalletUnloadResponseStatus | SupplementaryUnloadStatus.OUTSTANDING_INVOICES
    disabled: boolean
    setDisableActionButtons: (value: boolean) => void
}

export const Reject: FC<React.PropsWithChildren<Props>> = ({
    walletUnload,
    status,
    disabled,
    setDisableActionButtons,
}) => {
    const [modalActive, setModalActive] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(false)
    const {
        mutations: { updateWalletUnloadStatus },
    } = useWalletUnloads(status)
    const onConfirm = async () => {
        try {
            setDisableSaveButton(true)
            setDisableActionButtons(true)
            await updateWalletUnloadStatus(walletUnload.id, WalletUnloadResponseStatus.CANCELLED)
            notification.success({
                message: 'Wallet unload successfully rejected',
            })
        } catch (e) {
            notification.error({
                message: 'Failed to reject wallet unload',
            })
        } finally {
            setModalActive(false)
            setDisableSaveButton(false)
            setDisableActionButtons(false)
        }
    }
    return (
        <>
            <Button type="link" danger onClick={() => setModalActive(true)} disabled={disabled}>
                Reject
            </Button>
            <Modal
                title={
                    <Space>
                        <QuestionCircleOutlined style={{ color: color.gold }} /> Are you sure you
                        want to reject this wallet unload?
                    </Space>
                }
                open={modalActive}
                onOk={onConfirm}
                okButtonProps={{ disabled: disableSaveButton }}
                onCancel={() => setModalActive(false)}
                centered
            >
                This action cannot be undone
            </Modal>
        </>
    )
}
