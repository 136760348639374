import {
    Account,
    AccountCategory,
    AccountCategoryTypeKey,
    NO_SUITABLE_ACCOUNT_CATEGORY_ID,
} from '@pleo-io/deimos'
import { CategoryIcon } from './category-icon'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import type { AccountCategoryMutations } from 'services/deimos/categories'
import styled from 'styled-components'

const hasArchivedAccounts = (accountCategory: AccountCategory) =>
    accountCategory.accounts?.some((account: Account) => account.hidden) ?? false

export const getFilteredCategoryGroups = ({
    categoryGroups,
    showHiddenCategories,
}: {
    categoryGroups: AccountCategory[]
    showHiddenCategories: boolean
}) => {
    const visibleCategoryGroups = categoryGroups.filter(
        (group) => group.id !== NO_SUITABLE_ACCOUNT_CATEGORY_ID
    )

    return visibleCategoryGroups.filter((accountCategory) =>
        showHiddenCategories
            ? accountCategory.hidden || hasArchivedAccounts(accountCategory)
            : !accountCategory.hidden
    )
}

export function getCategoryGroupActions({
    categoryGroup,
    mutations,
    onError,
}: {
    categoryGroup: AccountCategory
    mutations: AccountCategoryMutations
    onError: ({ errorMessage, type }: ActionError) => void
}): ItemType[] {
    const { id, hidden } = categoryGroup
    const { archiveCategory, unarchiveCategory, deleteCategory } = mutations
    const type = 'Category group'

    return [
        ...(hidden
            ? [
                  {
                      key: 'unarchive',
                      label: 'Unarchive category group',
                      onClick: async () => {
                          try {
                              await unarchiveCategory(id)
                          } catch (error: any) {
                              onError({ errorMessage: error?.message, type })
                          }
                      },
                  },
              ]
            : [
                  {
                      key: 'archive',
                      label: 'Archive category group',
                      onClick: async () => {
                          try {
                              await archiveCategory(id)
                          } catch (error: any) {
                              onError({ errorMessage: error?.message, type })
                          }
                      },
                  },
              ]),

        {
            key: 'delete',
            label: 'Delete category group',
            onClick: async () => {
                try {
                    await deleteCategory(id)
                } catch (error: any) {
                    onError({ errorMessage: error?.message, type })
                }
            },
        },
    ]
}

const SmallCategoryIcon = styled(CategoryIcon)`
    width: 16px;
    height: 16px;
`

export function getCategoryTypeIcons({
    onChange,
}: {
    onChange: (value: AccountCategoryTypeKey) => void
}): ItemType[] {
    const categoryIcons = Array.from(accountCategoriesMap().values()).map(
        ({ typeKey, name, label }: AccountCategoryType) => {
            return {
                label,
                onClick: () => onChange(typeKey),
                icon: <SmallCategoryIcon imgkey={typeKey} />,
                key: name,
            }
        }
    )

    return categoryIcons
}

export const getErrorMessage = ({
    errorMessage,
    name,
    type,
}: {
    errorMessage: string
    name: string
    type: string
}) => {
    let message

    switch (errorMessage) {
        case 'in_use':
            message = `${type} is in use. Consider archiving ${name} instead of deleting.`
            break
        default:
            message = `An error occurred. Please try again later or contact support.`
    }

    return message
}

export type ActionError = {
    errorMessage: string
    type: string
}

export function getCategoryActions({
    category,
    mutations,
    onError,
}: {
    category: Account
    mutations: AccountCategoryMutations
    onError: ({ errorMessage, type }: ActionError) => void
}): ItemType[] {
    const { id, hidden = false } = category
    const { archiveAccount, unarchiveAccount, deleteAccount } = mutations
    const type = 'Category'

    return [
        ...(hidden
            ? [
                  {
                      key: 'unarchive',
                      label: 'Unarchive category',
                      onClick: async () => {
                          try {
                              await unarchiveAccount(id)
                          } catch (error: any) {
                              onError({ errorMessage: error?.message, type })
                          }
                      },
                  },
              ]
            : [
                  {
                      key: 'archive',
                      label: 'Archive category',
                      onClick: async () => {
                          try {
                              await archiveAccount(id)
                          } catch (error: any) {
                              onError({ errorMessage: error?.message, type })
                          }
                      },
                  },
              ]),

        {
            key: 'delete',
            label: 'Delete category',
            onClick: async () => {
                try {
                    await deleteAccount(id)
                } catch (error: any) {
                    onError({ errorMessage: error?.message, type })
                }
            },
        },
    ]
}

export type AccountCategoryType = Omit<
    AccountCategory,
    'companyId' | 'accounts' | 'id' | 'hidden'
> & {
    label: string
}

type valueof<T> = T[keyof T]

export const accountCategoriesMap = (): Map<valueof<AccountCategoryTypeKey>, AccountCategoryType> =>
    new Map<valueof<AccountCategoryTypeKey>, AccountCategoryType>([
        [
            AccountCategoryTypeKey.ENTERTAINMENT,
            {
                typeKey: AccountCategoryTypeKey.ENTERTAINMENT,
                name: 'Events, recreational services',
                label: 'Entertainment',
            },
        ],
        [
            AccountCategoryTypeKey.EQUIPMENT_AND_HARDWARE,
            {
                typeKey: AccountCategoryTypeKey.EQUIPMENT_AND_HARDWARE,
                name: 'Electronics, equipment, music stores',
                label: 'Equipment & hardware',
            },
        ],
        [
            AccountCategoryTypeKey.MARKETING_AND_ADVERTISING,
            {
                typeKey: AccountCategoryTypeKey.MARKETING_AND_ADVERTISING,
                name: 'Marketing services',
                label: 'Marketing & advertising',
            },
        ],
        [
            AccountCategoryTypeKey.MEALS_AND_DRINKS,
            {
                typeKey: AccountCategoryTypeKey.MEALS_AND_DRINKS,
                name: 'Restaurants, cafes, supermarkets',
                label: 'Meals & drinks',
            },
        ],
        [
            AccountCategoryTypeKey.OFFICE_EXPENSES,
            {
                typeKey: AccountCategoryTypeKey.OFFICE_EXPENSES,
                name: 'Books, gifts, florists shops',
                label: 'Office expenses',
            },
        ],
        [
            AccountCategoryTypeKey.PHONE_AND_INTERNET,
            {
                typeKey: AccountCategoryTypeKey.PHONE_AND_INTERNET,
                name: 'Internet, satellite, radio services',
                label: 'Phone & internet',
            },
        ],
        [
            AccountCategoryTypeKey.SOFTWARE,
            {
                typeKey: AccountCategoryTypeKey.SOFTWARE,
                name: 'Software applications, digital services',
                label: 'Software',
            },
        ],
        [
            AccountCategoryTypeKey.TRAVEL,
            {
                typeKey: AccountCategoryTypeKey.TRAVEL,
                name: 'Hotels, taxis, airlines',
                label: 'Travel',
            },
        ],
        [
            AccountCategoryTypeKey.SERVICES_AND_CONSULTANCY,
            {
                typeKey: AccountCategoryTypeKey.SERVICES_AND_CONSULTANCY,
                name: 'Contractors, consultancy services',
                label: 'Services & consultancy',
            },
        ],
        [
            AccountCategoryTypeKey.SUPPLIES,
            {
                typeKey: AccountCategoryTypeKey.SUPPLIES,
                name: 'Supplies, retail stores, pharmacies',
                label: 'Supplies',
            },
        ],
        [
            AccountCategoryTypeKey.OTHER,
            {
                typeKey: AccountCategoryTypeKey.OTHER,
                name: 'Other',
                label: 'Other',
            },
        ],
    ])
