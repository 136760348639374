import React, { FC, useEffect, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, notification, Form, Select, TreeSelect } from 'antd'
import { inputWidth } from 'theme/tokens'
import styled from 'styled-components'
import { initiateOffboarding, updateOffboardingStatus } from 'services/deimos/companies'
import {
    CompanyOffboardingCategory,
    CompanyOffboardingReason,
    CompanyOffboardingStatus,
    InitiateOffboardingRequest,
} from '@pleo-io/deimos'
import { DisplayOffboardingReason } from './utils'
import { deSnakify } from 'utils/strings'
import { useHasPermissions } from 'components/permission-guard/permission-guard'

const { confirm } = Modal
const { Option } = Select
const { TreeNode } = TreeSelect

interface Props {
    offboardingStatus?: CompanyOffboardingStatus | null
    companyId?: string
    closeModal: () => void
    revalidateCompany: () => void
}

const companyOffboardingReasonCategory = [
    {
        title: 'Compliance',
        options: [
            CompanyOffboardingReason.REJECTED,
            CompanyOffboardingReason.DUPLICATE,
            CompanyOffboardingReason.BANKRUPTCY,
            CompanyOffboardingReason.COMPETITOR,
            CompanyOffboardingReason.OTHER_BREACH_OF_TERMS_AND_CONDITIONS,
            CompanyOffboardingReason.AML,
            CompanyOffboardingReason.CTF,
            CompanyOffboardingReason.RISK_APPETITE,
        ],
    },
    {
        title: 'Bad Timing',
        options: [
            CompanyOffboardingReason.BAD_TIMING_ONE_MONTH,
            CompanyOffboardingReason.BAD_TIMING_THREE_MONTH,
            CompanyOffboardingReason.BAD_TIMING_SIX_MONTH,
            CompanyOffboardingReason.BAD_TIMING_ONE_YEAR,
            CompanyOffboardingReason.BAD_TIMING_UNKNOWN,
            CompanyOffboardingReason.BAD_TIMING_OTHER,
        ],
    },
    {
        title: 'Complicated Setup',
        options: [
            CompanyOffboardingReason.COMPLICATED_SETUP_WALLET_LOAD,
            CompanyOffboardingReason.COMPLICATED_SETUP_CARD_ACTIVATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_ACCOUNTING_INTEGRATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_PERSONAL_VERIFICATION,
            CompanyOffboardingReason.COMPLICATED_SETUP_COMPANY_VERIFICATION,
        ],
    },
    {
        title: 'Missing Feature',
        options: [
            CompanyOffboardingReason.MISSING_FEATURE_ACCOUNTING_INTEGRATION,
            CompanyOffboardingReason.MISSING_FEATURE_CREDIT,
            CompanyOffboardingReason.MISSING_FEATURE_MULTI_CURRENCY,
            CompanyOffboardingReason.MISSING_FEATURE_OPEN_API,
            CompanyOffboardingReason.MISSING_FEATURE_REPRESENTATION,
            CompanyOffboardingReason.MISSING_FEATURE_AUTO_VAT,
            CompanyOffboardingReason.MISSING_FEATURE_PROJECT_MANAGEMENT,
            CompanyOffboardingReason.MISSING_FEATURE_BILLABLE_EXPENSES,
            CompanyOffboardingReason.MISSING_FEATURE_INVOICE_MANAGEMENT,
            CompanyOffboardingReason.MISSING_FEATURE_MULTI_LOGIN,
            CompanyOffboardingReason.MISSING_FEATURE_OTHER,
        ],
    },
    {
        title: 'Misunderstood Product',
        options: [
            CompanyOffboardingReason.MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT,
            CompanyOffboardingReason.MISUNDERSTOOD_PRODUCT_OTHER,
        ],
    },
    {
        title: 'Moved to Competitor',
        options: [
            CompanyOffboardingReason.MOVED_TO_COMPETITOR,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_SPENDESK,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_SOLDO,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_MOSS,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_RAMP,
            CompanyOffboardingReason.MOVED_TO_COMPETITOR_OTHER,
        ],
    },
    {
        title: 'Organization Changes',
        options: [
            CompanyOffboardingReason.ORGANIZATION_CLOSING_COMPANY,
            CompanyOffboardingReason.ORGANIZATION_MERGING_ENTITY,
            CompanyOffboardingReason.ORGANIZATION_OTHER,
            CompanyOffboardingReason.ORGANIZATION_PLEO_CHAMPION_LEFT,
        ],
    },
    {
        title: 'Pricing',
        options: [
            CompanyOffboardingReason.PRICING_LACK_FUNCTIONALITY,
            CompanyOffboardingReason.PRICING_STRUCTURE,
            CompanyOffboardingReason.PRICING_TOO_EXPENSIVE,
            CompanyOffboardingReason.PRICING_OTHER,
        ],
    },
    {
        title: 'Other',
        options: [
            CompanyOffboardingReason.OTHER,
            CompanyOffboardingReason.OTHER_INVALID_SIGN_UP_FAKE,
            CompanyOffboardingReason.OTHER_INVALID_SIGN_UP_DUPLICATED,
            CompanyOffboardingReason.OTHER_LOW_EXPENSE_VOLUME,
            CompanyOffboardingReason.OTHER_FEEDBACK_REFUSED,
            CompanyOffboardingReason.OTHER_FEEDBACK_UNANSWERED,
        ],
    },
]

const OffboardingForm: FC<React.PropsWithChildren<Props>> = ({
    offboardingStatus,
    closeModal,
    companyId,
    revalidateCompany,
}) => {
    const [canSubmit, setCanSubmit] = useState(true)
    const initFormValues = {
        category: offboardingStatus?.category ?? CompanyOffboardingCategory.ACTIVE,
        reasons: offboardingStatus?.reasons,
        notes: offboardingStatus?.notes,
    }
    const hasStyxPermission = useHasPermissions(['styx'])

    const onOffboarding = async (values: any) => {
        if (!companyId) {
            return
        }
        const request: InitiateOffboardingRequest = {
            category: values.category,
            reasons: values.reasons ?? [],
            notes: values.notes ?? '',
        }
        try {
            if (offboardingStatus) {
                await updateOffboardingStatus(companyId, request)
                notification.info({ message: 'Successfully updated offboarding' })
            } else {
                await initiateOffboarding(companyId, request)
                notification.info({ message: 'Successfully initiated offboarding' })
            }
            closeModal()
        } catch (e) {
            if (offboardingStatus) {
                notification.error({ message: 'Failed to update offboarding' })
            } else {
                notification.error({ message: 'Failed to initiate offboarding' })
            }
        }
    }

    const onFinish = (values: any) => {
        const content = offboardingStatus
            ? 'This will update the offboarding for this company'
            : 'This will start the offboarding process for this company.'
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content,
            okButtonProps: { id: 'confirm-offboarding' },
            onOk: async () => {
                await onOffboarding(values)
                revalidateCompany()
            },
        })
    }

    // This function is required to stop the TreeSelect dropdown from scrolling with the background page
    function getPopupContainer(treeSelectNode: HTMLElement): any {
        return treeSelectNode.parentNode
    }

    // Validate the form when the values change
    const validateForm = (_: any, formValues: InitiateOffboardingRequest) => {
        if (
            formValues?.category === CompanyOffboardingCategory.LEGAL &&
            !formValues?.reasons?.length
        ) {
            setCanSubmit(false)
        } else {
            setCanSubmit(true)
        }
    }

    // As well as validating the form when values change, it also needs to validate depending on
    // the values initally passed in as props
    useEffect(() => {
        if (
            initFormValues.category === CompanyOffboardingCategory.LEGAL &&
            !initFormValues.reasons?.length
        ) {
            setCanSubmit(false)
        }
    }, [initFormValues.category, initFormValues.reasons?.length])

    return (
        <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={initFormValues}
            onValuesChange={validateForm}
        >
            <FormContainer>
                <Form.Item
                    name="category"
                    label="Category"
                    colon={false}
                    rules={[
                        {
                            required: true,
                            message: 'Please select the category',
                        },
                    ]}
                    style={{ width: inputWidth.large }}
                >
                    <Select style={{ width: inputWidth.huge }} disabled={!!offboardingStatus}>
                        {Object.values(CompanyOffboardingCategory).map((category) =>
                            !hasStyxPermission &&
                            category === CompanyOffboardingCategory.LEGAL ? null : (
                                <Option key={category} value={category}>
                                    {deSnakify(category)}
                                </Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="reasons" label="Reasons" colon={false}>
                    <TreeSelect
                        multiple
                        style={{ width: inputWidth.huge }}
                        placeholder="Reasons"
                        getPopupContainer={(event) => getPopupContainer(event)}
                    >
                        {companyOffboardingReasonCategory.map((reason) => (
                            <TreeNode
                                value={reason.title}
                                title={reason.title}
                                key={reason.title}
                                selectable={false}
                            >
                                {reason.options.map((option: CompanyOffboardingReason) => (
                                    <TreeNode
                                        value={option}
                                        title={DisplayOffboardingReason[option]}
                                        key={option}
                                    />
                                ))}
                            </TreeNode>
                        ))}
                    </TreeSelect>
                </Form.Item>
                <Form.Item name="notes" label="Notes" colon={false}>
                    <Input.TextArea
                        name="notes"
                        style={{ width: `${inputWidth.huge}` }}
                        placeholder="Notes"
                    />
                </Form.Item>
            </FormContainer>
            <ButtonContainer>
                <Button
                    htmlType="submit"
                    type="primary"
                    data-testid="offboard-button"
                    disabled={!canSubmit}
                >
                    {offboardingStatus ? 'Update' : 'Offboard'}
                </Button>
            </ButtonContainer>
        </Form>
    )
}

const FormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default OffboardingForm
