import useSWR from 'swr'
import { deimosRequest } from 'services/request'
import { fetcher } from './request'
import type { PartnerProgramInfoResponse, PartnerTier } from 'types/partner'
import type ky from 'ky/umd'

// Requests

export function deletePartner(partnerId: string) {
    return deimosRequest()
        .delete(`rest/v1/partners/${partnerId}`)
        .then((response) => response.json())
}

// SWR

export const usePartnerProgramInfo = (partnerId: string) => {
    const result = useSWR<PartnerProgramInfoResponse, ky.HTTPError>(
        `partner-portal/rpc/partners/${partnerId}/program-info?includeCalculatedTierIfOverride=true`,
        fetcher
    )
    const override = async ({ newTier, message }: { newTier: PartnerTier; message: string }) => {
        await deimosRequest().post(`partner-portal/rpc/partners/${partnerId}/program/override`, {
            json: { newTier, message },
        })
        if (result.data) {
            result.mutate({
                ...result.data,
                currentTier: { ...result.data.currentTier, type: newTier },
            })
        }
    }

    return { ...result, mutations: { override } }
}
